import { Product } from '@manzuko/shared';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { selectProductEntities } from 'app/data-access/product';
import { selectQueryParam } from 'app/data-access/router';

import { checkoutFeature } from './checkout.reducer';

export const {
  selectCheckoutState,
  selectPagination: selectCheckoutPagination,
  selectProductsIds: selectCheckoutProductsIds,
  selectOrderError: selectCheckoutCreateOrderError
} = checkoutFeature;

export const selectCheckoutPage = selectQueryParam('page');

export const selectCheckoutProducts = createSelector(
  selectCheckoutProductsIds,
  selectProductEntities,
  (ids: number[], entities: Dictionary<Product>) => ids?.map((id) => entities?.[id])
);

// export const selectCheckoutView = createSelector(
//   selectCheckoutPagination,
//   selectCheckoutProducts,
//   selectSubcategories,
//   selectCheckoutBreadcrumbs,
//   selectCheckout,
//   (pagination, products, subcategories, breadcrumbs, checkout) => ({
//     pagination,
//     products,
//     subcategories,
//     breadcrumbs,
//     checkout,
//   }),
// );
