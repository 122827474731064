import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Banner } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  private readonly http = inject(HttpClient);

  getValid(): Observable<Banner[]> {
    return this.http.get<Banner[]>(`${environment.api}/catalog/banners`);
  }
}
