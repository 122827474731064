import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Message, MessageResults } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private readonly http = inject(HttpClient);

  get$: Observable<MessageResults> = this.http.get<MessageResults>(
    `${environment.api}/catalog/messages?nocache=${new Date()?.getTime()}`
  );

  create(message: Message): Observable<Message> {
    return this.http.post<Message>(`${environment.api}/catalog/messages`, message);
  }

  update(message: Partial<Message>) {
    return this.http.put<Message>(`${environment.api}/catalog/messages/${message.id}`, message);
  }

  delete(id: number) {
    return this.http.delete(`${environment.api}/catalog/messages/${id}`);
  }

  getbyuserId(userId: number): Observable<MessageResults> {
    return this.http.get<MessageResults>(
      `${environment.api}/catalog/messages/user/${userId}?nocache=${new Date()?.getTime()}`
    );
  }

  getByOrderId(orderId: number): Observable<MessageResults> {
    return this.http.get<MessageResults>(
      `${environment.api}/catalog/messages/order/${orderId}?nocache=${new Date()?.getTime()}`
    );
  }
}
