import { User } from '@manzuko/shared';
import { createSelector } from '@ngrx/store';

import { authFeature } from './auth.reducer';

export const {
  selectAuthState: selectAuth,
  selectToken: selectAuthToken,
  selectUser: selectAuthUser,
  selectSecureRoutes: selectAuthSecureRoutes,
  selectErrors: selectAuthErrors,
  selectSuccess: selectAuthSuccess
} = authFeature;

export const selectAuthUserId = createSelector(selectAuthUser, (user: User) => user?.id);

export const selectAuthUserRoles = createSelector(selectAuthUser, (user: User) => user?.roles);

export const selectAuthUserIsAdmin = createSelector(
  selectAuthUserRoles,
  (roles: string | string[]) => roles?.includes('admin')
);

export const selectAuthUserIsEmployee = createSelector(
  selectAuthUserRoles,
  (roles: string | string[]) => roles?.includes('employee')
);

export const selectAuthUserHasStats = createSelector(
  selectAuthUserRoles,
  (roles: string | string[]) => roles?.includes('stats')
);

export const selectAuthUserIsAdminOrEmployee = createSelector(
  selectAuthUserRoles,
  (roles: string | string[]) => roles?.includes('admin') || roles?.includes('employee')
);

export const selectAuthUserEmail = createSelector(selectAuthUser, (user: User) => user?.email);

export const selectProfileView = createSelector(
  selectAuthUser,
  selectAuthErrors,
  selectAuthSuccess,
  (user: User, error: any, success: any) => ({
    user,
    error,
    success
  })
);
