import { BreakpointObserver } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';

import { selectCategoryTopLevel } from 'app/data-access/category/category.selectors';
import { LayoutActions, selectLayoutShowMenu } from 'app/data-access/layout';
import { RouterLinkPipe } from 'app/shared/pipes/router-link.pipe';

@Component({
  standalone: true,
  selector: 'mzk-nav-desktop',
  templateUrl: './nav-desktop.component.html',
  styleUrls: ['./nav-desktop.component.scss'],
  imports: [
    AsyncPipe,
    NgClass,
    RouterLink,
    RouterLinkActive,
    TranslocoDirective,
    FastSvgComponent,
    RouterLinkPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavDesktopComponent {
  private readonly store = inject(Store);
  private readonly platform = inject(Platform);
  private readonly observer = inject(BreakpointObserver);

  categories$ = this.store.select(selectCategoryTopLevel);
  showMenu$ = this.store.select(selectLayoutShowMenu);

  selectNav(selectedNav: number, $event): void {
    if (this.platform.isBrowser && this.observer.isMatched('(max-width: 960px)')) {
      // $event.preventDefault();
      // $event.stopPropagation();
    }

    this.store.dispatch(LayoutActions.selectNav({ selectedNav }));
  }
}
