import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ErrorCatchingInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(({ error }: HttpErrorResponse) => {
        console.warn(
          error?.error?.message
            ? `Client error: ${error?.error?.message}`
            : `Server error: (${error?.statusCode}) ${error?.message}`
        );
        throw error;
      })
    );
  }
}
