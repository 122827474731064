<div *transloco="let t" class="w-full flex flex-row items-start">
  <mzk-product-thumb
    [product]="item"
    [isGift]="item?.product?.isGift"
    size="cart"
    class="block min-w-14 w-24" />

  <div class="flex flex-col flex-grow justify-between h-full min-h-22 ml-1 md:ml-4">
    <div class="flex flex-row flex-grow justify-between">
      <a [routerLink]="!item?.product?.isGift ? item?.path : null">
        <p class="break-normal hyphens-auto leading-[1.35] max-w-[218px]">
          {{ item?.name }}
        </p>
      </a>
      <button
        type="button"
        title="{{ t('shop.cart.product.delete') }}"
        (click)="removeProduct(item.id)">
        <div class="hover:bg-gray-300 cursor-pointer rounded-xl">
          <fast-svg name="clear-cart" size="28" />
        </div>
      </button>
    </div>

    <div class="mt-1 text-gray-500 flex flex-row items-center justify-between">
      <div class="flex flex-col sm:flex-row">
        @if (!item?.product?.isGift) {
          <div class="flex flex-row items-center">
            <button
              data-test="product-minus"
              title="{{ t('shop.cart.product.remove') }}"
              aria-label="Usuń produkt"
              (click)="updateQuantity(item?.productId, -1, nameOnVoucherControl?.value)"
              type="button"
              class="text-neutral-500 transition-all ease-in-out hover:text-neutral-900">
              <fast-svg name="cart-minus" size="32" />
            </button>
            <input
              id="product-quantity-input"
              type="number"
              disableMouseScroll
              name="qty"
              [min]="1"
              [formControl]="quantityControl"
              (change)="
                quantityControl?.valid &&
                  updateQuantity(
                    item?.productId,
                    quantityControl?.value - item?.quantity,
                    nameOnVoucherControl?.value
                  )
              "
              class="border border-neutral-200 rounded-3xl sm:w-16 h-8 text-center pt-[9px] px-0.5 md:px-1 w-12 mt-1"
              aria-label="Ilość" />
            <label for="product-quantity-input" hidden>{{ t('shop.cart.product.quantity') }}</label>
            <button
              title="{{ t('shop.cart.product.add') }}"
              (click)="updateQuantity(item?.productId, 1, nameOnVoucherControl?.value)"
              type="button"
              aria-label="Dodaj produkt"
              [disabled]="item?.product?.quantityAfterOrder <= 0"
              class="text-neutral-500 transition-all ease-in-out hover:text-neutral-900">
              <fast-svg name="cart-plus" size="32" />
            </button>
          </div>
        }
        @if (item?.product?.isVirtual && showNameOnVoucher) {
          <mzk-form-control-input
            class="max-h-12 pt-2 sm:pt-0 pl-0 mr-1 sm:pl-2"
            [label]="t('shop.cart.product.nameOnVoucher')"
            [control]="nameOnVoucherControl"
            [error]="
              nameOnVoucherControl?.errors?.minlength
                ? t('shop.cart.product.nameOnVoucherMinLength')
                : nameOnVoucherControl?.errors?.maxlength
                  ? t('shop.cart.product.nameOnVoucherMaxLength')
                  : nameOnVoucherControl
                    ? t('shop.cart.product.nameOnVoucherError')
                    : ''
            " />
        }
      </div>
      @if (item?.product?.quantityAfterOrder <= 0) {
        <div class="text-red-500 inline">{{ t('shop.productAdd.noMoreProducts') }}</div>
      }

      <p class="flex flex-row justify-between items-center">
        <small class="text-gray-500 mr-1 md:mr-3">x</small>
        @if (item?.price && item?.price < item?.regularPrice) {
          <span data-test="product-price" class="font-bold text-brand">
            <!-- <s class="pr-1 text-md text-gray-600 font-thin">{{ item?.regularPrice | mzkCurrency | async }}</s> -->
            <span class="block">{{ item?.price | mzkCurrency | async }}</span>
          </span>
        }

        @if (!!item?.price && item?.price === item?.regularPrice) {
          <span data-test="product-price" class="font-bold text-brand">
            {{ item?.regularPrice | mzkCurrency | async }}
          </span>
        }
      </p>
    </div>

    @if (quantityControl?.invalid) {
      <div class="text-red-500">
        {{ t('shop.productAdd.correctNumber') }}
      </div>
    }
  </div>
</div>
@if (nameOnVoucherChange$ | async) {}
