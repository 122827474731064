import { Address, Order } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CheckoutActions = createActionGroup({
  source: 'Checkout/Page',
  events: {
    submit: props<{
      address: { address: Address; shippingAddress: Address };
      clientGa: string;
      sessionGa: string;
    }>(),

    'Create Order': props<{ order: Order }>(),
    'Create Order Success': props<{ order: Partial<Order> }>(),
    'Create Order Failure': props<{ error: any }>(),

    'Pay Online': props<{ order: Order }>(),
    'Register P24 Transaction': props<{ orderId: number }>(),
    'Register P24 Transaction Success': props<{ transaction: any }>(),
    'Register P24 Transaction Failure': props<{ error: any }>(),

    'Set Order': props<{ order: Order }>(),
    'Clear Error': emptyProps()
  }
});
