import { Category } from '@manzuko/shared';
import { createSelector } from '@ngrx/store';

import { selectCategoryAllWithUrls } from 'app/data-access/category/category.selectors';

export const selectSitemap = createSelector(
  selectCategoryAllWithUrls,
  (categories: Category[] = []) => {
    const getTree = (categoryId: number) =>
      categories
        ?.filter(({ parentId }) => parentId === categoryId)
        .map((category) => ({
          ...category,
          children: getTree(category?.id)
        }));

    return getTree(2)?.sort((a, b) => a?.position - b?.position) as Category[];
  }
);
