import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';

@Component({
  standalone: true,
  selector: 'mzk-footer-contact',
  templateUrl: './footer-contact.component.html',
  styleUrls: ['./footer-contact.component.scss'],
  imports: [TranslocoDirective, FastSvgComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterContactComponent {}
