import { ApiPagination } from '@manzuko/shared';
import { createFeature, createReducer, on } from '@ngrx/store';

import { TagActions } from './tag.actions';

export interface TagState {
  productsIds: number[];
  pagination: ApiPagination;
}

export const initialState: TagState = {
  productsIds: [],
  pagination: {}
};

export const tagFeature = createFeature({
  name: 'tag',
  reducer: createReducer(
    initialState,
    on(TagActions.set, (state, { products, pagination }) => ({
      ...state,
      productsIds: products?.map((product) => product?.id),
      pagination
    }))
  )
});
