import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap } from 'rxjs';

import { selectLayoutLanguage } from '../layout';
import { ShippingActions } from './shipping.actions';
import { ShippingService } from './shipping.service';

@Injectable({
  providedIn: 'root'
})
export class ShippingEffects {
  private readonly actions$ = inject(Actions);
  private readonly shippingService = inject(ShippingService);
  private readonly store = inject(Store);

  setShippings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ShippingActions.loadAll),
      concatMap(() => this.store.select(selectLayoutLanguage)),
      switchMap((lang) => {
        return this.shippingService.get(lang);
      }),
      map(({ results }) => ShippingActions.setAll({ shippings: results }))
    );
  });
}
