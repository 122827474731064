import { Address, ApiPagination } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AddressActions = createActionGroup({
  source: 'Address/API',
  events: {
    Enter: emptyProps(),
    Leave: emptyProps(),
    'Enter Form': emptyProps(),
    'Leave Form': emptyProps(),
    Load: emptyProps(),
    'Load Success': props<{ addresses: Address[]; pagination: ApiPagination }>(),
    'Load Failure': props<{ error: any }>(),

    Update: props<{ address: Partial<Address> }>(),
    'Update Success': props<{ address: Address }>(),
    'Update Failure': props<{ error: any }>(),

    Delete: props<{ id: number }>(),
    'Delete Success': props<{ id: number }>(),
    'Delete Failure': props<{ error: any }>(),

    Create: props<{ address: Address }>(),
    'Create Success': props<{ address: Address }>(),
    'Create Failure': props<{ error: any }>(),

    'Load One': props<{ id: number }>(),
    'Load One Success': props<{ address: Address }>(),
    'Load One Failure': props<{ error: any }>()
  }
});
