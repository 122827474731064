<ng-container *transloco="let t">
  @if (products(); as products) {
    <div
      class="shadow-inner w-full flex flex-row bg-brand-500 text-white max-sm:pl-1 p-3 py-1 text-md transition-all"
      [ngClass]="cartCount() ? 'translate-y-0' : 'translate-y-16'"
      (click)="showCart()">
      <strong class="pt-1.5 pb-2 w-2/12 font-normal">{{ t('shop.header.cart') }}:</strong>
      @if (mediaWatcher(); as mediaWatcher) {
        <ul
          class="flex flex-row -mt-3.5 ml-2.5 mr-0.5 w-1/2 xs:w-2/3 sm:w-3/4 text-sm pointer-events-none items-center">
          @for (product of products?.slice(0, mediaWatcher); track product.id; let count = $count) {
            <li
              class="flex flex-col items-center bounce-top"
              [ngStyle]="{ 'max-width': 80 / count + '%' }">
              <mzk-product-thumb
                [product]="product"
                size="cart"
                class="w-10 h-10 border-2 border-brand rounded-full shadow" />
              <span class="text-[8px]">&times;{{ product?.quantity }}</span>
            </li>
          }
          @if (products?.length > mediaWatcher) {
            <div
              class="flex flex-col justify-center w-10 h-10 border-2 aspect-square bg-white text-xs font-semibold text-black border-brand rounded-full mb-2.5 shadow bounce-top -ml-2 text-center">
              +{{ products?.length - mediaWatcher }}
            </div>
          }
        </ul>
        <strong class="pt-1.5 pb-2 w-2/12 ml-2">
          {{ total() | mzkCurrency | async }}
        </strong>
      }
    </div>
  }
</ng-container>
