import { DOCUMENT, isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';

declare const window: any;
// declare const _tsConfig: any;

@Injectable({
  providedIn: 'root'
})
export class TrustedshopsService {
  private readonly document = inject(DOCUMENT);
  private readonly platformId = inject(PLATFORM_ID);

  init() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const _tsid = 'XDFA3756D43F6A4E107483E0D55626C9A';
    window._tsConfig = {
      yOffset: '0', // offset from page bottom */
      variant: 'reviews', // default, reviews, custom, custom_reviews */
      customElementId: '', // required for variants custom and custom_reviews */
      trustcardDirection: 'bottomLeft', // for custom variants: topRight, topLeft, bottomRight, bottomLeft */
      customBadgeWidth: '', // for custom variants: 40 - 90 (in pixels) */
      customBadgeHeight: '', // for custom variants: 40 - 90 (in pixels) */
      disableResponsive: 'false', // deactivate responsive behaviour */
      disableTrustbadge: 'false' // deactivate trustbadge */
    };
    const _ts = this.document.createElement('script');
    _ts.type = 'text/javascript';
    _ts.charset = 'utf-8';
    _ts.async = true;
    _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js';
    const __ts = document.getElementsByTagName('script')[0];
    __ts.parentNode.insertBefore(_ts, __ts);
  }
}
