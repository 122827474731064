import { Address } from '@manzuko/shared';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

import { AddressActions } from './address.actions';

export type AddressState = EntityState<Address>;

export const adapter: EntityAdapter<Address> = createEntityAdapter<Address>();

export const initialState: AddressState = adapter.getInitialState({});

export const addressFeature = createFeature({
  name: 'address',
  reducer: createReducer(
    initialState,

    on(AddressActions.loadSuccess, (state, { addresses }) => adapter.setAll(addresses, state))
  ),
  extraSelectors: ({ selectAddressState }) => adapter.getSelectors(selectAddressState)
});
