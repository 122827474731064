import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { SortOrder } from '@manzuko/shared';
import { getLangFromRouteParams } from '@manzuko/shared/utils/lang-from-params';
import { catchError, map } from 'rxjs';

import { ProductService, productWithUrls } from 'app/data-access/product';

import { BestsellerSeoService } from './bestseller-seo.service';

export const bestsellerResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const bestsellerSeoService = inject(BestsellerSeoService);
  const productsService = inject(ProductService);
  const router = inject(Router);

  const lang = getLangFromRouteParams(route?.params);
  const queryParams = route?.queryParams;
  const products = productsService.get({
    ...queryParams,
    language: lang,
    sort: 'piecesSale90',
    order: SortOrder.desc
  });

  return products.pipe(
    map((productsResults) => {
      const results = productsResults?.results.map((product) => ({
        ...productWithUrls(product, lang)
      }));
      const title = lang === 'en' ? 'Bestsellers' : 'Najczęściej kupowane';
      bestsellerSeoService.setSeo(lang, title);

      const pagination = productsResults?.pagination;
      // const currentUrl = state.url;
      // const correctUrl = `${lang === 'en' ? '/en' : ''}/najczesciej-kupowane`;
      // const params = new URLSearchParams(queryParams);

      // const correctUrlWithParams = `${correctUrl}${
      //   Object.keys(queryParams)?.length ? '?' + params.toString() : ''
      // }`;

      // if (currentUrl !== correctUrlWithParams) {
      //   router.navigateByUrl(correctUrlWithParams);
      // }

      return {
        results,
        pagination
      };
    }),
    catchError((error) => router.navigateByUrl('/not-found'))
  );
};
