import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { Cart } from '@manzuko/shared';
import { Store } from '@ngrx/store';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';

import { CartActions, selectSideCartView } from 'app/data-access/cart';
import { LayoutActions } from 'app/data-access/layout';

import { CartProductComponent } from '../cart-product/cart-product.component';
import { CurrencyPipe, RouterLinkPipe } from '../pipes';

@Component({
  standalone: true,
  selector: 'mzk-cart',
  templateUrl: './cart.component.html',
  imports: [
    AsyncPipe,
    NgClass,
    RouterModule,
    CartProductComponent,
    TranslocoDirective,
    CurrencyPipe,
    FastSvgComponent,
    RouterLinkPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartComponent implements OnInit {
  readonly #store = inject(Store);

  protected readonly view = this.#store.selectSignal(selectSideCartView);

  @Input() isOpen = false;
  @Input() cart?: Cart;
  @Output() readonly closed: EventEmitter<void> = new EventEmitter();

  clear(): void {
    this.#store.dispatch(CartActions.removeCart());
  }

  hideCart(): void {
    this.#store.dispatch(LayoutActions.toggleCart({ showCart: false }));
  }

  ngOnInit(): void {
    this.#store.dispatch(CartActions.get());
    this.#store.dispatch(CartActions.loadCartProducts());
  }
}
