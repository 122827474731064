import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectLayoutLanguage } from 'app/data-access/layout';
import { productWithUrls } from 'app/data-access/product/product.helpers';
import { selectProductEntities } from 'app/data-access/product/product.selectors';
import { selectQueryParam, selectRouteParam, selectUrl } from 'app/data-access/router';

import { TagState } from './tag.reducer';

export const selectTagState = createFeatureSelector<TagState>('tag');

export const selectTagPagination = createSelector(selectTagState, (state) => state?.pagination);
export const selectTagProductsIds = createSelector(selectTagState, (state) => state?.productsIds);
export const selectTagPage = selectQueryParam('page');
export const selectTagTerm = selectRouteParam('tag');

export const selectTagPageLanguageUrl = createSelector(
  selectTagPage,
  selectLayoutLanguage,
  selectTagTerm,
  selectUrl,
  (page: string, language: string, tag: string, url: string) => ({
    page,
    language,
    tag,
    url
  })
);

export const selectTagProducts = createSelector(
  selectTagProductsIds,
  selectProductEntities,
  selectLayoutLanguage,
  (ids, entities, language) => {
    return ids?.map((id) => productWithUrls(entities?.[id], language));
  }
);

export const selectTagView = createSelector(
  selectTagPagination,
  selectTagProducts,
  selectTagTerm,
  selectLayoutLanguage,
  (pagination, products, tag: string, language: string) => {
    const tagString = tag ? tag?.split('-')?.slice(1)?.join(' ') : '';

    return {
      pagination,
      products,
      tag: tagString,
      language
    };
  }
);
