import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { CartItem } from '@manzuko/shared';
import { Store } from '@ngrx/store';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';

import { CartActions } from 'app/data-access/cart';

import { DisableMouseScrollDirective } from '../directives';
import { FormControlInputComponent } from '../form-control-input/form-control-input.component';
import { CurrencyPipe } from '../pipes';
import { ProductThumbComponent } from '../product-thumb/product-thumb.component';

@Component({
  standalone: true,
  selector: 'mzk-cart-product',
  templateUrl: './cart-product.component.html',
  styleUrls: ['./cart-product.component.scss'],
  imports: [
    AsyncPipe,
    RouterLink,
    ProductThumbComponent,
    TranslocoDirective,
    CurrencyPipe,
    ReactiveFormsModule,
    FastSvgComponent,
    DisableMouseScrollDirective,
    FormControlInputComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartProductComponent implements OnChanges {
  private readonly store = inject(Store);

  @Input() item: CartItem;
  @Input() showNameOnVoucher = false;

  public nameOnVoucherControl = new FormControl<string>(null);

  public quantityControl = new FormControl<number>(null, [
    Validators.required,
    Validators.min(1),
    Validators.pattern(/^\d+$/)
  ]);

  protected readonly nameOnVoucherChange$ = this.nameOnVoucherControl.valueChanges.pipe(
    distinctUntilChanged(),
    debounceTime(500),
    tap((nameOnVoucher) => this.updateQuantity(this.item?.product?.id, 0, nameOnVoucher))
  );
  // TODO: keep it dumb, move below logic out

  ngOnChanges(): void {
    this.quantityControl.patchValue(this.item?.quantity);
  }

  updateQuantity(
    productId: CartItem['productId'],
    quantityChange: number,
    nameOnVoucher: string
  ): void {
    this.store.dispatch(
      CartActions.updateProductQuantity({
        productId,
        quantityChange,
        nameOnVoucher
      })
    );
  }

  removeProduct(productId: number): void {
    this.store.dispatch(CartActions.removeProduct({ productId }));
  }
}
