import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';

import {
  selectCategoryNavSelected,
  selectCategoryNavSelectedSubcategories,
  selectCategoryTopLevel
} from 'app/data-access/category/category.selectors';
import { LayoutActions, selectLayoutShowMenu } from 'app/data-access/layout';
import { ClickOutsideDirective } from 'app/shared/directives';
import { FooterContactComponent } from 'app/shared/footer-contact/footer-contact.component';
import { RouterLinkPipe } from 'app/shared/pipes';

import { NavCategoryComponent } from '../nav-category/nav-category.component';

@Component({
  standalone: true,
  selector: 'mzk-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  imports: [
    AsyncPipe,
    NgClass,
    RouterLink,
    RouterLinkActive,
    TranslocoDirective,
    FastSvgComponent,
    NavCategoryComponent,
    FooterContactComponent,
    ClickOutsideDirective,
    RouterLinkPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent {
  readonly #store = inject(Store);

  protected readonly categories = this.#store.selectSignal(selectCategoryTopLevel);
  protected readonly showMenu = this.#store.selectSignal(selectLayoutShowMenu);

  // protected readonly selectedNav = this.#store.selectSignal(selectLayoutSelectedNav);
  protected readonly selectedCategory = this.#store.selectSignal(selectCategoryNavSelected);
  protected readonly selectedSubcategories = this.#store.selectSignal(
    selectCategoryNavSelectedSubcategories
  );

  protected readonly infoLinks = [
    { id: 'about', title: 'O nas', link: '/content/10-o-nas' },
    { id: 'delivery', title: 'Dostawa', link: '/content/7-wysylka' },
    { id: 'faq', title: 'FAQ', link: '/content/8-faq-najczesciej-zadawane-pytania' },
    { id: 'tos', title: 'Regulamin', link: '/regulamin-sklepu' },
    { id: 'privacy', title: 'Polityka prywatności', link: '/polityka-prywatnosci' }
  ];

  toggleMenu() {
    this.#store.dispatch(LayoutActions.toggleMenu({}));
  }

  selectNav(selectedNav: number, $event): void {
    // act as normal link if user wants to open in new tab
    if ($event.ctrlKey) {
      return;
    }

    $event.preventDefault();
    $event.stopPropagation();
    this.#store.dispatch(LayoutActions.selectNav({ selectedNav }));
  }
}
