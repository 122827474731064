import { Category } from '@manzuko/shared';
import { Dictionary } from '@ngrx/entity';
import { createSelector, defaultMemoize } from '@ngrx/store';

import { selectLayoutLanguage, selectLayoutSelectedNav } from '../layout/layout.selectors';
import { selectQueryParam, selectRouteParam } from '../router';
import { categoryWithUrls } from './category.helpers';
import { adapter, categoryFeature, CategoryState } from './category.reducer';

export const { selectCategoriesState } = categoryFeature;

const { selectIds, selectEntities, selectAll, selectTotal } = adapter?.getSelectors() || {};

export const selectCategoryIds = createSelector(selectCategoriesState, selectIds);
export const selectCategoryEntities = createSelector(selectCategoriesState, selectEntities);
export const selectCategoryAll = createSelector(selectCategoriesState, selectAll);
export const selectCategoryTotal = createSelector(selectCategoriesState, selectTotal);

export const selectCategoryCurrentId = createSelector(
  selectCategoriesState,
  (state: CategoryState) => state.selectedId
);

export const selectCategoryCurrent = createSelector(
  selectCategoryEntities,
  selectCategoryCurrentId,
  (entities: Dictionary<Category>, selectedId: number) => entities[selectedId]
);

export const selectCategoryIdParam = selectRouteParam('categoryId');
export const selectCategoryPageParam = selectQueryParam('page');

export const selectCategory = createSelector(
  selectCategoryEntities,
  selectCategoryIdParam,
  selectLayoutLanguage,
  (entities: Dictionary<Category>, categoryId: string, language: string) =>
    categoryWithUrls(entities?.[categoryId], language)
);

export const selectCategoryAllWithUrls = createSelector(
  selectCategoryAll,
  selectLayoutLanguage,
  (categories: Category[], language: string) =>
    categories?.map((category) => categoryWithUrls(category, language))
);

const _selectCategoryByParent = (parentId: number) =>
  createSelector(
    selectCategoryAllWithUrls,
    (categories: Category[]) => categories?.filter((category) => category?.parentId === parentId)
  );
export const selectCategoryByParent = (parentId: number) =>
  defaultMemoize(_selectCategoryByParent).memoized(parentId);

export const selectCategoryTopLevel = createSelector(
  selectCategoryByParent(2),
  (categories: Category[]) => categories?.sort((a, b) => a.position - b.position)
);

export const selectCategoryAllActive = selectCategoryAllWithUrls;

export const selectCategoryByIdAndPage = createSelector(
  selectCategoryIdParam,
  selectCategoryPageParam,
  (id, page) => ({ id, page })
);

export const selectCategoryWithPage = createSelector(
  selectCategory,
  selectCategoryPageParam,
  selectLayoutLanguage,
  (category, page, language: string) => ({ category, page, language })
);

export const selectCategoryTree = createSelector(
  selectCategoryAllWithUrls,
  (categories: Category[]) => {
    // array to flat tree
    // const tree = categories.reduce((acc, category) => {
    //   const { parentId } = category;
    //   if (parentId === 2) {
    //     return [...acc, category];
    //   }
    //   const parent = acc.find((item) => item.id === parentId);
    //   if (parent) {
    //     parent.children = [...(parent.children || []), category];
    //   }
    //   return acc;
    // }, []);

    // console.log(tree);
    return categories?.sort((a, b) => a?.parentId - b?.parentId);
  }
);

export const selectCategoryNavSelectedId = selectLayoutSelectedNav;

export const selectCategoryNavSelected = createSelector(
  selectCategoryNavSelectedId,
  selectCategoryEntities,
  selectLayoutLanguage,
  (selectedId, entities, language) =>
    selectedId ? categoryWithUrls(entities?.[selectedId], language) : null
);

export const selectCategoryNavSelectedSubcategories = createSelector(
  selectCategoryNavSelectedId,
  selectCategoryAllActive,
  (selectedId: number, categories: Category[]) =>
    categories?.filter(({ parentId }) => parentId === selectedId)
);
