import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Pipe({
  standalone: true,
  name: 'mzkFormGroup'
})
export class FormGroupPipe implements PipeTransform {
  public transform(value: FormControl | FormGroup, name: string): FormGroup {
    return (value?.get(name) as FormGroup) ?? new FormGroup({});
  }
}
