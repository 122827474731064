<form [formGroup]="form" (ngSubmit)="onSubmit(form)" data-test="newsletter-form">
  <section
    id="newsletter"
    *transloco="let t; read: 'shop.newsletter'"
    class="flex flex-col justify-center items-center sm:items-center">
    <div class="font-bold mt-2 mb-2 sm:text-2xl sm:tracking-normal">
      {{ t('title') }}
    </div>

    <div class="flex">
      <span
        class="inline-flex items-center px-3 text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
        &#64;
      </span>
      <input
        type="text"
        id="website-admin"
        data-test="newsletter-input"
        formControlName="email"
        class="rounded-none bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 border-gray-300 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-[10.4rem] sm:w-54"
        placeholder="{{ t('placeholder') }}" />

      <button
        type="submit"
        data-test="newsletter-submit"
        gaEvent="newsletter"
        class="inline-flex items-center pl-2 pr-3 text-gray-900 bg-gray-200 rounded-r-md border border-l-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
        {{ t('button') }}
      </button>
    </div>
    @if (form.invalid && form.touched && !form.pristine) {
      <div
        class="mt-3 p-4 mb-2 text-red-700 bg-red-200 rounded-lg dark:bg-red-200 dark:text-red-800"
        role="alert"
        data-test="newsletter-error">
        <span class="font-medium">{{ t('error') }}</span>
      </div>
    }
    @if (success$ | async) {
      <div
        class="mt-3 p-4 mb-2 text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
        role="alert"
        data-test="newsletter-success">
        <span class="font-medium">{{ t('success') }}</span>
      </div>
    }
    @if (error$ | async; as error) {
      <div
        class="mt-3 p-4 mb-2 text-red-700 bg-red-200 rounded-lg dark:bg-red-200 dark:text-red-800"
        role="alert"
        data-test="newsletter-error">
        <span class="font-medium">{{ t(error) }}</span>
      </div>
    }
  </section>
</form>
@if (formValueChange$ | async) {}
@if (onSubmit$ | async) {}
