import { config, Group } from '@manzuko/shared';

export const groupPath = (
  { id, slug, slugPl, slugEn }: Partial<Group> = {},
  lang?: string
): string => {
  if (lang === 'en') {
    return `/en/grupa/${id || 0}`; // -${slugEn || slug || ''}`;
  }
  if (slugPl) {
    return `/grupa/${id || 0}`; // -${slugPl || ''}`;
  }
  return `/grupa/${id || 0}`; // -${slug || ''}`;
};

export const groupSeoUrl = (group: Partial<Group> = {}, page = 1, lang?: string): string => {
  return `${config.website.url}${groupPath(group, lang)}${page > 1 ? `?page=${page}` : ''}`;
};
