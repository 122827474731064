import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  inject,
  Input,
  OnInit,
  TemplateRef
} from '@angular/core';
import { FormControl, FormControlStatus, ReactiveFormsModule, Validators } from '@angular/forms';
import { distinctUntilChanged, Observable, tap } from 'rxjs';

import { DisableMouseScrollDirective } from '../directives';
import phonePlMask from './phone-pl.mask';

import type { MaskitoOptions } from '@maskito/core';

@Component({
  standalone: true,
  selector: 'mzk-form-control-input',
  templateUrl: './form-control-input.component.html',
  imports: [CommonModule, ReactiveFormsModule, DisableMouseScrollDirective]
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormControlInputComponent implements OnInit {
  readonly #cdr = inject(ChangeDetectorRef);

  @Input() control: FormControl = new FormControl<string | number>(null);
  @Input() isRequired = false;
  @Input() isReadonly = false;
  @Input() label: string;
  @Input() error?: string;
  @Input() errors?: Record<string, string>;
  @Input() type = 'text';
  @Input() options: any;
  @Input() placeholder = '';
  @Input() autocomplete = '';
  @Input() tooltip = '';
  @Input() hint = '';
  @Input() name: string = this.constructor?.['ɵcmp']?.id;
  @Input() mask: MaskitoOptions;

  @ContentChild(TemplateRef) inputTemplate: TemplateRef<any>;

  protected valueChanges$: Observable<string>;
  protected statusChanges$: Observable<FormControlStatus>;

  protected readonly requiredValidator = Validators.required;
  protected readonly phonePlMask: MaskitoOptions = phonePlMask;

  ngOnInit(): void {
    this.valueChanges$ = this.control?.valueChanges?.pipe(
      distinctUntilChanged(),
      tap(() => this.#cdr.markForCheck())
    );
    this.statusChanges$ = this.control?.statusChanges?.pipe(
      distinctUntilChanged(),
      tap(() => this.#cdr.markForCheck())
    );
  }
}
