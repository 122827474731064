import { environment } from 'environments/environment';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

/**
 * These imports are only present in Browser (Client) version of the app.
 * Not provided to Server-side rendered app.
 */
export const APP_BROWSER_IMPORTS = [
  // BrowserAnimationsModule
  // HotToastModule.forRoot({
  //   position: 'top-right',
  //   dismissible: true,
  //   className: 'w-100 h-20 bg-green-500 text-white opacity-80'
  // }),
  NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsId),
  NgxGoogleAnalyticsRouterModule
  // PixelModule.forRoot({ enabled: true, pixelId: '1150440965045382' })
  // FuseSplashScreenModule
];
