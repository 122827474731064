import { PackageDimensions } from './interfaces/package-dimensions.dto';

export const paczkomatDimensions: PackageDimensions[] = [
  { id: 'small', name: 'small (8 x 38 x 64cm)', length: '80', width: '380', height: '640' },
  {
    id: 'medium',
    name: 'medium (19 x 38 x 64cm)',
    length: '190',
    width: '380',
    height: '640'
  },
  {
    id: 'large',
    name: 'large (41 x 38 x 64cm)',
    length: '410',
    width: '380',
    height: '640'
  }
];

export const orlenDimensions: PackageDimensions[] = [
  {
    id: 'S',
    name: 'S (8 x 38 x 60cm do 25kg)',
    length: '80',
    width: '380',
    height: '600'
  },
  {
    id: 'M',
    name: 'M (19 x 38 x 60cm do 25kg)',
    length: '190',
    width: '380',
    height: '600'
  },
  {
    id: 'L',
    name: 'L (41 x 38 x 60cm do 25kg)',
    length: '410',
    width: '380',
    height: '600'
  }
];

export const pocztaDimensions: PackageDimensions[] = [
  { id: 'S', name: 'S (9 x 40 x 65cm)', length: '90', width: '400', height: '650' },
  {
    id: 'M',
    name: 'M (20 x 40 x 65cm)',
    length: '200',
    width: '400',
    height: '650'
  },
  {
    id: 'L',
    name: 'L (42 x 40 x 65cm)',
    length: '420',
    width: '400',
    height: '650'
  },
  {
    id: 'XL',
    name: 'XL (60 x 60 x 70cm)',
    length: '600',
    width: '600',
    height: '700'
  },
  {
    id: '2XL',
    name: '2XL (W + S + D <= 250cm, do 50kg)'
  }
];

export const dpdDimensions: PackageDimensions[] = [
  { id: 'S', name: 'S (9 x 40 x 65cm)', length: '90', width: '400', height: '650' },
  {
    id: 'M',
    name: 'M (20 x 40 x 65cm)',
    length: '200',
    width: '400',
    height: '650'
  },
  {
    id: 'L',
    name: 'L (42 x 40 x 65cm)',
    length: '420',
    width: '400',
    height: '650'
  },
  {
    id: 'XL',
    name: 'XL (60 x 60 x 70cm)',
    length: '600',
    width: '600',
    height: '700'
  },
  {
    id: '2XL',
    name: '2XL (W + S + D <= 250cm, do 50kg)'
  }
];
