import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { config } from '@manzuko/shared';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { JsonLdDocument } from 'jsonld';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  tap
} from 'rxjs';

import { selectAuthUserIsAdmin } from 'app/data-access/auth/auth.selectors';
import { categoryWithUrls } from 'app/data-access/category';
import { GoogleAnalyticsActions } from 'app/data-access/google-analytics';
import {
  LayoutActions,
  selectLayoutLanguage,
  selectLayoutShowSearch
} from 'app/data-access/layout';
import { productWithUrls } from 'app/data-access/product';
import { CategoryThumbComponent } from 'app/shared/category-thumb/category-thumb.component';
import { JsonLdComponent } from 'app/shared/json-ld/json-ld.component';
import { ProductThumbComponent } from 'app/shared/product-thumb/product-thumb.component';
import { SearchService } from 'app/shared/search.service';

@Component({
  standalone: true,
  selector: 'mzk-search',
  templateUrl: './search.component.html',
  imports: [
    AsyncPipe,
    NgClass,
    RouterLink,
    ReactiveFormsModule,
    ProductThumbComponent,
    CategoryThumbComponent,
    JsonLdComponent,
    TranslocoDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent {
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly searchService = inject(SearchService);

  siteLinkSearchBox: JsonLdDocument = {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    url: config.website.url,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${config.website.url}/szukaj?search_query={search_term_string}`,
      'query-input': 'required name=search_term_string'
    }
  };
  isAdmin$ = this.store.select(selectAuthUserIsAdmin);
  isOpen$ = this.store.select(selectLayoutShowSearch);

  searchInput = new FormControl<string>('');
  strict = new FormControl<boolean>(false);

  searchForm: FormGroup = new FormGroup({
    searchInput: this.searchInput,
    strict: this.strict
  });

  search$ = this.searchForm.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    filter(({ searchInput }) => searchInput?.length >= 2),
    tap(({ searchInput }) =>
      this.store.dispatch(GoogleAnalyticsActions.search({ search_term: searchInput }))
    ),
    concatLatestFrom(() => this.store.select(selectLayoutLanguage)),
    switchMap(([{ searchInput, strict }, language]) =>
      this.searchService.search({ search: searchInput, language, strict }).pipe(
        map((response) => ({
          ...response,
          categories: {
            ...response?.categories,
            results: response?.categories?.results?.map((category) =>
              categoryWithUrls(category, language)
            )
          },
          products: {
            ...response?.products,
            results: response?.products?.results?.map((product) =>
              productWithUrls(product, language)
            )
          }
        })),
        catchError((error) => {
          console.error(error);
          return of(null);
        })
      )
    ),
    catchError((error) => {
      console.error(error);
      return of(null);
    })
  );
  lang$ = this.store.select(selectLayoutLanguage);

  navigateToSearchResultsPage(lang: string) {
    const queryParams = { search: this.searchInput.value };
    const baseRoute = lang === 'en' ? '/en' : '';

    this.router.navigate([`${baseRoute}/szukaj`], { queryParams });
  }

  showSearch() {
    this.store.dispatch(LayoutActions.toggleSearch({ showSearch: true }));
  }

  closeSearch() {
    this.store.dispatch(LayoutActions.toggleSearch({ showSearch: false }));
  }
}
