import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { tap } from 'rxjs';

@Injectable()
export class HandleUnrecoverableStateService {
  private readonly document = inject(DOCUMENT);

  readonly recover$ = inject(SwUpdate)?.unrecoverable?.pipe(
    tap((event) => {
      alert('Wystąpił nieoczekiwany błąd:\n' + event.reason + '\n\nNastąpi odświeżenie strony');
      this.document?.location?.reload();
    })
  );
}
