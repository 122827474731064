@if (lang$ | async; as lang) {
  <a
    [routerLink]="product?.path | mzkLink | async"
    class="block min-w-full w-full aspect-square relative rounded-full overflow-hidden border border-neutral-200 group-hover:border-brand transition-colors ease-in-out bg-white">
    <picture
      #frame
      class="block overflow-hidden aspect-square object-cover text-white"
      [ngClass]="{ active: active }">
      <img
        #img
        [ngSrc]="imageUrl"
        [alt]="product?.name"
        fill
        [sizes]="
          '(max-width: 600px) 41vw, ((min-width: 600px) and (max-width: 960px)) 30vw, ((min-width: 960px) and (max-width: 1280px)) 22vw, ((min-width: 1280px) and (max-width: 1440px)) 17vw, (min-width: 1440px) 15vw'
        "
        class="min-w-full w-full aspect-square object-cover"
        [priority]="priority"
        (load)="loading$.next(false)" />
    </picture>
    @if (quantity) {
      <div
        class="absolute left-0 right-0 top-0 pt-2 bg-white bg-opacity-75 text-center text-[6px] text-black truncate leading-[1.2em]">
        <span class="inline-block max-w-1/2 truncate">
          {{ product?.reference }}
        </span>
      </div>
    }
    @if (quantity) {
      <div
        class="absolute left-0 right-0 bottom-0 pb-1 bg-white bg-opacity-75 text-center font-semibold text-sm text-neutral-500 -indent-1.5">
        &times; {{ quantity }}
      </div>
    }
  </a>
}
