import { Shipping } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ShippingActions = createActionGroup({
  source: 'Shipping/API',
  events: {
    'Load All': emptyProps(),
    'Set All': props<{ shippings: Shipping[] }>()

    // 'Add Shipping': props<{ shipping: Shipping }>(),
    // 'Set Shipping': props<{ shipping: Shipping }>(),
    // 'Upsert Shipping': props<{ shipping: Shipping }>(),
    // 'Add Shippings': props<{ shippings: Shipping[] }>(),
    // 'Upsert Shippings': props<{ shippings: Shipping[] }>(),
    // 'Update Shipping': props<{ update: Update<Shipping> }>(),
    // 'Update Shippings': props<{ updates: Update<Shipping>[] }>(),
    // 'Map Shipping': props<{ entityMap: EntityMapOne<Shipping> }>(),
    // 'Map Shippings': props<{ entityMap: EntityMap<Shipping> }>(),
    // 'Delete Shipping': props<{ id: string }>(),
    // 'Delete Shippings': props<{ ids: string[] }>(),
    // 'Delete Shippings By Predicate': props<{
    //   predicate: Predicate<Shipping>;
    // }>(),
    // 'Clear Shippings': emptyProps(),
  }
});
