import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { fromEvent, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';

import { AuthActions, selectAuthUserEmail } from '../auth';
import { CheckoutActions } from '../checkout';
import { GoogleAnalyticsActions } from '../google-analytics';
import { LayoutActions } from '../layout/layout.actions';
import { ProductActions, selectProductById } from '../product';
import { SalesmanagoService } from '../salesmanago/salesmanago.service';
import { CartActions } from './cart.actions';
import { selectCartProductsIds, selectCartRequestProperties } from './cart.selectors';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class CartEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly cartService = inject(CartService);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly salesManagoService = inject(SalesmanagoService);

  setCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CartActions.addProductSuccess,
        CartActions.updateProductQuantitySuccess,
        CartActions.loadVoucherSuccess,
        CartActions.refreshSuccess,
        CartActions.mergeCartsSuccess,
        CartActions.removeProductSuccess,
        CartActions.removeCartSuccess,
        CartActions.setShippingSuccess,
        CartActions.setPaymentSuccess,
        CartActions.getSuccess,
        CartActions.removeVoucherSuccess
      ),
      map(({ cart }) => CartActions.set({ cart }))
    );
  });

  sendCartToSalesManago$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          CartActions.addProductSuccess,
          CartActions.updateProductQuantitySuccess,
          CartActions.mergeCartsSuccess,
          CartActions.removeProductSuccess
        ),
        concatLatestFrom(() => this.store.select(selectAuthUserEmail)),
        switchMap(([{ cart }, email]) => {
          const event = {
            email: cart?.user?.email || email,
            contactEvent: {
              eventId: cart?.salesManagoId,
              externalId: cart?.id,
              location: 'manzuko_com',
              contactExtEventType: 'CART',
              date: new Date().getTime(),
              value: cart?.productsPriceWithDiscounts || cart?.productsPriceWithDiscounts,
              products: cart?.products?.map((product) => product?.reference).join(', ')
            }
          };

          if (cart?.salesManagoId) {
            return this.salesManagoService.updateContactExtEvent(event);
          }

          return this.salesManagoService
            .addContactExtEvent(event)
            .pipe(
              switchMap((response) =>
                this.cartService.update(cart?.id, { salesManagoId: response?.eventId })
              )
            );
        })
      );
    },
    { dispatch: false }
  );

  clearCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout, CheckoutActions.createOrderSuccess),
      map(() => CartActions.clear())
    );
  });

  addToCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.addProduct),
      concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
      exhaustMap(([{ productId }, { cart, language, currency, userId }]) =>
        this.cartService.addToCart({ productId, cart, language, currency, quantityChange: 1 }).pipe(
          map((cart) => CartActions.addProductSuccess({ cart })),
          catchError((error) => of(CartActions.addProductFailure({ error })))
        )
      )
    );
  });

  // refreshOnEnterPage$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(LayoutActions.enter),
  //     concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
  //     // filter(([, { cart }]) => !!cart),
  //     map(([, { cart, language, currency }]) => CartActions.refresh({ cart, language, currency }))
  //   );
  // });

  refreshCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.refresh),
      switchMap(({ cart, language, currency }) =>
        this.cartService.refreshCart({ cart, language, currency }).pipe(
          map((cart) => CartActions.refreshSuccess({ cart })),
          catchError((error) => of(CartActions.refreshFailure({ error })))
        )
      )
    );
  });

  updateProductQuantity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateProductQuantity),
      concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
      exhaustMap(
        ([{ productId, quantityChange, nameOnVoucher }, { cart, language, currency, userId }]) => {
          return this.cartService[quantityChange > 0 ? 'addToCart' : 'subtractFromCart']({
            productId,
            cart,
            language,
            nameOnVoucher,
            currency,
            quantityChange
          }).pipe(
            map((cart) => CartActions.updateProductQuantitySuccess({ cart })),
            catchError((error) => of(CartActions.updateProductQuantityFailure({ error })))
          );
        }
      )
    );
  });

  mergeCartsOnLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginSuccess, AuthActions.loginSuccessFromLocalStorage),
      map(({ auth }) => CartActions.mergeCarts({ userId: auth.user.id }))
    );
  });

  mergeCarts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.mergeCarts),
      concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
      switchMap(([{ userId }, { cart, language, currency }]) => {
        return this.cartService.mergeCarts({ userId, cart, language, currency }).pipe(
          map((cart) => CartActions.mergeCartsSuccess({ cart })),
          catchError((error) => of(CartActions.mergeCartsFailure({ error })))
        );
      })
    );
  });

  removeCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.removeCart),
      concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
      switchMap(([, { cart, language, currency, userId }]) => {
        return this.cartService.removeCart({ cart }).pipe(
          map((cart) => CartActions.removeCartSuccess({ cart })),
          catchError((error) => of(CartActions.removeCartFailure({ error })))
        );
      })
    );
  });

  loadCartProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.loadCartProducts),
      concatLatestFrom(() => this.store.select(selectCartProductsIds)),
      filter(Boolean),
      map(([_, ids]) => ProductActions.loadMany({ ids }))
    );
  });

  // getCartOnInitPage$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(LayoutActions.enter),
  //     take(1),
  //     map(() => CartActions.get())
  //   );
  // });

  getCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.get),
      concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
      switchMap(([, { cart, language, currency, userId }]) => {
        return this.cartService.get(cart, userId, language, currency).pipe(
          map((cart) => CartActions.getSuccess({ cart })),
          catchError((error) => of(CartActions.getFailure({ error })))
        );
      })
    );
  });

  loadCartProductsOnLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.mergeCartsSuccess),
      map(({ cart }) => cart?.products?.map((product) => product.id)),
      filter(Boolean),
      map((ids) => ProductActions.loadMany({ ids }))
    );
  });

  removeProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.removeProduct),
      concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
      switchMap(([{ productId }, { cart, language, currency, userId }]) => {
        return this.cartService.removeProductFromCart(productId, cart).pipe(
          map((cart) => CartActions.removeProductSuccess({ cart })),
          catchError((error) => of(CartActions.removeProductFailure({ error })))
        );
      })
    );
  });

  refreshCartOnSetLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LayoutActions.setLanguage),
      concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
      map(([{ language }, { cart }]) => {
        const currency = language === 'en' ? 'EUR' : 'PLN';
        return CartActions.refresh({ cart, language, currency });
      })
    );
  });

  setVoucher$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.loadVoucher),
      concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
      switchMap(([{ code }, { cart, language, currency }]) => {
        return this.cartService.loadVoucher({ cart, code, language, currency }).pipe(
          map((cart) => CartActions.loadVoucherSuccess({ cart })),
          catchError((error) => of(CartActions.loadVoucherFailure({ error })))
        );
      })
    );
  });

  removeVoucher$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.removeVoucher),
      concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
      switchMap(([, { cart, language, currency }]) => {
        return this.cartService.removeCartVoucher({ cart, language, currency }).pipe(
          map((cart) => CartActions.removeVoucherSuccess({ cart })),
          catchError((error) => of(CartActions.removeVoucherFailure({ error })))
        );
      })
    );
  });

  setShipping$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.setShipping),
      concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
      switchMap(([{ shippingId }, { cart, language, currency }]) => {
        return this.cartService.setShipping({ cart, shippingId, language, currency }).pipe(
          map((cart) => CartActions.setShippingSuccess({ cart })),
          catchError((error) => of(CartActions.setShippingFailure({ error })))
        );
      })
    );
  });

  sendAddShippingInfoToGa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.setShippingSuccess),
      map(({ cart }) => GoogleAnalyticsActions.addShippingInfo({ cart }))
    );
  });

  setPayment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.setPayment),
      concatLatestFrom(() => this.store.select(selectCartRequestProperties)),
      switchMap(([{ payment }, { cart, language, currency }]) => {
        return this.cartService.setPayment({ cart, payment, language, currency }).pipe(
          map((cart) => CartActions.setPaymentSuccess({ cart })),
          catchError((error) => of(CartActions.setPaymentFailure({ error })))
        );
      })
    );
  });

  sendAddPaymentInfoToGa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.setPaymentSuccess),
      map(({ cart }) => GoogleAnalyticsActions.addPaymentInfo({ cart }))
    );
  });

  updateCartOnOrderCreateSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.update),
      switchMap(({ id, cart }) =>
        this.cartService.update(id, cart).pipe(
          map((cart) => CartActions.updateSuccess({ cart })),
          catchError((error) => of(CartActions.updateFailure({ error })))
        )
      )
    );
  });

  updateCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CheckoutActions.createOrderSuccess),
      map(({ order }) => {
        const { _id, ...cart } = order;
        return CartActions.update({
          id: cart.cartId,
          cart: {
            ...cart,
            id: cart.cartId,
            isOrdered: true,
            orderId: order.id
          }
        });
      })
    );
  });

  addToCartSendToGa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.addProduct),
      concatLatestFrom(({ productId }) => this.store.select(selectProductById(productId))),
      map(([{ productId }, product]) => GoogleAnalyticsActions.addToCart({ product, quantity: 1 }))
    );
  });

  addOnUpdateCartSendToGa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateProductQuantity),
      filter(({ quantityChange }) => quantityChange > 0),
      concatLatestFrom(({ productId, quantityChange }) =>
        this.store.select(selectProductById(productId))
      ),
      map(([{ productId, quantityChange }, product]) =>
        GoogleAnalyticsActions.addToCart({ product, quantity: quantityChange })
      )
    );
  });

  addOnUpdateCartSendToEdrone$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CartActions.updateProductQuantity),
        filter(({ quantityChange }) => quantityChange > 0),
        concatLatestFrom(({ productId, quantityChange }) =>
          this.store.select(selectProductById(productId))
        )
      );
    },
    { dispatch: false }
  );

  removeOnUpdateCartSendToGa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartActions.updateProductQuantity),
      filter(({ quantityChange }) => quantityChange < 0),
      concatLatestFrom(({ productId, quantityChange }) =>
        this.store.select(selectProductById(productId))
      ),
      map(([{ productId, quantityChange }, product]) =>
        GoogleAnalyticsActions.removeFromCart({ product, quantity: quantityChange })
      )
    );
  });

  storeActions$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CartActions.set),
        tap((action) => {
          const storedActions = window?.localStorage?.getItem('storedCartActions');
          const actions = storedActions ? JSON.parse(storedActions) : [];
          const newActions = [action];
          window?.localStorage?.setItem('storedCartActions', JSON.stringify(newActions));
        })
      );
    },
    { dispatch: false }
  );

  onStorageActionsChange$ = isPlatformBrowser(this.platformId)
    ? createEffect(() => {
        return fromEvent<StorageEvent>(window, 'storage').pipe(
          filter((event) => event.key === 'storedCartActions'),
          filter((event) => event.newValue !== null),
          filter((event) => {
            const [{ type }] = JSON.parse(event.newValue);
            return type === '[Cart/API] Set';
          }),
          map((event) => {
            const [{ type, cart }] = JSON.parse(event.newValue);

            return CartActions.setFromLocalStorage({ cart });
          })
        );
      })
    : () => {};
}
