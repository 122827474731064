import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiSecureRoutes } from '@manzuko/shared';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { combineLatest, first, Observable, switchMap } from 'rxjs';

import { selectAuthSecureRoutes, selectAuthToken } from './auth.selectors';

/**
 * This interceptor handles all of the ongoing requests.
 * It adds an authentication token if available in the auth-service.
 * All of the ongoing requests are passed to the requests-service to handle and show an error if required.
 */
@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  private readonly store = inject(Store);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return combineLatest([
      this.store.select(selectAuthSecureRoutes),
      this.store.select(selectAuthToken)
    ]).pipe(
      first(),
      switchMap(([secureRoutes, token]) => {
        const newRequest =
          token || this.isSecureRoute(request, secureRoutes)
            ? request.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
              })
            : request;

        return next.handle(newRequest);
      })
    );
  }

  private isSecureRoute(
    { url, method }: HttpRequest<unknown>,
    secureRoutes: ApiSecureRoutes
  ): boolean {
    const path = url?.split(environment.api)?.[1];
    if (!path || !secureRoutes) {
      return false;
    }

    return secureRoutes[path]?.includes(method);
  }
}
