import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Setting } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingAdminService {
  private readonly http = inject(HttpClient);

  getAll(): Observable<Setting> {
    return this.http.get<Setting>(
      `${environment.api}/admin/settings/settings?nocache=${new Date()?.getTime()}`
    );
  }

  update(settings: Setting[]): Observable<Setting> {
    return this.http.patch<Setting>(`${environment.api}/admin/settings/settings`, settings);
  }
}
