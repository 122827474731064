import { inject, Injectable } from '@angular/core';
import { Group } from '@manzuko/shared';

import { SEOService } from 'app/shared/seo.service';
import { shorten, stripHtmlTags } from 'app/utils';

import { groupSeoUrl } from './group.helpers';

@Injectable({
  providedIn: 'root'
})
export class GroupSeoService {
  private readonly seoService = inject(SEOService);

  setSeo(group: Partial<Group> = {}, page = 1, lang: string): void {
    const pageTitle = page > 1 ? `- ${lang === 'en' ? 'Page' : 'Strona'} ${page}` : '';
    const title = group?.metaTitle || group?.name;
    const metaTitle = shorten(title, pageTitle ? 40 : 50);
    this.seoService.setPageTitle(`${metaTitle} ${pageTitle}`);

    const cleanDescription = stripHtmlTags(
      group?.metaDescription || group?.description || `${metaTitle} ${pageTitle}`
    );
    const description = shorten(cleanDescription, 160);

    const metaTags: Record<string, string> = {
      description
      // keywords: group?.metaKeywords
    };
    const ogTags: Record<string, string> = {
      // 'og:type': 'product',
      'og:title': metaTitle,
      // 'og:description': description,
      'og:url': groupSeoUrl(group, page, lang),
      // 'og:image': `https://manzuko.com/assets/images/p/${group?.productImageId}.jpg`,
      'og:locale': lang === 'en' ? 'en_US' : 'pl_PL'
    };

    // this.seoService.updateMetaTags(metaTags);
    this.seoService.updateOgTags(ogTags);
    const groupSeoUrlPl = groupSeoUrl(group, page, 'pl');
    this.seoService.createLinkForCanonicalURL(groupSeoUrl(group, page, lang));
    this.seoService.createLinkForAlternatePl(groupSeoUrlPl);
    this.seoService.createLinkForAlternateEn(groupSeoUrl(group, page, 'en'));
    this.seoService.createLinkForAlternateDefault(groupSeoUrlPl);
  }
}
