import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { Provider } from '@angular/core';
import { environment } from 'environments/environment';

import { ImageLoaderProviderConfig } from './image-loader-provider-config';
import { withBreakpoints } from './with-breakpoints';

export const provideImageLoader = (config: ImageLoaderProviderConfig = {}): Provider => {
  const { baseUrl = environment.cdn, breakpoints = [] } = config;
  return [
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => `${config.src}&width=${config?.width || 1500}`
    },
    withBreakpoints(breakpoints)
  ];
};
