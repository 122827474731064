import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Favorite, FavoriteParams, FavoriteResults } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  private readonly http = inject(HttpClient);

  get(params?: FavoriteParams): Observable<FavoriteResults> {
    return this.http.get<FavoriteResults>(
      `${environment.api}/catalog/favorites?nocache=${new Date()?.getTime()}`,
      {
        params: params as HttpParams
      }
    );
  }

  create(productId: number): Observable<Favorite> {
    return this.http.post<Favorite>(`${environment.api}/catalog/favorites`, { productId });
  }

  delete(productId: number): Observable<Favorite> {
    return this.http.delete<Favorite>(`${environment.api}/catalog/favorites/${productId}`);
  }

  getAll(): Observable<Favorite[]> {
    return this.http.get<Favorite[]>(
      `${environment.api}/catalog/favorites/all?nocache=${new Date()?.getTime()}`
    );
  }
}
