import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, takeUntil } from 'rxjs';

import { ProductActions } from 'app/data-access/product';
import { SearchService } from 'app/shared/search.service';

import { SearchActions } from './search.actions';
import { selectSearchPageLanguageUrl } from './search.selectors';

@Injectable({
  providedIn: 'root'
})
export class SearchEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly searchService = inject(SearchService);

  loadSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.enter),
      concatMap(() =>
        this.store.select(selectSearchPageLanguageUrl).pipe(
          map(({ page, language, search, params }) =>
            SearchActions.load({ page: +page || 1, search, language, params })
          ),
          takeUntil(this.actions$.pipe(ofType(SearchActions.leave)))
        )
      )
    );
  });

  setSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.load),
      switchMap(({ page, search, language, params }) =>
        this.searchService.searchProducts({
          sort: params.sort,
          order: params.order,
          language,
          ...(search && { search }),
          page: +page,
          strict: params?.strict || false
        })
      ),
      map(({ results, pagination }) =>
        SearchActions.set({
          products: results,
          pagination
        })
      )
    );
  });

  setSearchProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchActions.set),
      map((results) => ProductActions.setProducts(results))
    );
  });
}
