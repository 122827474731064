import { ApiPagination, Favorite, FavoriteParams } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FavoriteActions = createActionGroup({
  source: 'Favorite/API',
  events: {
    'Enter ': emptyProps(),
    'Leave ': emptyProps(),

    Load: props<{ params: FavoriteParams }>(),
    'Load Success': props<{ favorites: Favorite[]; pagination: ApiPagination }>(),
    'Load Failure': props<{ error: any }>(),

    Set: props<{ favorites: Favorite[]; pagination: ApiPagination }>(),

    'Load All': emptyProps(),
    'Load All Success': props<{ favorites: Favorite[] }>(),
    'Load All Failure': props<{ error: any }>(),

    Add: props<{ productId: number }>(),
    'Add Success': props<{ favorite: Favorite }>(),
    'Add Failure': props<{ error: any }>(),

    Remove: props<{ productId: number }>(),
    'Remove Success': props<{ favorite: Favorite }>(),
    'Remove Failure': props<{ error: any }>()
  }
});
