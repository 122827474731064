<footer *transloco="let t; read: 'shop.footer'" class="flex flex-col py-15 bg-brand-800 text-white">
  <mzk-newsletter />
  <mzk-json-ld [json]="organization" />
  <mzk-json-ld [json]="website" />
  <mzk-json-ld [json]="localBusiness" />

  <section
    class="divide-y divide-white divide-opacity-10 md:divide-y-0 md:flex md:justify-around md:pt-12 xl:justify-evenly">
    <article id="products">
      <strong>{{ t('products') }}</strong>
      <ul class="p-3">
        <li>
          <a class="pt-5" data-test="footer-promotion" [routerLink]="'/promocje' | mzkLink | async">
            {{ t('sale') }}
          </a>
        </li>
        <li>
          <a data-test="footer-latest" [routerLink]="'/nowe-produkty' | mzkLink | async">
            {{ t('new') }}
          </a>
        </li>
        <li>
          <a
            data-test="footer-bestsellers"
            [routerLink]="'/najczesciej-kupowane' | mzkLink | async">
            {{ t('bestsellers') }}
          </a>
        </li>
        <li>
          <a data-test="footer-map" [routerLink]="'/mapa' | mzkLink | async">
            {{ t('map') }}
          </a>
        </li>
      </ul>

      <strong class="inline-block mt-5">
        {{ t('competition') }}
      </strong>
      <ul class="p-3">
        <li>
          <a
            data-test="footer-challenge-conditions"
            [routerLink]="'/content/11-weekendowe-wyzwanie-regulamin' | mzkLink | async">
            {{ t('challenge') }}
          </a>
        </li>
        <li>
          <a
            data-test="footer-handicraft-conditions"
            [routerLink]="'/content/19-pr-regulamin' | mzkLink | async">
            {{ t('review') }}
          </a>
        </li>
        <li>
          <a
            data-test="footer-promote"
            rel="nofollow"
            href="https://www.subscribepage.com/buduj_marke_z_manzuko">
            <strong>{{ t('promote') }}</strong>
          </a>
        </li>
        <li>
          @if (isAdmin()) {
            <a class="mt-5" data-test="footer-admin-panel" routerLink="/admin">
              {{ t('admin') }}
            </a>
          }
        </li>
      </ul>
    </article>

    <article id="information">
      <strong>{{ t('informations') }}</strong>
      <ul class="p-3">
        <li>
          <a data-test="footer-delivery" [routerLink]="'/content/7-wysylka' | mzkLink | async">
            {{ t('shipping') }}
          </a>
        </li>
        <li>
          <a
            data-test="footer-faq"
            [routerLink]="'/content/8-faq-najczesciej-zadawane-pytania' | mzkLink | async">
            {{ t('faq') }}
          </a>
        </li>
        <li>
          <a data-test="footer-about-us" [routerLink]="'/content/10-o-nas' | mzkLink | async">
            {{ t('about') }}
          </a>
        </li>
        <li>
          <a data-test="footer-contact" [routerLink]="'/kontakt' | mzkLink | async">
            {{ t('contact us') }}
          </a>
        </li>
        <li>
          <a data-test="footer-blog" target="_blank" href="https://manzuko.com/blog/">
            {{ t('blog') }}
          </a>
        </li>
        <li>
          <a
            data-test="footer-conditions"
            [routerLink]="'/content/3-regulamin-sklepu' | mzkLink | async">
            {{ t('terms') }}
          </a>
        </li>
        <li>
          <a
            data-test="footer-private-policy"
            [routerLink]="'/content/6-polityka-prywatnosci' | mzkLink | async">
            {{ t('privacy') }}
          </a>
        </li>
      </ul>
    </article>

    <article>
      <strong>{{ t('contact') }}</strong>
      <mzk-footer-contact></mzk-footer-contact>
    </article>
  </section>

  <div class="my-10 text-center">
    Manzuko &copy; 2011-2024 - All rights reserved
    <p class="block text-sm font-mono"># {{ t('version') }} {{ version }} #</p>
  </div>
</footer>
