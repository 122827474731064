import { CookieSettings } from '@manzuko/shared';
import { createFeature, createReducer, on } from '@ngrx/store';

import { LayoutActions } from '../layout/layout.actions';

export interface SettingState {
  showCookies: boolean;
  cookies: CookieSettings;
  language: string;
  currency: string;
}

const initialState: SettingState = {
  showCookies: true,
  cookies: {
    functional: false,
    analytics: false,
    performance: false,
    advertising: false,
    others: false
  },
  language: 'pl',
  currency: 'PLN'
};

export const settingFeature = createFeature({
  name: 'settings',
  reducer: createReducer(
    initialState,

    on(
      LayoutActions.acceptCookies,
      (state, { cookieSettings }): SettingState => ({
        ...state,
        showCookies: false,
        cookies: cookieSettings
      })
    )
  )
});
