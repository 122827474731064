import { ApiPagination, Favorite } from '@manzuko/shared';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

import { AuthActions } from '../auth';
import { FavoriteActions } from './favorite.actions';

export interface FavoriteState extends EntityState<Favorite> {
  pagination: ApiPagination;
  list: number[];
  productsIds: number[];
}

export const adapter: EntityAdapter<Favorite> = createEntityAdapter<Favorite>();

export const initialState: FavoriteState = adapter.getInitialState({
  pagination: {},
  list: [],
  productsIds: []
});

export const favoriteFeature = createFeature({
  name: 'favorite',
  reducer: createReducer(
    initialState,

    on(FavoriteActions.set, (state, { favorites }) => {
      return adapter.upsertMany(favorites, state);
    }),

    on(FavoriteActions.set, (state, { favorites, pagination }) => ({
      ...state,
      pagination,
      list: favorites?.map((favorite) => favorite?.productId)
    })),

    on(FavoriteActions.loadAllSuccess, (state, { favorites }) => {
      return adapter.upsertMany(favorites, state);
    }),

    on(FavoriteActions.loadAllSuccess, (state, { favorites }) => ({
      ...state,
      productsIds: favorites?.map((favorite) => favorite?.productId)
    })),

    on(FavoriteActions.removeSuccess, (state, { favorite }) => {
      return adapter.removeOne(favorite?.id, state);
    }),

    on(AuthActions.logout, (state) => {
      return adapter.removeAll({
        ...state,
        list: [],
        productsIds: []
      });
    })
  ),
  extraSelectors: ({ selectFavoriteState }) => adapter.getSelectors(selectFavoriteState)
});
