import { ApiPagination } from '@manzuko/shared';
import { createFeature, createReducer, on } from '@ngrx/store';

import { SearchActions } from './search.actions';

export interface SearchState {
  productsIds: number[];
  pagination: ApiPagination;
}

export const initialState: SearchState = {
  productsIds: [],
  pagination: {}
};

export const searchFeature = createFeature({
  name: 'search',
  reducer: createReducer(
    initialState,
    on(SearchActions.set, (state, { products, pagination }) => ({
      ...state,
      productsIds: products.map((product) => product.id),
      pagination
    }))
  )
});
