import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[ifIsBrowser]'
})
export class IfIsBrowserDirective implements OnInit {
  readonly #platformId = inject(PLATFORM_ID);
  readonly #templateRef = inject(TemplateRef<unknown>);
  readonly #viewContainer = inject(ViewContainerRef);

  ngOnInit(): void {
    if (isPlatformBrowser(this.#platformId)) {
      this.#viewContainer.createEmbeddedView(this.#templateRef);
      return;
    }
    this.#viewContainer.clear();
  }
}
