import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { filter, tap } from 'rxjs';

@Injectable()
export class PromptUpdateService {
  private readonly document = inject(DOCUMENT);

  readonly updates$ = inject(SwUpdate)?.versionUpdates?.pipe(
    filter((event: VersionEvent): event is VersionReadyEvent => event?.type === 'VERSION_READY'),
    tap(() => {
      // alert('Dostępna jest nowa wersja, nastąpi odświeżenie strony');
      // Reload the page to update to the latest version.
      this.document?.location?.reload();
    })
  );
}
