import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoDirective } from '@jsverse/transloco';
import { Cart } from '@manzuko/shared';
import { Store } from '@ngrx/store';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';
import { filter, map, merge, startWith } from 'rxjs';

import {
  CartActions,
  selectCartProducts,
  selectCartProductsCount,
  selectCartProductsTotalPrice
} from 'app/data-access/cart';
import { LayoutActions } from 'app/data-access/layout';

import { CurrencyPipe } from '../pipes';
import { ProductThumbComponent } from '../product-thumb/product-thumb.component';

@Component({
  standalone: true,
  selector: 'mzk-cart-micro',
  templateUrl: './cart-micro.component.html',
  imports: [
    AsyncPipe,
    NgClass,
    NgStyle,
    ProductThumbComponent,
    TranslocoDirective,
    CurrencyPipe,
    FastSvgComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartMicroComponent implements OnInit {
  readonly #store = inject(Store);
  readonly #observer = inject(BreakpointObserver);

  @Input() isOpen = false;
  @Input() cart?: Cart;
  @Output() readonly closed: EventEmitter<void> = new EventEmitter();

  private readonly breakpoints = [
    { mediaQuery: '(max-width: 300px)', value: 4 },
    { mediaQuery: '(min-width: 3011px) and (max-width: 349px)', value: 5 },
    { mediaQuery: '(min-width: 350px) and (max-width: 410px)', value: 6 },
    { mediaQuery: '(min-width: 411px) and (max-width: 550px)', value: 10 },
    { mediaQuery: '(min-width: 551px) and (max-width: 768px)', value: 16 },
    { mediaQuery: '(min-width: 769px) and (max-width: 960px)', value: 24 }
  ];

  protected readonly mediaWatcher = toSignal(
    merge(
      ...this.breakpoints.map((bp) =>
        this.#observer.observe(bp?.mediaQuery).pipe(
          filter((state) => state?.matches),
          map(() => bp?.value)
        )
      )
    )
  );

  // TODO: keep it dumb, move below logic out
  protected readonly cartCount = toSignal(
    this.#store.select(selectCartProductsCount).pipe(startWith(0))
  );
  protected readonly products = this.#store.selectSignal(selectCartProducts);
  protected readonly total = this.#store.selectSignal(selectCartProductsTotalPrice);

  clear(): void {
    this.#store.dispatch(CartActions.removeCart());
  }

  showCart(): void {
    this.#store.dispatch(LayoutActions.toggleCart({ showCart: true }));
  }

  hideCart(): void {
    this.#store.dispatch(LayoutActions.toggleCart({ showCart: false }));
  }

  ngOnInit(): void {
    this.#store.dispatch(CartActions.loadCartProducts());
  }
}
