import { Setting } from '@manzuko/shared';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

import { SettingAdminActions } from './setting.actions';

export const adapter: EntityAdapter<Setting> = createEntityAdapter<Setting>();

export const initialState: EntityState<Setting> = adapter.getInitialState({});

export const settingAdminFeature = createFeature({
  name: 'adminSetting',
  reducer: createReducer(
    initialState,

    on(SettingAdminActions.setList, (state, { settings }) => {
      return adapter.upsertMany(settings, state);
    })
  ),
  extraSelectors: ({ selectAdminSettingState }) => adapter.getSelectors(selectAdminSettingState)
});
