<a [routerLink]="category()?.path" class="block w-full aspect-square relative">
  @if (imageUrl(); as url) {
    <picture
      class="block w-full overflow-hidden rounded-full aspect-square border border-neutral-200 group-hover:border-brand transition-colors object-cover">
      <img
        loading="lazy"
        [src]="url"
        alt="{{ category()?.name }}"
        class="w-full h-full rounded-full aspect-square object-cover p-[1px]" />
    </picture>
  }
</a>
