import { Setting } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const SettingAdminActions = createActionGroup({
  source: 'Setting/Admin/API',
  events: {
    'Enter List': emptyProps(),
    'Leave List': emptyProps(),

    'Load List': emptyProps(),
    // TODO: Fix type! on load list success
    'Load List Success': props<{ settings: any }>(),
    'Load List Failure': props<{ error: any }>(),

    'Set List': props<{ settings: Setting[] }>(),

    'Submit Form': props<{ settings: Setting[] }>(),

    Update: props<{ settings: Setting[] }>(),
    'Update Success': props<{ settings: Setting }>(),
    'Update Failure': props<{ error: any }>()
  }
});
