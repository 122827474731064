import { inject, Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';

import { selectLayoutLanguage } from '../layout';
import { selectAuthUser } from './auth.selectors';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {
  private readonly store = inject(Store);
  private readonly router = inject(Router);

  canActivate(): boolean | Observable<boolean | UrlTree> | UrlTree {
    return this.checkAuthentication();
  }

  canLoad(): boolean | Observable<boolean | UrlTree> | UrlTree {
    return this.checkAuthentication();
  }

  checkAuthentication(): boolean | Observable<boolean | UrlTree> | UrlTree {
    return combineLatest([
      this.store.pipe(select(selectLayoutLanguage)),
      this.store.pipe(select(selectAuthUser))
    ]).pipe(
      map(([language, user]) => {
        if (user) {
          return true;
        }

        return this.router.createUrlTree([language === 'en' ? '/en/logowanie' : '/logowanie']);
      })
    );
  }
}
