import { createSelector, defaultMemoize } from '@ngrx/store';

import { selectQueryParams } from 'app/data-access/router';

import { productWithUrls, selectProductEntities, selectProductIdParam } from '../product';
import { favoriteFeature } from './favorite.reducer';

export const {
  selectFavoriteState,
  selectEntities: selectFavoriteEntities,
  selectAll: selectFavoriteAll,
  selectPagination: selectFavoritePagination,
  selectList: selectFavoriteList,
  selectProductsIds: selectFavoriteProductsIds
} = favoriteFeature;

export const selectFavoriteRouteParams = selectQueryParams;

export const selectFavoriteProductIds = createSelector(selectFavoriteAll, (favorites) =>
  favorites.map((favorite) => favorite?.productId)
);

export const selectFavoriteView = createSelector(
  selectProductEntities,
  selectFavoriteList,
  selectFavoritePagination,
  (entities, list, pagination) => ({
    favorites: list?.map((id) => productWithUrls(entities?.[id]))?.filter(Boolean),
    pagination
  })
);

export const selectFavoriteIsFavorite = createSelector(
  selectProductIdParam,
  selectFavoriteProductsIds,
  (productId, productsIds) => productsIds?.includes(+productId)
);

export const selectFavoriteProductIdsAsObject = createSelector(
  selectFavoriteProductsIds,
  (list) => list?.reduce((acc, id) => ({ ...acc, [id]: true }), {})
);

const _selectFavoriteIsFavoriteById = (id: number) =>
  createSelector(selectFavoriteProductIdsAsObject, (ids) => ids?.[id]);
export const selectFavoriteIsFavoriteById = (id: number) =>
  defaultMemoize(_selectFavoriteIsFavoriteById).memoized(id);
