import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Category, CategoryParams, CategoryResults } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private readonly http = inject(HttpClient);

  getNav(params: CategoryParams): Observable<Category[]> {
    return this.http.get<Category[]>(`${environment.api}/catalog/categories/nav`, {
      params: params as unknown as HttpParams
    });
  }

  getTree(params: CategoryParams): Observable<Category[]> {
    return this.http.get<Category[]>(`${environment.api}/catalog/categories/tree`, {
      params: params as unknown as HttpParams
    });
  }

  list(params?: CategoryParams): Observable<CategoryResults> {
    return this.http.get<CategoryResults>(`${environment.api}/catalog/categories`, {
      params: params as HttpParams
    });
  }

  get(params: CategoryParams): Observable<Category[]> {
    return this.http.get<Category[]>(`${environment.api}/catalog/categories/${params.id}`, {
      params: params as unknown as HttpParams
    });
  }
}
