import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslocoHttpLoader implements TranslocoLoader {
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get translation
   *
   * @param lang
   */
  getTranslation(lang: string) {
    return this._httpClient.get<Translation>(`${environment.url}/assets/i18n/${lang}.json`);
    // return import(`../../../../assets/i18n/${lang}.json`).then((res) => res.default);
  }
}
