// TODO: use date-fns/is-date?
function isDate(value: unknown): boolean {
  const iso8601 = /^\d{4}-\d\d-\d\d(T\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?$/;
  return typeof value === 'string' && iso8601.test(value);
}

export function convertObjectDateStringsToDate<T = object>(body: T): T {
  if (body === null || body === undefined) {
    return body;
  }

  if (Array.isArray(body)) {
    return body?.map((value) => convertObjectDateStringsToDate(value)) as T;
  }

  if (typeof body === 'object') {
    return Object.fromEntries(
      Object.entries(body)?.map(([key, value]) => [
        key,
        isDate(value) ? new Date(value) : convertObjectDateStringsToDate(value)
      ])
    ) as T;
  }

  return body;

  // const casted = { ...body };
  // for (const key of Object.keys(casted)) {
  //   const value = casted[key];
  //   if (isDate(value)) {
  //     casted[key] = new Date(value);
  //   }
  //   casted[key] = convertObjectDateStringsToDate(value);
  // }
  // return casted;
}
