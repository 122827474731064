import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { Provider } from '@angular/core';
import { environment } from 'environments/environment';

import { productCdnUrl } from '../product/product.helpers';
import { ImageLoaderProviderConfig } from './image-loader-provider-config';
import { withBreakpoints } from './with-breakpoints';

export const provideProductImageLoader = (config: ImageLoaderProviderConfig = {}): Provider => {
  const { baseUrl = environment.cdn, breakpoints = [] } = config;
  return [
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) =>
        productCdnUrl(baseUrl, { ...config, width: config?.width })
    },
    withBreakpoints(breakpoints)
  ];
};
