import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Layout, layoutFeature } from './layout.reducer';

export const {
  selectShowCart: selectLayoutShowCart,
  selectShowMenu: selectLayoutShowMenu,
  selectShowSearch: selectLayoutShowSearch,
  selectSelectedNav: selectLayoutSelectedNav,
  selectCurrency: selectLayoutCurrency
} = layoutFeature;

export const selectLayoutLanguage = createSelector(
  layoutFeature.selectLanguage,
  (language) => language || 'pl'
);

export const selectLayout = createFeatureSelector<Layout>('layout');
