import { Params } from '@angular/router';
import { ApiPagination, Product } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const SearchActions = createActionGroup({
  source: 'Search/API',
  events: {
    Enter: emptyProps(),
    Leave: emptyProps(),
    load: props<{ page: number; search: string; language: string; params: Params }>(),
    Set: props<{
      products: Product[];
      pagination: ApiPagination;
    }>()
  }
});
