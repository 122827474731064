/* eslint-disable @typescript-eslint/member-ordering */
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { concatMap, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CategoryActions } from '../category';
import { selectLayoutLanguage } from '../layout';
import { ProductSeoService } from './product-seo.service';
import { ProductActions } from './product.actions';
import {
  selectProduct,
  selectProductAndLanguage,
  selectProductIdParamAndLanguage
} from './product.selectors';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root'
})
export class ProductEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly productService = inject(ProductService);
  private readonly productSeoService = inject(ProductSeoService);

  loadProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.loadProducts),
      switchMap(({ params }) => this.productService.get({ ...params })),
      map(({ results, pagination }) =>
        ProductActions.loadSuccess({
          products: results,
          pagination
        })
      )
    );
  });

  loadProductsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.loadSuccess),
      map(({ products }) =>
        ProductActions.setProducts({
          products
        })
      )
    );
  });

  loadProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.loadOne),
      switchMap(({ params }) => this.productService.getOne({ ...params })),
      map((product) => ProductActions.loadOneSuccess({ product }))
    );
  });

  loadProductSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.loadOneSuccess),
      map(({ product }) =>
        ProductActions.setOne({
          product
        })
      )
    );
  });

  loadProductFromUrl$ = createEffect(() => {
    return this.store.pipe(
      select(selectProductIdParamAndLanguage),
      filter(({ productId }) => !!productId),
      map(({ productId, language }) =>
        ProductActions.loadOne({ params: { id: +productId, language } })
      )
    );
  });

  setProductMeta$ = createEffect(
    () => {
      return this.store.pipe(
        select(selectProductAndLanguage),
        filter(({ product }) => !!product?.id),
        tap(({ product, language }) => this.productSeoService.setSeo(product, language))
      );
    },
    { dispatch: false }
  );

  loadMany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.loadMany),
      filter(({ ids }) => !!ids?.length),
      concatLatestFrom(() => this.store.select(selectLayoutLanguage)),
      switchMap(([{ ids, params }, language]) =>
        this.productService
          .getMany(ids, { ...params, language })
          .pipe(map((products) => ProductActions.loadManySuccess({ products })))
      )
    );
  });

  loadManySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.loadManySuccess),
      map(({ products }) =>
        ProductActions.setProducts({
          products
        })
      )
    );
  });

  getBreadcrumbs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.enter),
      concatMap(() =>
        this.store.pipe(select(selectProduct)).pipe(
          filter((product) => !!product?.id),
          concatLatestFrom(() => this.store.select(selectLayoutLanguage)),
          map(([product, language]) =>
            CategoryActions.loadCategory({ id: product.categoryId, language })
          ),
          takeUntil(this.actions$.pipe(ofType(ProductActions.leave)))
        )
      )
    );
  });

  // enterCreateVisit$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ProductActions.enter),
  //     concatMap(() =>
  //       this.store.select(selectProduct).pipe(
  //         filter(Boolean),
  //         distinctUntilKeyChanged('id'),
  //         map((product) => ProductActions.createVisit({ product })),
  //         takeUntil(this.actions$.pipe(ofType(ProductActions.leave)))
  //       )
  //     )
  //   );
  // });

  // createVisits$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ProductActions.createVisit),
  //     switchMap(({ product }) =>
  //       this.productService.createVisit(product).pipe(
  //         map((product: Product) => ProductActions.createVisitSuccess({ product })),
  //         catchError((error: unknown) => of(ProductActions.createVisitFailure({ error })))
  //       )
  //     )
  //   );
  // });

  // sendViewItemToGa$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ProductActions.setOne),
  //     map(({ product }) => GoogleAnalyticsActions.viewItem({ product }))
  //   );
  // });
}
