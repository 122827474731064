import { UrlSegment } from '@angular/router';
import {
  checkIsContent,
  checkIsPolitykaPrywatnosci,
  checkIsRegulamin,
  config,
  Content
} from '@manzuko/shared';

export const contentUrlMatcher = (url: UrlSegment[]) => {
  const isRegulamin = checkIsRegulamin(url);
  const isPolitykaPrywatnosci = checkIsPolitykaPrywatnosci(url);
  const isContent = checkIsContent(url);
  if (isRegulamin) {
    const contentId = new UrlSegment('3', {});
    return {
      consumed: url,
      posParams: { contentId }
    };
  }

  if (isPolitykaPrywatnosci) {
    const contentId = new UrlSegment('6', {});
    return {
      consumed: url,
      posParams: { contentId }
    };
  }

  if (isContent) {
    const contentId = new UrlSegment(url[1].path.match(/^(\d+)/gm)[0], {});
    return {
      consumed: url,
      posParams: { contentId }
    };
  }
  return null;
};

export const contentSeoUrl = (content: Content, lang?: string): string => {
  return `${config.website.url}/${lang === 'en' ? 'en/' : ''}content/${content?.id}-${content
    ?.locale?.[lang]?.slug}`;
};
