import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';

export function joinByProperty(products, property, separator) {
  return products?.map((item) => item?.[property])?.join(separator);
}

export const stripHtmlTags = (value: string): string =>
  value
    ?.replace(/(<([^>]+)>)/gi, '')
    ?.replace(/&nbsp;/gi, ' ')
    ?.replace(/\s/gi, ' ');

export const shorten = (value: string, length: number): string => {
  if (value?.length <= length) {
    return value;
  }
  const short = value?.substr(0, length);
  if (short?.length < length) {
    return short;
  }
  return short?.substr(0, Math.min(short.length, short.lastIndexOf(' ')));
};

export function createFuseConfirmationConfig(
  body: Partial<FuseConfirmationConfig>
): FuseConfirmationConfig {
  return {
    title: body?.title || 'Are you sure?',
    message: body?.message || 'Are you sure you want to do this?',
    icon: {
      show: body?.icon?.show || true,
      name: body?.icon?.name || 'heroicons_outline:exclamation',
      color: body?.icon?.color || 'warn'
    },
    actions: {
      confirm: {
        show: body?.actions?.confirm?.show || true,
        label: body?.actions?.confirm?.label || 'Accept',
        color: body?.actions?.confirm?.color || 'warn'
      },
      cancel: {
        show: body?.actions?.cancel?.show || true,
        label: body?.actions?.cancel?.label || 'Back'
      }
    },
    dismissible: body?.dismissible || true
  };
}

export function createDateTime(date: any, time: string): Date {
  if (!date) return null;

  return (
    new Date(new Date(date)?.setHours(+time?.split(':')?.[0] || 0, +time?.split(':')?.[1] || 0)) ||
    null
  );
}

export function removeFalsyPropertiesFromObject(object) {
  return Object.keys(object).reduce((acc, key) => {
    if (object[key]) {
      acc[key] = object[key];
    }
    return acc;
  }, {});
}
