import { createFeatureSelector, createSelector } from '@ngrx/store';

import { adapter, ShippingState } from './shipping.reducer';

export const selectShippingState = createFeatureSelector<ShippingState>('shipping');

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter?.getSelectors() || {};

export const selectShippingIds = createSelector(selectShippingState, selectIds);
export const selectShippingEntities = createSelector(selectShippingState, selectEntities);
export const selectShippingAll = createSelector(selectShippingState, selectAll);
export const selectShippingTotal = createSelector(selectShippingState, selectTotal);
