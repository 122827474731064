import { Discount } from '../interfaces/discount.dto';
import { Product } from '../interfaces/product.dto';

export const productPath = (
  { id, slug, categorySlug, slugPl, categorySlugPl, slugEn, categorySlugEn }: Partial<Product> = {},
  lang?: string
): string => {
  if (lang === 'en') {
    const category = categorySlugEn || categorySlug;
    return `/en/${category ? category + '/' : ''}${id || 0}-${slugEn || slug || ''}.html`;
  }
  if (slugPl) {
    return `/${categorySlugPl + '/' || ''}${id || 0}-${slugPl || ''}.html`;
  }
  if (categorySlug) {
    return `/${categorySlug + '/' || ''}${id || 0}-${slug || ''}.html`;
  }
  return `/${id || 0}-${slug || ''}.html`;
};

export const getIsAvailable = (product: Product): boolean =>
  product?.active && product?.quantity > 0 && product?.price > 0;

export const getPriceDiscounts = (discounts: Discount[]): Discount[] =>
  discounts?.filter(
    (discount) => !discount?.isPercent && (!discount?.quantity || discount?.quantity <= 1)
  );

export const getPercentageDiscounts = (discounts: Discount[]): Discount[] =>
  discounts?.filter(
    (discount) => discount?.isPercent && (!discount?.quantity || discount?.quantity <= 1)
  );

export const getQuantityDiscounts = (product: Product, discounts: Discount[]): Discount[] => {
  const filteredDiscounts = discounts?.filter((discount) => discount?.quantity > 1);
  const discountsWithPrice = filteredDiscounts
    .map((discount) => ({
      ...discount,
      price: +(
        discount?.isPercent
          ? product?.price - (product?.price * discount?.value) / 100
          : product?.price - discount?.value
      ).toFixed(2)
    }))
    .filter((discount) => discount?.price > 0);

  return discountsWithPrice;
};

export const getBestPriceDiscount = (product: Product): Discount => {
  if (!product?.priceDiscounts || product?.priceDiscounts?.length === 0) {
    return null;
  }
  const bestPriceDiscount = product?.priceDiscounts?.reduce((best, current) => {
    if (product?.price - current?.value <= 0) {
      return best;
    }
    return best?.value > current?.value ? best : current;
  }, null);
  return bestPriceDiscount;
};

export const getBestPercentageDiscount = (product: Product): Discount => {
  if (!product?.percentageDiscounts || product?.percentageDiscounts?.length === 0) {
    return null;
  }
  const bestPercentageDiscount = product?.percentageDiscounts?.reduce((best, current) => {
    if (product?.price - (product?.price * current?.value) / 100 <= 0) {
      return best;
    }
    return best?.value > current?.value ? best : current;
  }, null);
  return bestPercentageDiscount;
};

export const getPriceWithDiscount = (product: Product): number =>
  Math.min(
    product?.price,
    product?.price - product?.bestPriceDiscount?.value || product?.price,
    product?.price - (product?.price * product?.bestPercentageDiscount?.value) / 100 ||
      product?.price
  );
