<ng-container *transloco="let t">
  @if (userId$ | async; as userId) {
    <button
      class="flex flex-col items-center focus:outline-none"
      [routerLink]="loginLink$ | async"
      (click)="userId ? (menuOpen = !menuOpen) : null"
      (clickOutside)="menuOpen = false"
      type="button"
      title="Konto użytkownika">
      <svg
        alt="Konto"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="w-6 h-6">
        <path
          fill-rule="evenodd"
          d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
          clip-rule="evenodd" />
      </svg>
      <span class="hidden md:inline-block" data-test="header-user-name">
        {{ (user$ | async)?.firstName || t('shop.header.login') }}
      </span>
    </button>
  } @else {
    <button
      class="flex flex-col items-center focus:outline-none"
      [routerLink]="loginLink$ | async"
      type="button"
      title="Konto użytkownika">
      <svg
        alt="Konto"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
      </svg>
      <span class="hidden md:inline-block" data-test="header-user-name">
        {{ (user$ | async)?.firstName || t('shop.header.login') }}
      </span>
    </button>
  }

  @if (userId$ | async) {
    <div class="absolute right-0 bottom-auto text-center">
      @if (menuOpen) {
        <div
          data-test="header-user-menu"
          class="mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-md z-50 text-lg">
          <ul>
            @for (option of nav; track option.path) {
              <li class="hover:bg-gray-100">
                <a
                  [routerLink]="'/konto/' + option?.path | mzkLink | async"
                  attr.data-test="header-user-{{ option?.path }}"
                  class="block px-4 py-3 cursor-pointer"
                  (click)="menuOpen = false">
                  {{ t('shop.header.' + option?.label) }}
                </a>
              </li>
            }
            @if (isAdmin$ | async) {
              <li class="hover:bg-gray-100">
                <a
                  [routerLink]="['/admin']"
                  data-test="header-user-admin"
                  class="block px-4 py-3 cursor-pointer"
                  (click)="menuOpen = false">
                  {{ t('shop.header.admin') }}
                </a>
              </li>
            }
            <li class="hover:bg-gray-100 md:hidden">
              <a
                [routerLink]="'/konto/ulubione' | mzkLink | async"
                data-test="header-user-favorites"
                class="block px-4 py-3 cursor-pointer"
                (click)="menuOpen = false">
                {{ t('shop.header.favourite') }}
              </a>
            </li>
            <li class="hover:bg-gray-100">
              <button
                data-test="header-user-logout"
                class="block px-4 py-3 cursor-pointer w-full"
                (click)="logout()"
                type="button">
                {{ t('shop.header.logout') }}
              </button>
            </li>
          </ul>
        </div>
      }
    </div>
  }
</ng-container>
