import { DOCUMENT, isPlatformServer, Location } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { FuseLoadingService } from '@fuse/services/loading';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import {
  routerCancelAction,
  routerErrorAction,
  routerNavigatedAction,
  routerRequestAction
} from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { concatMap, delay, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { GoogleAnalyticsActions } from '../google-analytics';
import { selectLayoutProductAndCategoryPath } from '../product';
import { LayoutActions } from './layout.actions';
import { generateUrlWithLanguage } from './layout.helpers';

@Injectable({
  providedIn: 'root'
})
export class LayoutEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly document = inject(DOCUMENT);
  private readonly location = inject(Location);
  private readonly loadingService = inject(FuseLoadingService);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly router = inject(Router);

  showLoadingOnRouterRequest$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LayoutActions.enter),
        concatMap(() => {
          return this.actions$.pipe(
            ofType(routerRequestAction),
            map(() => this.loadingService.show()),
            takeUntil(this.actions$.pipe(ofType(LayoutActions.leave)))
          );
        })
      );
    },
    { dispatch: false }
  );

  hideLoadingOnRouterNavigated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LayoutActions.enter),
        concatMap(() => {
          return this.actions$.pipe(
            ofType(routerNavigatedAction, routerCancelAction, routerErrorAction),
            environment.demoThrottle ? delay(environment.demoThrottle) : tap(),
            map(() => this.loadingService.hide()),
            takeUntil(this.actions$.pipe(ofType(LayoutActions.leave)))
          );
        })
      );
    },
    { dispatch: false }
  );

  closeOnNavigation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LayoutActions.enter),
      concatMap(() => {
        // this.ngZone.run(() => {
        return this.actions$.pipe(ofType(routerRequestAction)).pipe(
          mergeMap(() => [
            LayoutActions.toggleMenu({ showMenu: false }),
            LayoutActions.toggleCart({ showCart: false }),
            LayoutActions.toggleSearch({ showSearch: false }),
            LayoutActions.selectNav({ selectedNav: null })
          ]),
          takeUntil(this.actions$.pipe(ofType(LayoutActions.leave)))
        );
      })
    );
  });

  showCart$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LayoutActions.toggleCart),
        map(({ showCart }) => {
          const bodyClass = this.document.querySelector('body').classList;
          if (showCart) {
            return bodyClass.replace('overflow-y-scroll', 'overflow-hidden');
          }
          return bodyClass.replace('overflow-hidden', 'overflow-y-scroll');
        })
      );
    },
    { dispatch: false }
  );

  showSearch$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LayoutActions.toggleSearch),
        map(({ showSearch }) => {
          const bodyClass = this.document.querySelector('body').classList;
          if (showSearch) {
            return bodyClass.replace('overflow-y-scroll', 'overflow-hidden');
          }
          return bodyClass.replace('overflow-hidden', 'overflow-y-scroll');
        })
      );
    },
    { dispatch: false }
  );

  setCurrencyFromLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LayoutActions.setLanguage),
      map(({ language }) =>
        LayoutActions.setCurrency({ currency: language === 'en' ? 'EUR' : 'PLN' })
      )
    );
  });

  sendViewCartToGa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LayoutActions.toggleCart),
      filter(({ showCart }) => showCart),
      map(() => GoogleAnalyticsActions.viewCart())
    );
  });

  redirectOnLanguageSet$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LayoutActions.setLanguage),
        concatLatestFrom(() => this.store.select(selectLayoutProductAndCategoryPath)),
        map(([{ language }, path]) => {
          if (isPlatformServer(this.platformId)) {
            return;
          }
          if (!path) {
            const currentUrl = this.location.path();
            const newUrl = generateUrlWithLanguage(currentUrl, language);
            // this.location.replaceState(newUrl);
            this.router.navigateByUrl(newUrl);
            return;
          }
          // this.location.replaceState(path);
          this.router.navigateByUrl(path);
        })
      );
    },
    { dispatch: false }
  );
}
