import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { getLangFromRouteParams } from '@manzuko/shared/utils/lang-from-params';
import { catchError, map } from 'rxjs';

import { ProductService, productWithUrls } from 'app/data-access/product';

import { LatestSeoService } from './latest-seo.service';

export const latestResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const latestSeoService = inject(LatestSeoService);
  const productsService = inject(ProductService);
  const router = inject(Router);

  const lang = getLangFromRouteParams(route?.params);
  const queryParams = route?.queryParams;
  const products = productsService.get({ language: lang, ...queryParams });

  return products.pipe(
    map((productsResults) => {
      const products = productsResults?.results.map((product) => ({
        ...productWithUrls(product, lang),
        isNew: true
      }));
      const title = lang === 'en' ? 'New products' : 'Nowe produkty';
      latestSeoService.setSeo(lang, title);

      const pagination = productsResults?.pagination;
      // const currentUrl = state.url;
      // const correctUrl = `${lang === 'en' ? '/en' : ''}/nowe-produkty`;
      // const params = new URLSearchParams(queryParams);
      // const correctUrlWithParams = `${correctUrl}${
      //   Object.keys(queryParams)?.length ? '?' + params.toString() : ''
      // }`;

      // if (currentUrl !== correctUrlWithParams) {
      //   router.navigateByUrl(correctUrlWithParams);
      // }

      return {
        products,
        pagination: {
          ...pagination,
          lastPage: 20
        }
      };
    }),
    catchError((error) => router.navigateByUrl('/not-found'))
  );
};
