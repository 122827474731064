import { ChangeDetectionStrategy, Component, HostBinding, inject, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { JsonLdDocument } from 'jsonld';

@Component({
  standalone: true,
  selector: 'mzk-json-ld',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonLdComponent {
  readonly #sanitizer = inject(DomSanitizer);

  @Input()
  set json(currentValue: JsonLdDocument) {
    this.jsonLD = this.getSafeHTML(currentValue);
  }
  @HostBinding('innerHTML') jsonLD?: SafeHtml;

  getSafeHTML(value: JsonLdDocument): SafeHtml {
    const json = value ? JSON.stringify(value, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.#sanitizer.bypassSecurityTrustHtml(html);
  }
}
