import { inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { getLangUrl } from '@manzuko/shared/utils/get-lang-url';

import { SEOService } from 'app/shared/seo.service';

@Injectable({
  providedIn: 'root'
})
export class LatestSeoService {
  private readonly seoService = inject(SEOService);
  private readonly metaService = inject(Meta);

  descriptionEn = `New products in the Manzuko store | The latest beads and jewelry making elements ✔️Express shipping ✔️Wholesale quantities ✔️We help you create your dream jewelry - check it out.`;

  descriptionPl = `Nowości w sklepie Manzuko | Najnowsze koraliki i elementy do tworzenia biżuterii ✔️Ekspresowa wysyłka ✔️Hurtowe ilości ✔️Pomagamy stworzyć Twoją wymarzoną biżuterię- sprawdź.`;

  setSeo(lang: string, title: string): void {
    const canonicalUrl = getLangUrl('nowe-produkty', lang);

    this.seoService.setPageTitle(title);
    this.metaService.updateTag({
      name: 'description',
      content: `${lang === 'en' ? this.descriptionEn : this.descriptionPl}`
    });
    this.seoService.createLinkForCanonicalURL(canonicalUrl);

    const ogTags: Record<string, string> = {
      // 'og:type': 'product',
      'og:title': title,
      'og:description': `${lang === 'en' ? this.descriptionEn : this.descriptionPl}`,
      'og:url': canonicalUrl,
      'og:image': `https://manzuko.b-cdn.net/assets/images/logo-text-white.jpg`,
      'og:locale': lang === 'en' ? 'en_US' : 'pl_PL'
    };

    this.seoService.updateOgTags(ogTags);

    this.seoService.createLinkForAlternatePl('https://manzuko.com/nowe-produkty');
    this.seoService.createLinkForAlternateEn('https://manzuko.com/en/nowe-produkty');
    this.seoService.createLinkForAlternateDefault('https://manzuko.com/nowe-produkty');
  }
}
