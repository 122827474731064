import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoDirective } from '@jsverse/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';
import { delay, startWith, tap } from 'rxjs';

import { CartActions, selectCartProductsQuantity } from 'app/data-access/cart';
import { LayoutActions } from 'app/data-access/layout';

@Component({
  standalone: true,
  selector: 'mzk-cart-icon',
  templateUrl: './cart-icon.component.html',
  imports: [AsyncPipe, NgClass, TranslocoDirective, FastSvgComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartIconComponent {
  readonly #store = inject(Store);
  readonly #actions$ = inject(Actions);

  // @Input() isOpen = false;
  // @Input() cart?: Cart;
  // @Output() readonly closed: EventEmitter<void> = new EventEmitter();

  protected readonly cartCount = toSignal(
    this.#store.select(selectCartProductsQuantity).pipe(startWith(0))
  );

  protected readonly adding = signal(false);

  protected readonly added$ = this.#actions$.pipe(
    startWith(false),
    ofType(CartActions.addProductSuccess, CartActions.updateProductQuantitySuccess),
    tap(() => this.adding.set(true)),
    delay(500),
    tap(() => this.adding.set(false))
  );

  showCart(): void {
    this.#store.dispatch(LayoutActions.toggleCart({ showCart: true }));
  }

  // ngOnInit(): void {
  //   // this.#store.dispatch(CartActions.refresh());
  //   // this.#store.dispatch(CartActions.loadCartProducts());
  // }
}
