import { Cart, CartItem } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CartActions = createActionGroup({
  source: 'Cart/API',
  events: {
    Get: emptyProps(),
    'Get Success': props<{ cart: Cart }>(),
    'Get Failure': props<{ error: any }>(),

    Set: props<{ cart: Cart }>(),
    'Set From Local Storage': props<{ cart: Cart }>(),
    Clear: emptyProps(),

    Load: emptyProps(),
    'Load Success': props<{ cart: Cart }>(),
    'Load Failure': props<{ error: any }>(),

    'Add Product': props<{ productId: CartItem['productId'] }>(),
    'Add Product Success': props<{ cart: Cart }>(),
    'Add Product Failure': props<{ error: any }>(),

    'Update Product Quantity': props<{
      productId: CartItem['productId'];
      quantityChange: number;
      nameOnVoucher?: string;
    }>(),
    'Update Product Quantity Success': props<{ cart: Cart }>(),
    'Update Product Quantity Failure': props<{ error: any }>(),

    'Remove Product': props<{ productId: number }>(),
    'Remove Product Success': props<{ cart: Cart }>(),
    'Remove Product Failure': props<{ error: any }>(),

    'Remove Cart': emptyProps(),
    'Remove Cart Success': props<{ cart: Cart }>(),
    'Remove Cart Failure': props<{ error: any }>(),

    'Load Voucher': props<{ code: string }>(),
    'Load Voucher Success': props<{ cart: Cart }>(),
    'Load Voucher Failure': props<{ error: any }>(),

    'Remove Voucher': emptyProps(),
    'Remove Voucher Success': props<{ cart: Cart }>(),
    'Remove Voucher Failure': props<{ error: any }>(),

    'Set Shipping': props<{ shippingId: number }>(),
    'Set Shipping Success': props<{ cart: Cart }>(),
    'Set Shipping Failure': props<{ error: any }>(),

    Refresh: props<{ cart: Cart; language: string; currency: string }>(),
    'Refresh Success': props<{ cart: Cart }>(),
    'Refresh Failure': props<{ error: any }>(),

    'Load Cart Products': emptyProps(),

    'Merge Carts': props<{ userId: number }>(),
    'Merge Carts Success': props<{ cart: Cart }>(),
    'Merge Carts Failure': props<{ error: any }>(),

    Update: props<{ id: number; cart: any }>(),
    'Update Success': props<{ cart: Cart }>(),
    'Update Failure': props<{ error: any }>(),

    'Set Payment': props<{ payment: string }>(),
    'Set Payment Success': props<{ cart: Cart }>(),
    'Set Payment Failure': props<{ error: any }>(),

    'Clear Voucher Error': emptyProps(),
    'Clear Voucher Success': emptyProps()
  }
});
