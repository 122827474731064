import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { selectAuthUser, selectAuthUserIsAdminOrEmployee } from './auth.selectors';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  private readonly store = inject(Store);
  private readonly router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/admin/' : state.url;
    return this.check(redirectUrl);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> | UrlTree {
    return this.store.select(selectAuthUserIsAdminOrEmployee).pipe(
      map((isAdmin) => {
        if (isAdmin) {
          return true;
        }
        return this.router.createUrlTree(['']);
      })
    );
  }

  // canActivateChild(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): boolean | Observable<boolean | UrlTree> | UrlTree {
  //   return this.store.select(selectAuthUserIsAdminOrEmployee).pipe(
  //     map((roles) => {
  //       if (roles === 'admin' || roles === 'employee') {
  //         return true;
  //       }
  //       return this.router.createUrlTree(['']);
  //     })
  //   );
  // }

  canLoad(): boolean | Observable<boolean | UrlTree> | UrlTree {
    return this.check('/admin/');
  }

  private check(redirectURL: string): boolean | Observable<boolean | UrlTree> | UrlTree {
    return this.store.pipe(
      select(selectAuthUser),
      map((user) => {
        if (user) {
          return true;
        }
        return this.router.createUrlTree(['/admin/sign-in'], {
          queryParams: { redirectURL }
        });
      })
    );
  }
}
