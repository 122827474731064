import { inject, Injectable } from '@angular/core';
import { Category } from '@manzuko/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, takeUntil } from 'rxjs';

import { CategoryActions, CategoryService } from 'app/data-access/category';
import { selectLayoutLanguage } from 'app/data-access/layout';

import { SitemapActions } from './sitemap.actions';

@Injectable({
  providedIn: 'root'
})
export class SitemapEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly categoryService = inject(CategoryService);

  loadSitemapOnLanguageChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SitemapActions.enter),
      concatMap(() => {
        return this.store.select(selectLayoutLanguage).pipe(
          map((language) => SitemapActions.load({ language })),
          takeUntil(this.actions$.pipe(ofType(SitemapActions.leave)))
        );
      })
    );
  });

  setSitemap$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SitemapActions.load),
      switchMap(({ language }) =>
        this.categoryService.getTree({
          language
        })
      ),
      map((categories: Category[]) =>
        CategoryActions.setCategories({
          categories
        })
      )
    );
  });
}
