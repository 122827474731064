export const environment = {
  production: true,
  demo: false,
  demoThrottle: 0,
  baseUrl: 'https://manzuko.com',
  url: 'https://manzuko.com',
  api: 'https://manzuko.com/api',
  cdn: 'https://manzuko.b-cdn.net',
  // cdn: 'https://manzuko-1350d.kxcdn.com',
  recaptchaSiteKey: '6LcbXo0UAAAAAHOaUPZNhryanKLatJL5gv7d9V4X',
  recaptchaEnabled: true,
  includeAdmin: false,
  firebase: {
    apiKey: 'AIzaSyB1DDJfSTxI-S9RXqeZfuYt-Z1LB9jhg2A',
    authDomain: 'manzuko-1145.firebaseapp.com',
    projectId: 'manzuko-1145',
    storageBucket: 'manzuko-1145.appspot.com',
    messagingSenderId: '401812126015',
    appId: '1:401812126015:web:58825c8457145a15563b8a',
    measurementId: 'G-ZXQVNRWD7Q'
  },
  pocztaGeowidgetOptions: {
    basemapProvider: 'OpenStreetMap',
    geocoderProvider: 'Nominatim',
    basemapOptions: 'Y8cfRlIZd8DaLMhpaqVBYTebU4ssnONX2znO5QsZ'
  },
  googleAnalyticsId: 'G-ZXQVNRWD7Q',
  isrRevalidate: 60 * 60 * 24, // 24h
  orderStatuses: [
    'Opłacone',
    'Wysłane',
    'Anulowane',
    'Zwrócone',
    'Oczekiwanie płatności czekiem',
    'W przygotowaniu',
    'Błąd płatności',
    'Brak towaru',
    'Oczekiwanie na płatność przelewem',
    'Oczekiwanie na płatność paypal',
    'Płatnośc przy odbiorze otrzymana',
    'W przygotowaniu',
    'Oczekiwania na dodatkowe informacje',
    'Oczekiwanie na zaakceptowanie płatności',
    'Zwrot Zaakceptowany',
    'Oczekiwanie na płatność Przelewy24',
    'Płatność Przelewy24 przyjęta',
    'Przelewy24 zmienione'
  ]
};
