import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiSecureRoutes, LoginForm, LoginResponse, SocialLogin, User } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly http = inject(HttpClient);

  hasCredentials = true;
  savedToken = 'test';

  get(): Observable<User> {
    return this.http.get<User>(
      `${environment.api}/catalog/auth/profile?nocache=${new Date()?.getTime()}`
    );
  }

  create(user: User) {
    return this.http.post<User>(`${environment.api}/catalog/auth/register`, user);
  }

  update(user: Partial<User>) {
    return this.http.put<User>(`${environment.api}/catalog/auth/profile`, user);
  }

  changePassword(password: string, currentPassword: string) {
    return this.http.put<User>(`${environment.api}/catalog/auth/changePassword`, {
      password,
      currentPassword
    });
  }

  delete() {
    return this.http.delete(`${environment.api}/catalog/auth/profile`);
  }

  login(credentials: LoginForm): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${environment.api}/catalog/auth/login`, credentials);
  }

  loginWithSocial(credentials: SocialLogin): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `${environment.api}/catalog/auth/login/social`,
      credentials
    );
  }

  reset(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.api}/catalog/auth/reset`, { email });
  }

  verifyToken(resetPasswordToken: string) {
    return this.http.post<any>(`${environment.api}/catalog/auth/reset-verify`, {
      resetPasswordToken
    });
  }

  resetConfirm(password: string, resetPasswordToken: string) {
    return this.http.post<any>(`${environment.api}/catalog/auth/reset-confirm`, {
      password,
      resetPasswordToken
    });
  }

  getSecureRoutes(): Observable<ApiSecureRoutes> {
    return this.http.get<ApiSecureRoutes>(`${environment.api}/catalog/config/secure-routes`);
  }

  verifyJWT(): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.api}/catalog/auth/verify?nocache=${new Date()?.getTime()}`
    );
  }
}
