import { Cart, Order, Product } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const GoogleAnalyticsActions = createActionGroup({
  source: 'GoogleAnalitics',
  events: {
    Purchase: props<{ order: Order }>(),
    'View Cart': emptyProps(),
    'View Item': props<{ product: Product }>(),
    'View Item List': props<{ products: Product[] }>(),
    Register: emptyProps(),
    Login: props<{ method?: string }>(),
    'Add To Cart': props<{ product: Product; quantity: number }>(),
    'Remove From Cart': props<{ product: Product; quantity: number }>(),
    'Begin Checkout': emptyProps(),
    'Add Payment Info': props<{ cart: Cart }>(),
    'Add Shipping Info': props<{ cart: Cart }>(),
    'Add To Wishlist': props<{ product: Product }>(),
    Search: props<{ search_term: string }>(),
    'Subscribe Newsletter': props<{ email: string }>()
  }
});
