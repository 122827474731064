import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Category } from '@manzuko/shared';
import { environment } from 'environments/environment';

import { productCdnUrl } from 'app/data-access/product/product.helpers';

@Component({
  standalone: true,
  selector: 'mzk-category-thumb',
  templateUrl: './category-thumb.component.html',
  styleUrls: ['./category-thumb.component.scss'],
  imports: [RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryThumbComponent {
  category = input<Partial<Category>>();

  imageUrl = computed(
    () =>
      this.category()?.id &&
      productCdnUrl(environment?.cdn, { src: `${this.category()?.productImageId}`, width: 100 })
  );
}
