import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Cart } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { removeFalsyPropertiesFromObject } from 'app/utils';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private readonly http = inject(HttpClient);

  get(cart: Cart, userId: number, language: string, currency: string): Observable<Cart> {
    const params = { cartId: cart?.id, userId, language, currency };
    const paramsWithNoFalsyValues = removeFalsyPropertiesFromObject(params);

    return this.http.get<Cart>(`${environment.api}/catalog/cart?nocache=${new Date()?.getTime()}`, {
      params: paramsWithNoFalsyValues as unknown as HttpParams
    });
  }

  addToCart(params: {
    productId: number;
    cart: Cart;
    nameOnVoucher?: string;
    language: string;
    currency: string;
    quantityChange: number;
  }): Observable<Cart> {
    return this.http.post<Cart>(`${environment.api}/catalog/cart/add`, {
      ...params
    });
  }

  subtractFromCart(params: {
    productId: number;
    cart: Cart;
    nameOnVoucher?: string;
    language: string;
    currency: string;
    quantityChange: number;
  }): Observable<Cart> {
    return this.http.post<Cart>(`${environment.api}/catalog/cart/subtract`, {
      ...params
    });
  }

  refreshCart(params: { cart: Cart; language: string; currency: string }): Observable<Cart> {
    return this.http.post<Cart>(`${environment.api}/catalog/cart/refresh`, {
      ...params
    });
  }

  mergeCarts(params: {
    userId: number;
    cart: Cart;
    language: string;
    currency: string;
  }): Observable<Cart> {
    return this.http.post<Cart>(`${environment.api}/catalog/cart/merge`, {
      ...params
    });
  }

  removeCart(params: { cart: Cart }): Observable<Cart> {
    return this.http.delete<Cart>(`${environment.api}/catalog/cart/remove/${params.cart.id}`);
  }

  removeProductFromCart(productId: number, cart: Cart): Observable<Cart> {
    return this.http.post<Cart>(`${environment.api}/catalog/cart/remove/product`, {
      productId,
      cart
    });
  }

  loadVoucher(params: {
    cart: Cart;
    code: string;
    language: string;
    currency: string;
  }): Observable<Cart> {
    return this.http.post<Cart>(`${environment.api}/catalog/cart/voucher`, {
      ...params
    });
  }

  setShipping({ cart, shippingId, language, currency }): Observable<Cart> {
    return this.http.post<Cart>(`${environment.api}/catalog/cart/shipping`, {
      cart,
      shippingId,
      language,
      currency
    });
  }

  setPayment({ cart, payment, language, currency }): Observable<Cart> {
    return this.http.post<Cart>(`${environment.api}/catalog/cart/payment`, {
      cart,
      payment,
      language,
      currency
    });
  }

  update(id: number, cart: Partial<Cart>): Observable<Cart> {
    return this.http.patch<Cart>(`${environment.api}/catalog/cart`, { id, cart });
  }

  removeCartVoucher({
    cart,
    language,
    currency
  }: {
    cart: Cart;
    language: string;
    currency: string;
  }): Observable<Cart> {
    return this.http.patch<Cart>(`${environment.api}/catalog/cart/remove-voucher`, {
      cart,
      language,
      currency
    });
  }

  removeNotOrdered(): Observable<any> {
    return this.http.delete(`${environment.api}/catalog/cart`);
  }
}
