@if (control) {
  <div
    class="relative form-control"
    [ngClass]="{
      'form-control-success': control?.value && control?.valid,
      'form-control-success-async': control?.asyncValidator && control?.value && control?.valid,
      'form-control-error': (control?.value || control?.touched) && control?.invalid,
      'form-control-error-async':
        control?.asyncValidator && (control?.value || control?.touched) && control?.invalid,
      'form-control-pending': control?.pending,
      'form-control-readonly': isReadonly,
      'form-control-required': isRequired || control?.hasValidator(requiredValidator)
    }">
    <div class="flex">
      <ng-content />

      <!-- @if (inputTemplate) {
        <ng-container *ngTemplateOutlet="inputTemplate; context: { $implicit: control }" />
      } @else { -->
      @if (type === 'number') {
        <input
          disableMouseScroll
          [attr.aria-label]="label"
          [formControl]="control"
          [id]="name"
          class="peer"
          placeholder=" "
          [readonly]="isReadonly"
          [type]="type"
          [autocomplete]="autocomplete" />
      } @else {
        <input
          [formControl]="control"
          [id]="name"
          [attr.aria-label]="label"
          class="peer"
          placeholder=" "
          [readonly]="isReadonly"
          [type]="type"
          [autocomplete]="autocomplete" />
      }
      <!-- } -->
    </div>
    <label [for]="name" class="form-label-float">
      {{ label }}
      @if (isRequired || control?.hasValidator(requiredValidator)) {
        <span>*</span>
      }
    </label>
    <div class="min-h-6 mb-1">
      @if (control?.touched && control?.invalid) {
        <small>
          @if (errors) {
            @for (controlError of control?.errors | keyvalue; track controlError) {
              <span class="error-{{ controlError?.key }}">
                @if (errors?.[controlError?.key]; as displayError) {
                  <span [innerHTML]="displayError"></span>
                } @else {
                  {{ error }}
                }
              </span>
            }
          } @else {
            {{ error }}
          }

          @if (hint) {
            <p class="mb-4 leading-tight text-gray-500 dark:text-gray-400" [innerHTML]="hint"></p>
          }
        </small>
      }
    </div>
  </div>
}
