import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { P24Error, Transaction } from '@ingameltd/node-przelewy24';
import { Order } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  private readonly http = inject(HttpClient);

  createOrder(order): Observable<Order> {
    return this.http.post<Order>(`${environment.api}/checkout`, order);
  }

  registerP24Transaction(orderId: number): Observable<Transaction | P24Error> {
    return this.http.get<Transaction | P24Error>(
      `${environment.api}/checkout/register-transaction/${orderId}`
    );
  }
}
