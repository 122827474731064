import { inject, Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { tap } from 'rxjs';

@Injectable()
export class LogUpdateService {
  readonly updates$ = inject(SwUpdate)?.versionUpdates?.pipe(
    tap((event: VersionEvent) => {
      switch (event?.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${event.version?.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${event?.currentVersion?.hash}`);
          console.log(`New app version ready for use: ${event?.latestVersion?.hash}`);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${event?.version?.hash}': ${event?.error}`);
          break;
      }
    })
  );
}
