import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Group, GroupParams, GroupResults } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  private readonly http = inject(HttpClient);

  list(params?: GroupParams): Observable<GroupResults> {
    return this.http.get<GroupResults>(`${environment.api}/catalog/groups`, {
      params: params as HttpParams
    });
  }

  get(params: GroupParams): Observable<Group> {
    return this.http.get<Group>(`${environment.api}/catalog/groups/${params.id}`, {
      params: params as unknown as HttpParams
    });
  }
}
