import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { getLangFromRouteParams } from '@manzuko/shared/utils/lang-from-params';
import { tap } from 'rxjs';

import { ContentSeoService } from './content-seo.service';
import { ContentService } from './content.service';

export const contentResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const contentService = inject(ContentService);
  const contentSeoService = inject(ContentSeoService);
  // const router = inject(Router);
  const id = route.paramMap.get('contentId');
  const lang = getLangFromRouteParams(route?.params);

  return contentService.getOne({ id: +id }).pipe(
    tap((content) => {
      contentSeoService.setSeo(lang, content);
      // const currentUrl = state.url;
      // const correctUrl = `${lang === 'en' ? '/en' : ''}/content/${id}-${content?.locale?.[lang]
      //   ?.slug}`;
      // if (currentUrl !== correctUrl) {
      //   router.navigateByUrl(correctUrl);
      // }
    })
  );
};
