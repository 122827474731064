import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import {
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig
} from '@angular/router';
import { FUSE_APP_CONFIG } from '@fuse/services/config/config.constants';
import { provideTransloco, Translation, TranslocoService } from '@jsverse/transloco';
import { RX_RENDER_STRATEGIES_CONFIG } from '@rx-angular/cdk/render-strategies';
import { environment } from 'environments/environment';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS } from 'ng-recaptcha';

import { appConfig } from './admin/core/config/app.config';
import { TranslocoHttpLoader } from './admin/core/transloco/transloco.http-loader';
import { Error500Handler } from './app.error-handler';
import { appRoutes } from './app.routes';
import { APP_STORE_PROVIDERS } from './app.store';
import { AuthInterceptor } from './data-access/auth/auth.interceptor';
import { ErrorCatchingInterceptor } from './data-access/auth/error.interceptor';
import { DateHttpInterceptor } from './shared/date-http-interceptor';

export const APP_PROVIDERS = [
  provideRouter(
    appRoutes,
    // withDebugTracing(),
    withComponentInputBinding(),
    withEnabledBlockingInitialNavigation(),
    withInMemoryScrolling({
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    }),
    withRouterConfig({
      paramsInheritanceStrategy: 'always'
    })
    /**
     * **🚀 Perf Tip for TBT:**
     *
     * Disable initial sync navigation in router config and schedule it in router-outlet container component
     */
    // withDisabledInitialNavigation()
    // withInMemoryScrolling({
    //   /**
    //    * **💡 UX Tip for InfiniteScroll:**
    //    *
    //    * Reset scroll position to top on route change, users could be
    //    * irritated starting a new list from the bottom of the page.
    //    *
    //    * also: otherwise infinite scroll isn't working properly
    //    */
    //   scrollPositionRestoration: 'top'
    // })
  ),

  provideClientHydration(),

  /**
   * **🚀 Perf Tip for LCP, TTI:**
   *
   * Fetch data visible in viewport on app bootstrap instead of component initialization.
   */
  // GLOBAL_STATE_APP_INITIALIZER_PROVIDER,
  /**
   * **🚀 Perf Tip for TBT:**
   *
   * Chunk app bootstrap over APP_INITIALIZER.
   */
  // SCHEDULED_APP_INITIALIZER_PROVIDER,
  // {
  //   provide: APP_INITIALIZER,
  //   useFactory: () => (): Promise<void> =>
  //     new Promise<void>((resolve) => {
  //       setTimeout(() => resolve());
  //     }),
  //   deps: [],
  //   multi: true
  // },
  /**
   * **🚀 Perf Tip for TBT, LCP, CLS:**
   *
   * Configure RxAngular to get maximum performance.
   */
  // RXA_PROVIDER
  {
    provide: RX_RENDER_STRATEGIES_CONFIG,
    useValue: {
      parent: false
      // primaryStrategy: 'global',
      // patchZone: false
    }
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorCatchingInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: DateHttpInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  APP_STORE_PROVIDERS,

  {
    provide: FUSE_APP_CONFIG,
    useValue: appConfig
  },
  { provide: LOCALE_ID, useValue: 'pl' },
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: environment.recaptchaSiteKey }
  },
  {
    provide: RECAPTCHA_LANGUAGE,
    useFactory: (locale: string): string => locale,
    deps: [LOCALE_ID]
  },
  {
    provide: ErrorHandler,
    useClass: Error500Handler
  },

  provideTransloco({
    config: {
      availableLangs: [
        {
          id: 'pl',
          label: 'Polish'
        },
        {
          id: 'en',
          label: 'English'
        }
        // {
        //   id: 'de',
        //   label: 'German'
        // }
      ],
      defaultLang: 'pl',
      fallbackLang: 'pl',
      reRenderOnLangChange: true,
      prodMode: environment.production
    },
    loader: TranslocoHttpLoader
  }),

  {
    // Preload the default language before the app starts to prevent empty/jumping content
    provide: APP_INITIALIZER,
    deps: [TranslocoService],
    useFactory:
      (translocoService: TranslocoService): any =>
      (): Promise<Translation> => {
        const defaultLang = translocoService.getDefaultLang();
        translocoService.setActiveLang(defaultLang);
        return translocoService.load(defaultLang).toPromise();

        // return import(`../assets/i18n/${lang}.json`).then(res => res.default);

        // import en from './i18n/en.json';
        // this.translocoService.setTranslation(en, 'en');
      },
    multi: true
  }

  // { provide: APP_ID, useValue: 'manzuko' }
];
