import { createSelector } from '@ngrx/store';

import { selectQueryParam } from '../router';
import { addressFeature } from './address.reducer';

export const {
  selectAddressState,
  selectEntities: selectAddressEntities,
  selectAll: selectAddressAll
} = addressFeature;

export const selectAddressById = createSelector(
  selectAddressEntities,
  selectQueryParam('addressId'),
  (entities, id: string) => entities?.[id]
);
