import { CookieSettings } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LayoutActions = createActionGroup({
  source: 'Layout',
  events: {
    Enter: emptyProps(),
    Leave: emptyProps(),
    'Toggle Cart': props<{ showCart: boolean }>(),
    'Toggle Menu': props<{ showMenu?: boolean }>(),
    'Toggle Search': props<{ showSearch?: boolean }>(),
    'Set Language': props<{ language: string }>(),
    'Set Currency': props<{ currency: string }>(),
    'Accept Cookies': props<{ cookieSettings: CookieSettings }>(),
    'Select Nav': props<{ selectedNav: number }>()
  }
});
