import { inject, Injectable } from '@angular/core';

import { SEOService } from 'app/shared/seo.service';
import { shorten } from 'app/utils';

import { contactSeoUrl } from './contact.helpers';

@Injectable({
  providedIn: 'root'
})
export class ContactSeoService {
  private readonly seoService = inject(SEOService);

  setSeo(lang: string, meta: { title: string; description: string; keywords: string }): void {
    this.seoService.setPageTitle(meta?.title);

    const description = shorten(meta?.description, 160);
    const metaTags: Record<string, string> = {
      description,
      keywords: meta?.keywords
    };
    this.seoService.updateMetaTags(metaTags);

    const url = contactSeoUrl(lang);
    const locale = lang === 'pl' ? 'pl_PL' : 'en_US';
    const ogTags: Record<string, string> = {
      'og:url': url,
      'og:locale': locale,
      'og:title': meta?.title,
      'og:description': meta?.description
    };

    const alternatePl = contactSeoUrl('pl');
    this.seoService.createLinkForAlternateDefault(alternatePl);
    this.seoService.updateOgTags(ogTags);
    this.seoService.createLinkForCanonicalURL(url);
    this.seoService.createLinkForAlternatePl(alternatePl);
    this.seoService.createLinkForAlternateEn(contactSeoUrl('en'));
  }
}
