import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { getLangFromRouteParams } from '@manzuko/shared/utils/lang-from-params';
import { tap } from 'rxjs';

import { ContactSeoService } from './contact-seo.service';

export const contactResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const translocoService = inject(TranslocoService);
  const contactSeoService = inject(ContactSeoService);
  const lang = getLangFromRouteParams(route?.params);

  return translocoService.selectTranslateObject('shop.contactForm.meta', {}, lang).pipe(
    tap((meta) => {
      contactSeoService.setSeo(lang, meta);
    })
  );
};
