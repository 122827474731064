import { Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Pipe({
  standalone: true,
  name: 'mzkFormControl'
})
export class FormControlPipe implements PipeTransform {
  public transform(value: FormControl | FormGroup, name: string): FormControl {
    return (value?.get(name) as FormControl) ?? new FormControl();
  }
}
