import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { Product } from '@manzuko/shared';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { provideProductImageLoader } from 'app/data-access/image-loaders/product-image-loader';
import { selectLayoutLanguage } from 'app/data-access/layout';

import { RouterLinkPipe } from '../pipes/router-link.pipe';

type ImageSize = 'cart' | 'home' | 'large';

@Component({
  standalone: true,
  selector: 'mzk-product-thumb',
  templateUrl: './product-thumb.component.html',
  imports: [RouterLink, NgOptimizedImage, CommonModule, RouterLinkPipe],
  providers: [
    provideProductImageLoader({
      breakpoints: [
        120, 130, 140, 150, 160, 170, 180, 190, 200, 220, 232, 240, 260, 280, 300, 320, 340, 360,
        380
      ]
    })
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductThumbComponent implements OnChanges {
  private readonly store = inject(Store);

  @Input() product: Partial<Product>;
  @Input() size: ImageSize;
  @Input() index: number;
  @Input() quantity: number;
  @Input() isGift: boolean;

  protected imageUrl = '';
  protected priority = null;
  protected active = false;

  protected readonly lang$ = this.store.select(selectLayoutLanguage);
  protected readonly loading$ = new BehaviorSubject<boolean>(true);

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.product) {
      return;
    }
    if (changes.product || changes.size) {
      // this.imageUrl = `${this.product?.imageId}/${this.product?.id}.jpg`;
      this.imageUrl = `${this.product?.imageId}`;
      //! TODO: use always PL slug for thumb instead of ID
      // this.imageUrl = `${this.product.imageId}/${this.product.slug.pl}.jpg`;
    }
    if (changes.index && this.priority === null) {
      this.priority = this.index < 5;
    }
  }
}
