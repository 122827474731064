import { NgModule } from '@angular/core';

import { APP_BROWSER_IMPORTS } from './app.browser.imports';
import { APP_BROWSER_PROVIDER } from './app.browser.provider';
import { APP_COMPONENT_IMPORTS, AppComponent } from './app.component';
import { APP_IMPORTS } from './app.imports';
import { APP_PROVIDERS } from './app.provider';

@NgModule({
  declarations: [AppComponent],
  imports: [APP_BROWSER_IMPORTS, APP_IMPORTS, APP_COMPONENT_IMPORTS],
  providers: [...APP_PROVIDERS, ...APP_BROWSER_PROVIDER],
  bootstrap: [AppComponent]
})
export class AppModule {}
