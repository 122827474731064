import { createFeature, createReducer, on } from '@ngrx/store';

import { AuthState } from './auth-state';
import { AuthActions } from './auth.actions';

export const initialState: AuthState = {
  token: '',
  user: null,
  secureRoutes: {},
  errors: '',
  success: null
};

export const authFeature = createFeature({
  name: 'auth',
  reducer: createReducer(
    initialState,

    on(AuthActions.set, (state, { auth }): AuthState => ({ ...state, ...auth })),

    on(
      AuthActions.setSecureRoutes,
      (state, { secureRoutes }): AuthState => ({
        ...state,
        secureRoutes
      })
    ),

    on(
      AuthActions.logout,
      (state): AuthState => ({
        ...state,
        token: '',
        user: null
      })
    ),

    on(
      AuthActions.register,
      (state): AuthState => ({
        ...state,
        errors: null
      })
    ),

    on(
      AuthActions.registerSuccess,
      (state): AuthState => ({
        ...state,
        success: true
      })
    ),

    on(
      AuthActions.registerFailure,
      (state, { errors }): AuthState => ({
        ...state,
        errors: errors
      })
    ),

    on(
      AuthActions.clearErrors,
      (state): AuthState => ({
        ...state,
        errors: null,
        success: null
      })
    ),
    on(
      AuthActions.loginFailure,
      (state, { error }): AuthState => ({
        ...state,
        errors: error
      })
    ),
    on(
      AuthActions.changePasswordFailure,
      (state, { error }): AuthState => ({
        ...state,
        errors: error
      })
    ),
    on(
      AuthActions.changePasswordSuccess,
      (state): AuthState => ({
        ...state,
        success: 'passwordChanged'
      })
    ),
    on(
      AuthActions.updateUserSuccess,
      (state, { user }): AuthState => ({
        ...state,
        user: {
          ...state.user,
          ...user
        }
      })
    )
  )
});
