import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { config, Order } from '@manzuko/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, concatMap, filter, map, of, switchMap, take, tap } from 'rxjs';

import { selectCart } from 'app/data-access/cart/cart.selectors';

import { GoogleAnalyticsActions } from '../google-analytics';
import { selectLayoutLanguage } from '../layout';
import { SalesmanagoService } from '../salesmanago/salesmanago.service';
import { CheckoutActions } from './checkout.actions';
import { CheckoutService } from './checkout.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutEffects {
  private readonly actions$ = inject(Actions);
  private readonly checkoutService = inject(CheckoutService);
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly salesManagoService = inject(SalesmanagoService);

  submitCheckoutForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CheckoutActions.submit),
      concatLatestFrom(() => [this.store.select(selectCart)]),
      map(([{ address, clientGa, sessionGa }, cart]) => {
        // const products = cart?.products?.map((product) => cart.products[product.id]);

        const { shipping, payment, courierInfo, ...addressRest } = address.address;

        const state =
          payment === 'online'
            ? config.orderStatus.P24_WAITING
            : payment === 'transfer'
              ? config.orderStatus.WAITING_FOR_BANK_TRANSFER
              : config.orderStatus.WAITING_FOR_PREPARING;

        const { _id, ...cartRest } = cart;
        const order = {
          ...cartRest,
          // products,
          address: addressRest,
          shippingAddress: address?.shippingAddress,
          shipping,
          clientGa,
          sessionGa,
          courierInfo,
          payment,
          state,
          statusHistory: [
            {
              status: state,
              date: new Date()
            }
          ],
          paymentState: [{ status: state, date: new Date() }],
          shippingState: []
        } as unknown as Order;
        return CheckoutActions.createOrder({ order: { ...order, cartId: cartRest.id } });
      })
    );
  });

  sentPurchaseToGa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CheckoutActions.createOrderSuccess),
      map(({ order }) => GoogleAnalyticsActions.purchase({ order }))
    );
  });

  sentPurchaseToSalesManago$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CheckoutActions.createOrderSuccess),
        switchMap(({ order }) => this.salesManagoService.order(order))
      );
    },
    { dispatch: false }
  );

  createOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CheckoutActions.createOrder),
      switchMap(({ order }) => {
        return this.checkoutService.createOrder({ ...order }).pipe(
          map((order) => CheckoutActions.createOrderSuccess({ order })),
          catchError((error) => of(CheckoutActions.createOrderFailure({ error: error.message })))
        );
      })
    );
  });

  redirectOnCreateOrderSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CheckoutActions.createOrderSuccess),
        concatMap(({ order }) =>
          this.store.select(selectLayoutLanguage).pipe(
            take(1),
            tap((language) => {
              const baseRoute = language === 'en' ? '/en' : '';
              this.router
                .navigate([`${baseRoute}/koszyk/zamowienie/potwierdzenie/${order.id}`])
                .then(() => {
                  // TODO: tmp hack to show trustedshops popup
                  // window.location.reload();
                });
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  clearErrorOnCreateOrderSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CheckoutActions.createOrderSuccess),
      map(() => CheckoutActions.clearError())
    );
  });

  registerP24Transaction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CheckoutActions.payOnline),
      filter(({ order }) => order?.payment === 'online'),
      switchMap(({ order }) => {
        return this.checkoutService.registerP24Transaction(order.id).pipe(
          map((transaction) => CheckoutActions.registerP24TransactionSuccess({ transaction })),
          catchError((error) => of(CheckoutActions.registerP24TransactionFailure({ error })))
        );
      })
    );
  });

  redirectToPrzelewy24$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CheckoutActions.registerP24TransactionSuccess),
        tap(({ transaction }) => {
          const { link, token } = transaction;
          window.location.href = link;
        })
      );
    },
    { dispatch: false }
  );

  // navigateOnCreateOrderSuccess$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(CheckoutActions.createOrderSuccess),
  //       tap(({ order }) => this.router.navigate([`koszyk/zamowienie/potwierdzenie/${order.id}`]))
  //     );
  //   },
  //   { dispatch: false }
  // );
}
