import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Content, ContentParams, ContentResults } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { SEOService } from 'app/shared/seo.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private readonly http = inject(HttpClient);
  private readonly seoService = inject(SEOService);

  get$: Observable<ContentResults> = this.http.get<ContentResults>(
    `${environment.api}/catalog/contents`
  );

  getOne({ id, ...params }: ContentParams): Observable<Content> {
    return this.http.get<Content>(`${environment.api}/catalog/contents/${id}`, {
      params: params as HttpParams
    });
  }
}
