import { ApiPagination } from '@manzuko/shared';
import { createFeature, createReducer, on } from '@ngrx/store';

import { CheckoutActions } from './checkout.actions';

export interface CheckoutState {
  productsIds: number[];
  pagination: ApiPagination;
  orderError: string;
}

export const initialState: CheckoutState = {
  productsIds: [],
  pagination: {},
  orderError: ''
};

export const checkoutFeature = createFeature({
  name: 'checkout',
  reducer: createReducer(
    initialState,

    on(
      CheckoutActions.createOrderFailure,
      (state, { error }): CheckoutState => ({
        ...state,
        orderError: error
      })
    ),

    on(
      CheckoutActions.clearError,
      (state): CheckoutState => ({
        ...state,
        orderError: ''
      })
    )
  )
});
