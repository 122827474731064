import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { ToastAdminActions } from '../toast';
import { SettingAdminActions } from './setting.actions';
import { SettingAdminService } from './setting.service';

@Injectable({
  providedIn: 'root'
})
export class SettingAdminEffects {
  private readonly actions$ = inject(Actions);
  private readonly settingsService = inject(SettingAdminService);

  enterList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingAdminActions.enterList),
      map(() => SettingAdminActions.loadList())
    );
  });

  loadSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingAdminActions.loadList),
      switchMap(() =>
        this.settingsService.getAll().pipe(
          map((settings) => SettingAdminActions.loadListSuccess({ settings })),
          catchError((error: any) => of(SettingAdminActions.loadListFailure({ error })))
        )
      )
    );
  });

  setSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingAdminActions.loadListSuccess),
      map(({ settings }) => SettingAdminActions.setList({ settings }))
    );
  });

  submitForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingAdminActions.submitForm),

      map(({ settings }) => SettingAdminActions.update({ settings }))
    );
  });

  updateSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingAdminActions.update),
      switchMap(({ settings }) =>
        this.settingsService.update(settings).pipe(
          map((settings) => SettingAdminActions.updateSuccess({ settings })),
          catchError((error: any) => of(SettingAdminActions.updateFailure({ error })))
        )
      )
    );
  });

  // TOASTS

  updateSuccessToast$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingAdminActions.updateSuccess),
      map(() => ToastAdminActions.success({ message: `Zaktualizowano ustawienia` }))
    );
  });

  updateFailureToast$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SettingAdminActions.updateFailure),
      map(() => ToastAdminActions.error({ message: `Nie udało się zaktualizować ustawień` }))
    );
  });
}
