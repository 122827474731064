import { UrlSegment } from '@angular/router';

export const checkIsRegulamin = (url: UrlSegment[]): boolean => {
  return url[0].path === 'regulamin' || url[0].path === 'regulamin-sklepu';
};

export const checkIsPolitykaPrywatnosci = (url: UrlSegment[]): boolean => {
  return url[0].path === 'polityka-prywatnosci';
};

export const checkIsContent = (url: UrlSegment[]): boolean => {
  return url.length === 2 && url[0].path === 'content';
};

export const checkIsHome = (url: UrlSegment[]): boolean => {
  return (url.length === 1 && url?.[0]?.path === '') || url.length === 0;
};

export const checkIsProduct = (url: UrlSegment[]): boolean => {
  const lastUrl = url[url.length - 1];
  return !!lastUrl?.path.match(/^(\d+)-.*\.html/gm);
};

export const checkIsCategory = (url: UrlSegment[]): boolean => {
  return url.length === 1 && !!url[0].path.match(/^(\d+)-.*$/gm);
};

export const checkIsContact = (url: UrlSegment[]): boolean => {
  return url.length === 1 && url[0].path === 'kontakt';
};

export const checkIsNewProducts = (url: UrlSegment[]): boolean => {
  return url.length === 1 && url[0].path === 'nowe-produkty';
};

export const checkIsDiscount = (url: UrlSegment[]): boolean => {
  return url.length === 1 && url[0].path === 'promocje';
};

export const checkIsBestseller = (url: UrlSegment[]): boolean => {
  return url.length === 1 && url[0].path === 'najczesciej-kupowane';
};

export const checkIsGroup = (url: UrlSegment[]): boolean => {
  return url.length === 2 && url[0].path === 'grupa';
};

export const checkIsTag = (url: UrlSegment[]): boolean => {
  return url.length === 2 && url[0].path === 'tag';
};

export const checkIsSearch = (url: UrlSegment[]): boolean => {
  return url.length === 1 && url[0].path === 'szukaj';
};
