import { registerLocaleData } from '@angular/common';
import myLocalePl from '@angular/common/locales/pl';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';

import { AppModule } from 'app/app.module';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(myLocalePl);

function bootstrap(): void {
  setTimeout(() => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
      .catch((err) => console.error(err));
  }, 0);
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
