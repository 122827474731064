import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Order, User } from '@manzuko/shared';
import { SalesManagoContactExtEventResponse } from '@manzuko/shared/interfaces/salesmanago';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesmanagoService {
  private readonly http = inject(HttpClient);

  contactUpsert(params: any) {
    return this.http.post<any>(`${environment.api}/salesmanago/contact/upsert`, params);
  }

  addContactExtEvent(params: any) {
    return this.http.post<SalesManagoContactExtEventResponse>(
      `${environment.api}/salesmanago/contact/addContactExtEvent`,
      params
    );
  }

  updateContactExtEvent(params: any) {
    return this.http.post<SalesManagoContactExtEventResponse>(
      `${environment.api}/salesmanago/contact/updateContactExtEvent`,
      params
    );
  }

  batchAddContactExtEvent(params: any) {
    return this.http.post<any>(
      `${environment.api}/salesmanago/contact/batchAddContactExtEvent`,
      params
    );
  }

  newsletter(email: string) {
    return this.contactUpsert({
      contact: { email },
      forceOptIn: true,
      forceOptOut: false,
      forcePhoneOptIn: false,
      forcePhoneOptOut: true
    });
  }

  register(user: User) {
    const contact = {
      contact: {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        externalId: user.id
      },
      forceOptIn: user.newsletter,
      forceOptOut: !user.newsletter,
      forcePhoneOptIn: false,
      forcePhoneOptOut: true
    };
    return this.contactUpsert(contact);
  }

  order(order: Order) {
    const events = [
      {
        email: order?.user?.email || order?.address?.email,
        contactEvent: {
          location: 'manzuko_com',
          contactExtEventType: 'PURCHASE',
          externalId: order.id,
          date: new Date(order.createDate!).getTime(),
          value: order.totalPaid || order.totalPrice,
          products: order.products?.map((product) => product?.reference).join(', ')
        }
      }
    ];
    return this.batchAddContactExtEvent({ events });
  }
}
