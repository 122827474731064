import { ApiPagination, Product } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const TagActions = createActionGroup({
  source: 'Tag/API',
  events: {
    Enter: emptyProps(),
    Leave: emptyProps(),
    load: props<{ page: number; tag: number; language: string }>(),
    Set: props<{
      products: Product[];
      pagination: ApiPagination;
    }>()
  }
});
