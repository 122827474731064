import { HttpErrorResponse } from '@angular/common/http';
import { ApiPagination, Subscription } from '@manzuko/shared';
import { SubscriptionParams } from '@manzuko/shared/interfaces/subscription-params.dto';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const SubscriptionActions = createActionGroup({
  source: 'Subscription/API',
  events: {
    Set: props<{ subscriptions: Subscription[] }>(),
    'Set Back In Stock Subscription': props<{ subscription: Partial<Subscription> }>(),
    'Set Back In Stock Subscription Success': props<{ subscription: Subscription }>(),
    'Set Back In Stock Subscription Failure': props<{ error: any }>(),

    'Set Success': emptyProps(),

    'Send Back In Stock Subscription': props<{ productId: number }>(),
    'Send Back In Stock Subscription Success': emptyProps(),
    'Send Back In Stock Subscription Failure': props<{ error: any }>(),

    'Clear Errors': emptyProps(),
    'Set Error': props<{ error: any }>(),

    'Load Subscription': props<{ params: SubscriptionParams }>(),
    'Load Subscription Success': props<{
      subscriptions: Subscription[];
      pagination: ApiPagination;
    }>(),
    'Load Subscription Failure': props<{ error: HttpErrorResponse }>(),

    'Load One': props<{ id: number }>(),
    'Load One Success': props<{ subscription: Subscription }>(),
    'Load One Failure': props<{ error: any }>(),

    Create: props<{ subscription: Subscription }>(),
    'Create Success': props<{ subscription: Subscription }>(),
    'Create Failure': props<{ error: any }>(),

    Update: props<{ subscription: Subscription }>(),
    'Update Success': props<{ subscription: Subscription }>(),
    'Update Failure': props<{ error: any }>(),

    Remove: props<{ id: number }>(),
    'Remove Success': props<{ id: number }>(),
    'Remove Failure': props<{ error: any }>()
  }
});
