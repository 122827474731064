import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { convertObjectDateStringsToDate } from '@manzuko/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class DateHttpInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (httpRequest?.responseType !== 'json') {
      return next.handle(httpRequest);
    }

    // If the expected response type is JSON then handle it here.
    return this.handleResponse(httpRequest, next);
  }

  private handleResponse(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Override the responseType to disable the default JSON parsing.
    // httpRequest = httpRequest.clone({ responseType: 'text' });
    // Handle the response using the custom parser.
    return next.handle(httpRequest).pipe(map(this.parseResponse.bind(this)));
  }

  private parseResponse(event: HttpEvent<any>): HttpEvent<any> {
    if (!(event instanceof HttpResponse)) {
      return event;
    }

    const body = convertObjectDateStringsToDate(event.body);
    return event.clone({ body });
  }
}
