import { ApiPagination, Message } from '@manzuko/shared';
import { createActionGroup, props } from '@ngrx/store';

export const MessageActions = createActionGroup({
  source: 'Message/API',
  events: {
    'Load By User Id': props<{ userId: number }>(),
    'Load By User Id Success': props<{ messages: Message[]; pagination: ApiPagination }>(),
    'Load By User Id Failure': props<{ error: any }>(),

    'Load By Order Id': props<{ orderId: number }>(),
    'Load By Order Id Success': props<{ messages: Message[]; pagination: ApiPagination }>(),
    'Load By Order Id Failure': props<{ error: any }>()
  }
});
