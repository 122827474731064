import { inject, Injectable } from '@angular/core';
import { Content } from '@manzuko/shared';

import { SEOService } from 'app/shared/seo.service';
import { shorten, stripHtmlTags } from 'app/utils';

import { contentSeoUrl } from './content.helpers';

@Injectable({
  providedIn: 'root'
})
export class ContentSeoService {
  private readonly seoService = inject(SEOService);

  setSeo(lang: string, content: Content): void {
    const title = content?.locale?.[lang]?.metaTitle || content?.locale?.[lang]?.name;
    this.seoService.setPageTitle(title);

    const cleanDescription = stripHtmlTags(content?.locale?.[lang]?.metaDescription);
    const description = shorten(cleanDescription, 160);
    const metaTags: Record<string, string> = {
      description,
      keywords: content.locale?.[lang]?.metaKeywords
    };
    this.seoService.updateMetaTags(metaTags);

    const url = contentSeoUrl(content, lang);
    const locale = lang === 'pl' ? 'pl_PL' : 'en_US';
    const ogTags: Record<string, string> = {
      'og:url': url,
      'og:locale': locale,
      'og:title': title,
      'og:description': description
    };
    const alternatePl = contentSeoUrl(content, 'pl');
    this.seoService.createLinkForAlternateDefault(alternatePl);
    this.seoService.updateOgTags(ogTags);
    this.seoService.createLinkForCanonicalURL(url);
    this.seoService.createLinkForAlternatePl(alternatePl);
    this.seoService.createLinkForAlternateEn(contentSeoUrl(content, 'en'));
  }
}
