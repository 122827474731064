import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { CookieSettings } from '@manzuko/shared';
import { Store } from '@ngrx/store';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';

import { LayoutActions } from 'app/data-access/layout';

@Component({
  standalone: true,
  selector: 'mzk-cookies',
  templateUrl: './cookies.component.html',
  imports: [NgClass, TranslocoDirective, RouterLink, FastSvgComponent, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookiesComponent {
  readonly #store = inject(Store);

  @Input() showAdvanced: boolean;
  @Input() isOpen = false;

  @Output() readonly closed: EventEmitter<void> = new EventEmitter();
  @Output() readonly showAdvancedChange = new EventEmitter<boolean>();

  protected readonly cookiesForm: FormGroup = new FormGroup({
    functional: new FormControl(false),
    analytics: new FormControl(false),
    performance: new FormControl(false),
    advertising: new FormControl(false),
    others: new FormControl(false)
  });

  toggleAdvanced(showAdvanced: boolean): void {
    this.showAdvancedChange.emit(showAdvanced);
  }

  hideCookies(cookieSettings: CookieSettings): void {
    // try {
    //   // this.googleTagService.gtag('consent', 'update', {
    //   //   ad_storage: cookieSettings.advertising,
    //   //   ad_user_data: cookieSettings.advertising,
    //   //   ad_personalization: cookieSettings.advertising,
    //   //   analytics_storage: cookieSettings.analytics,
    //   //   functionality_storage: cookieSettings.functional,
    //   //   personalization_storage: cookieSettings.functional,
    //   //   security_storage: cookieSettings.functional
    //   // });
    //   this.googleTagService.gtag('consent', 'update', {
    //     ad_storage: 'granted',
    //     ad_user_data: 'granted',
    //     ad_personalization: 'granted',
    //     analytics_storage: 'granted',
    //     functionality_storage: 'granted',
    //     personalization_storage: 'granted',
    //     security_storage: 'granted'
    //   });
    // } catch (error) {
    //   console.error('Error while updating GA consent', error);
    // }
    this.#store.dispatch(LayoutActions.acceptCookies({ cookieSettings }));
  }
}
