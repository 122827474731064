import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Product, ProductParams, ProductResults } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private readonly http = inject(HttpClient);
  private readonly router = inject(Router);

  get(params: ProductParams): Observable<ProductResults> {
    return this.http.get<ProductResults>(`${environment.api}/catalog/products`, {
      params: params as HttpParams
    });
  }

  getOne(params: ProductParams): Observable<Product> {
    return this.http.get<Product>(`${environment.api}/catalog/products/${params?.id}`, {
      params: params as HttpParams
    });
  }

  getMany(ids: number[] | string, params: ProductParams): Observable<Product[]> {
    return this.http.get<Product[]>(`${environment.api}/catalog/products/many/${ids}`, {
      params: params as HttpParams
    });
  }

  sample(params: any): Observable<ProductResults> {
    return this.http.get<ProductResults>(`${environment.api}/catalog/products/sample`, {
      params
    });
  }

  // TODO move to admin service
  uploadImages(images: FormData): Observable<number[]> {
    return this.http.post<number[]>(`${environment.api}/admin/product-images/upload`, images);
  }

  createVisit(product: Product): Observable<any> {
    const currentUrl = this.router.url;

    return this.http.post(`${environment.api}/catalog/visits`, {
      id: product.id,
      productId: product.id,
      categoryId: product.categoryId,
      url: currentUrl
    });
  }
  // getProductWithHistory(id: number): Observable<Product> {
  //   return this.http.get<Product>(`${environment.api}/products/productWithHistory/${+id}`);
  // }
}
