import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const SitemapActions = createActionGroup({
  source: 'Sitemap/Page',
  events: {
    Enter: emptyProps(),
    Leave: emptyProps(),
    Load: props<{ language: string }>()
  }
});
