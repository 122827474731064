import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, of } from 'rxjs';

import { selectLayoutLanguage } from 'app/data-access/layout';

@Pipe({
  standalone: true,
  name: 'mzkLink'
})
export class RouterLinkPipe implements PipeTransform {
  private readonly store = inject(Store);

  createPath(value: string): string {
    if (value?.startsWith('https://manzuko.com/en/')) {
      return value.replace('https://manzuko.com/en/', '/');
    }
    if (value?.startsWith('https://manzuko.com/')) {
      return value.replace('https://manzuko.com/', '/');
    }
    if (value?.startsWith('/en/')) {
      return value.replace('/en/', '/');
    }
    if (!value?.startsWith('/')) {
      return `/${value}`;
    } else {
      return value;
    }
  }

  getLink(language: string, path: string): string {
    if (language && language.toLowerCase() === 'pl') {
      return path;
    } else if (language && language.toLowerCase() === 'en') {
      return `/en${path}`;
    }
    return path;
  }

  transform(value: string = '', lang?: string): Observable<string> {
    const path = this.createPath(value);

    if (lang) {
      return of(this.getLink(lang, path));
    }

    return this.store
      .select(selectLayoutLanguage)
      .pipe(map((language: string) => this.getLink(language, path)));
  }
}
