import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { getLangFromRouteParams } from '@manzuko/shared/utils/lang-from-params';
import { catchError, map } from 'rxjs';

import { ProductService, productWithUrls } from 'app/data-access/product';

import { DiscountSeoService } from './discount-seo.service';

export const discountResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const discountSeoService = inject(DiscountSeoService);
  const productsService = inject(ProductService);
  const router = inject(Router);

  const lang = getLangFromRouteParams(route?.params);
  const queryParams = route?.queryParams;
  const products = productsService.get({ ...queryParams, language: lang, discounts: true });

  return products.pipe(
    map((productsResults) => {
      const products = productsResults?.results.map((product) => ({
        ...productWithUrls(product, lang)
      }));
      const title = lang === 'en' ? 'Discounts' : 'Promocje';
      discountSeoService.setSeo(lang, title);

      const pagination = productsResults?.pagination;
      // const currentUrl = state.url;
      // const correctUrl = `${lang === 'en' ? '/en' : ''}/promocje`;
      // const params = new URLSearchParams(queryParams);
      // const correctUrlWithParams = `${correctUrl}${
      //   Object.keys(queryParams)?.length ? '?' + params.toString() : ''
      // }`;

      // if (currentUrl !== correctUrlWithParams) {
      //   router.navigateByUrl(correctUrlWithParams);
      // }

      return {
        products,
        pagination
      };
    }),
    catchError((error) => router.navigateByUrl('/not-found'))
  );
};
