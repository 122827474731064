import { inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { getLangUrl } from '@manzuko/shared/utils/get-lang-url';

import { SEOService } from 'app/shared/seo.service';

@Injectable({
  providedIn: 'root'
})
export class BestsellerSeoService {
  private readonly seoService = inject(SEOService);
  private readonly metaService = inject(Meta);

  descriptionEn =
    'Bestsellers Manzuko | The most frequently and willingly purchased semi-finished products for making jewelry in our store ✔️ Great quality ✔️ Affordable prices ✔️ Fast shipping – see more.';

  descriptionPl =
    'Bestsellery Manzuko | Najczęściej i najchętniej kupowane półprodukty do robienia biżuterii w naszym sklepie ✔️ Świetna jakość ✔️ Przystępne ceny ✔️ Szybka wysyłka – zobacz więcej';

  setSeo(lang: string, title: string): void {
    const canonicalUrl = getLangUrl('najczesciej-kupowane', lang);
    this.seoService.setPageTitle(title);
    this.metaService.updateTag({
      name: 'description',
      content: `${lang === 'en' ? this.descriptionEn : this.descriptionPl}`
    });
    this.seoService.createLinkForCanonicalURL(canonicalUrl);

    const ogTags: Record<string, string> = {
      // 'og:type': 'product',
      'og:title': title,
      'og:description': `${lang === 'en' ? this.descriptionEn : this.descriptionPl}`,
      'og:url': canonicalUrl,
      'og:image': `https://manzuko.b-cdn.net/assets/images/logo-text-white.jpg`,
      'og:locale': lang === 'en' ? 'en_US' : 'pl_PL'
    };

    this.seoService.updateOgTags(ogTags);

    this.seoService.createLinkForAlternatePl('https://manzuko.com/najczesciej-kupowane');
    this.seoService.createLinkForAlternateEn('https://manzuko.com/en/najczesciej-kupowane');
    this.seoService.createLinkForAlternateDefault('https://manzuko.com/najczesciej-kupowane');
  }
}
