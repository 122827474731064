import { inject, Injectable } from '@angular/core';
import { Category } from '@manzuko/shared';

import { SEOService } from 'app/shared/seo.service';
import { shorten, stripHtmlTags } from 'app/utils';

import { categorySeoUrl } from './category.helpers';

@Injectable({
  providedIn: 'root'
})
export class CategorySeoService {
  private readonly seoService = inject(SEOService);

  setSeo(category: Partial<Category> = {}, page = 1, lang: string): void {
    const pageTitle = page > 1 ? `- ${lang === 'en' ? 'Page' : 'Strona'} ${page}` : '';
    const title = category?.metaTitle || category?.name;
    const metaTitle = shorten(title, pageTitle ? 40 : 50);
    this.seoService.setPageTitle(`${metaTitle} ${pageTitle}`);

    const cleanDescription = stripHtmlTags(category?.metaDescription || category?.description);
    const description = shorten(cleanDescription, 160);

    const metaTags: Record<string, string> = {
      description,
      keywords: category?.metaKeywords
    };
    const ogTags: Record<string, string> = {
      // 'og:type': 'product',
      'og:title': title,
      'og:description': description,
      'og:url': categorySeoUrl(category, page, lang),
      'og:image': `https://manzuko.com/assets/images/p/${category?.productImageId}.jpg`,
      'og:locale': lang === 'en' ? 'en_US' : 'pl_PL'
    };

    this.seoService.updateMetaTags(metaTags);
    this.seoService.updateOgTags(ogTags);
    const categorySeoUrlPl = categorySeoUrl(category, page, 'pl');
    this.seoService.createLinkForCanonicalURL(categorySeoUrl(category, page, lang));
    this.seoService.createLinkForAlternatePl(categorySeoUrlPl);
    this.seoService.createLinkForAlternateEn(categorySeoUrl(category, page, 'en'));
    this.seoService.createLinkForAlternateDefault(categorySeoUrlPl);
  }
}
