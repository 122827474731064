import { IMAGE_CONFIG } from '@angular/common';
import { Provider } from '@angular/core';

import { ImageLoaderProviderConfig } from './image-loader-provider-config';

export const withBreakpoints = (
  breakpoints: ImageLoaderProviderConfig['breakpoints'] = []
): Provider => {
  if (breakpoints?.length === 0) {
    return null;
  }

  return {
    provide: IMAGE_CONFIG,
    useValue: { breakpoints }
  };
};
