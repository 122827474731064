import { isPlatformServer } from '@angular/common';
import { ErrorHandler, inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { environment } from './../environments/environment';

// import { MatDialog } from '@angular/material/dialog';
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    console.log('app error handler init');
    // Sentry.init({
    //   dsn: 'https://62268eea13cb44708031aa82024a85e4@o1403074.ingest.sentry.io/6735636',
    //   integrations: [
    //     new BrowserTracing({
    //       tracingOrigins: [
    //         'localhost',
    //         'https://manzuko.shop',
    //         'https://api.manzuko.shop',
    //         'https://app.manzuko.shop',
    //         'https://manzuko.com',
    //         'https://api.manzuko.com',
    //         'https://app.manzuko.com'
    //       ],
    //       routingInstrumentation: Sentry.routingInstrumentation
    //     })
    //   ],

    //   // Set tracesSampleRate to 1.0 to capture 100%
    //   // of transactions for performance monitoring.
    //   // We recommend adjusting this value in production
    //   tracesSampleRate: 0.2
    // });
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error);
  }
}

@Injectable()
export class Error500Handler implements ErrorHandler {
  // private readonly toast = inject(HotToastService);
  private readonly injector = inject(Injector);
  // private readonly dialog = inject(MatDialog);

  handleError(error) {
    const platformId = this.injector.get(PLATFORM_ID);

    if (isPlatformServer(platformId)) {
      return;
    }

    if (error?.statusCode) {
      return;
    }

    console.warn(error?.message || error?.error);

    // this.injector.get(Store).dispatch(ToastAdminActions.error({ message: 'test' }));
    // this.injector.get(HotToastService).error(error?.message || error?.error, {
    //   className: 'bg-red-500 text-white'
    // });
    // this.dialog.closeAll();
    // this.dialog.open(Error500Component, { data: { error } });
    // console.warn('Client error:', error);
    return;

    if (error.message?.includes('Uncaught')) {
      // return this.router.navigate(['/500']);
      // this.dialog.closeAll();
      // this.dialog.open(Error500Component, { data: { error } });
      // .afterClosed()
      // .subscribe((result) => {
      //   // console.log(`Dialog result: ${result}`);
      // });
    }
  }
}

export function getErrorHandler(): ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandler();
  }
  return new Error500Handler();
  // return new ErrorHandler();
}
