import { ApiPagination, Product, ProductParams } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ProductActions = createActionGroup({
  source: 'Product/API',
  events: {
    Enter: emptyProps(),
    Leave: emptyProps(),

    'Load Products': props<{ params: ProductParams }>(),
    'Load Success': props<{ products: Product[]; pagination: ApiPagination }>(),
    'Load Failure': props<{ error: any }>(),

    'Load One': props<{ params: ProductParams }>(),
    'Load One Success': props<{ product: Product }>(),
    'Load One Failure': props<{ error: any }>(),

    'Set One': props<{ product: Product }>(),
    'Set Products': props<{ products: Product[] }>(),

    'Load Many': props<{ ids: number[]; params?: ProductParams }>(),
    'Load Many Success': props<{ products: Product[] }>(),
    'Load Many Failure': props<{ error: any }>(),

    Create: props<{ product: Product }>(),
    'Create Success': props<{ product: Product }>(),
    'Create Failure': props<{ error: any }>(),

    Remove: props<{ productId: number }>(),
    'Remove Success': emptyProps(),
    'Remove Failure': props<{ error: any }>(),

    Update: props<{ product: Product }>(),
    'Update Success': emptyProps(),
    'Update Failure': props<{ error: any }>(),

    'Remove Products': props<{ ids: number[] }>(),
    'Remove Products Success': emptyProps(),
    'Remove Products Failure': props<{ error: any }>(),

    'Update Products': props<{ ids: number[]; prop: any }>(),
    'Update Products Success': emptyProps(),
    'Update Products Failure': props<{ error: any }>(),

    'Create Visit': props<{ product: Product }>(),
    'Create Visit Success': props<{ product: Product }>(),
    'Create Visit Failure': props<{ error: any }>()
  }
});
