import { provideEffects } from '@ngrx/effects';
import { NavigationActionTiming, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { Action, ActionReducer, provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';

import { MessageEffects } from 'app/data-access/message/message.effects';

import { SettingAdminEffects } from './admin/data-access/settings/setting.effects';
import { settingAdminFeature } from './admin/data-access/settings/setting.reducer';
import { AuthEffects } from './data-access/auth/auth.effects';
import { authFeature } from './data-access/auth/auth.reducer';
import { CartEffects } from './data-access/cart/cart.effects';
import { cartFeature } from './data-access/cart/cart.reducer';
import { CategoryEffects } from './data-access/category/category.effects';
import { categoryFeature } from './data-access/category/category.reducer';
import { FavoriteEffects, favoriteFeature } from './data-access/favorites';
import { GoogleAnalyticsEffects } from './data-access/google-analytics';
import { LayoutEffects } from './data-access/layout/layout.effects';
import { layoutFeature } from './data-access/layout/layout.reducer';
import { ProductEffects } from './data-access/product/product.effects';
import { productFeature } from './data-access/product/product.reducer';
import { settingFeature } from './data-access/setting/setting.reducer';
import { SubscriptionEffects } from './data-access/subscription/subscription.effects';
import { subscriptionFeature } from './data-access/subscription/subscription.reducer';

declare const isDevelopmentMode: boolean;

export function localStorageSyncReducer<T, V extends Action = Action>(
  reducer: ActionReducer<any>
): ActionReducer<T, V> {
  return (state, action) => {
    if (action.type === '@ngrx/store/update-reducers') {
      return reducer(state, action);
    }
    return localStorageSync({
      rehydrate: true,
      removeOnUndefined: true,
      restoreDates: false,
      keys: [
        'auth',
        'cart',
        'settings'
        // 'layout'
        // 'categories'
        // 'products'
      ]
    })(reducer)(state, action);
  };
}

export const APP_STORE_PROVIDERS = [
  provideStore(
    {
      router: routerReducer
    },
    {
      metaReducers: [localStorageSyncReducer],
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: true
      }
    }
  ),
  provideRouterStore({
    navigationActionTiming: NavigationActionTiming.PostActivation
  }),

  provideState(authFeature),
  provideState(subscriptionFeature),
  provideState(cartFeature),
  provideState(categoryFeature),
  provideState(layoutFeature),
  provideState(productFeature),
  provideState(settingAdminFeature),
  provideState(favoriteFeature),
  provideState(settingFeature), //
  //   errorHandler: errorHandlerFeature.reducer,
  //   ngrxForms: ngrxFormsFeature.reducer,

  // provideState(rehydrateFeature),

  provideEffects([
    AuthEffects,
    CartEffects,
    CategoryEffects,
    LayoutEffects,
    ProductEffects,
    SubscriptionEffects,
    MessageEffects,
    SettingAdminEffects,
    FavoriteEffects, //
    GoogleAnalyticsEffects
  ]),

  // isPlatformBrowser(inject(PLATFORM_ID))
  isDevelopmentMode
    ? provideStoreDevtools({
        maxAge: 50,
        // logOnly: environment.production,
        autoPause: true,
        actionsBlocklist: [
          '@ngrx/',
          '[Message/Admin/API] Set Panel',
          '[Message/Admin/API] Load Interval',
          '[Notification/Admin/API] Load Interval',
          '[Notification/Admin/API] Load Success',
          '[Notification/Admin/API] Set'
        ]
      })
    : []
];
