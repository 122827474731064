import { ApplicationRef, inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, first, interval, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CheckForUpdateService {
  private readonly swUpdate = inject(SwUpdate);

  // Allow the app to stabilize first, before starting
  // polling for updates with `interval()`.
  readonly appIsStable$ = inject(ApplicationRef)?.isStable.pipe(
    first((isStable) => isStable === true)
  );
  readonly everySixHours$ = interval(60 * 1000); // 6 * 60 * 60 * 1000);
  readonly everySixHoursOnceAppIsStable$ = concat(this.appIsStable$, this.everySixHours$).pipe(
    tap(async () => {
      try {
        const updateFound = await this.swUpdate.checkForUpdate();
        if (updateFound) {
          console.log('A new version is available.');
          return;
        }
        // console.log('Already on the latest version.');
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    })
  );
}
