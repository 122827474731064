import { SocialAuthService } from '@abacritt/angularx-social-login';
import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { LoginResponse } from '@manzuko/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { fromEvent, of } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import { GoogleAnalyticsActions } from '../google-analytics/google-analytics.actions';
import { selectLayoutLanguage } from '../layout';
import { selectUrl } from '../router';
import { SalesmanagoService } from '../salesmanago/salesmanago.service';
import { AuthActions } from './auth.actions';
import { selectAuthToken } from './auth.selectors';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthEffects {
  private readonly router = inject(Router);
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly authService = inject(AuthService);
  private readonly salesManagoService = inject(SalesmanagoService);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly socialAuthService = isPlatformBrowser(this.platformId)
    ? inject(SocialAuthService, { optional: true })
    : null;

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((credentials) =>
        this.authService.login(credentials).pipe(
          map((auth: LoginResponse) => AuthActions.loginSuccess({ auth })),
          catchError((error: any) => of(AuthActions.loginFailure({ error })))
        )
      )
    );
  });

  loginWithSocial$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginWithSocial),
      switchMap(({ email, firstName, id, idToken, lastName, name, photoUrl, provider }) =>
        this.authService
          .loginWithSocial({ email, firstName, id, idToken, lastName, name, photoUrl, provider })
          .pipe(
            map((auth: LoginResponse) => AuthActions.loginSuccess({ auth, provider })),
            catchError((error: any) => of(AuthActions.loginFailure({ error })))
          )
      )
    );
  });

  loginSucces$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        concatMap(() =>
          this.store.select(selectLayoutLanguage).pipe(
            take(1),
            map((language) => {
              const baseRoute = language === 'en' ? '/en' : '/';
              return this.router.navigate([baseRoute]);
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  loginFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginFailure),
        tap(() => console.warn('Login failed'))
      );
    },
    { dispatch: false }
  );

  getApiSecureRoutes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getSecureRoutes),
      switchMap(() => this.authService.getSecureRoutes()),
      map((secureRoutes) => AuthActions.setSecureRoutes({ secureRoutes }))
    );
  });

  setAuthOnLoginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map(({ auth }) => AuthActions.set({ auth }))
    );
  });

  setAuthOnLoginSuccessFromLocalStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginSuccessFromLocalStorage),
      map(({ auth }) => AuthActions.set({ auth }))
    );
  });

  getUserOnTokenChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setSecureRoutes),
      concatMap(() => {
        return this.store.select(selectAuthToken).pipe(
          filter(Boolean),
          distinctUntilChanged(),
          map(() => AuthActions.getUser())
        );
      })
    );
  });

  getUserProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUser),
      switchMap(() => this.authService.get()),
      map((user) => AuthActions.setUser({ user }))
    );
  });

  register$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.register),
      switchMap(({ user }) =>
        this.authService.create({ ...user, roles: ['customer'] }).pipe(
          map(() => AuthActions.registerSuccess({ user })),
          catchError((error: unknown) => of(AuthActions.registerFailure({ errors: error })))
        )
      )
    );
  });

  registerSalesManago$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.registerSuccess),
        switchMap(({ user }) => this.salesManagoService.register(user))
      );
    },
    { dispatch: false }
  );

  registerFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.registerFailure),
        tap(() => console.warn('Login failed'))
      );
    },
    { dispatch: false }
  );

  googleLogin$ = isPlatformBrowser(this.platformId)
    ? createEffect(() => {
        return this.socialAuthService.authState.pipe(
          map((credentials) => AuthActions.loginWithSocial(credentials))
        );
      })
    : () => {};

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        concatLatestFrom(() => this.store.select(selectUrl)),
        filter(([, url]) => url.includes('/konto') || url.includes('/admin')),
        concatMap(() =>
          this.store.select(selectLayoutLanguage).pipe(
            take(1),
            map((language) => {
              const baseRoute = language === 'en' ? '/en' : '/';
              return this.router.navigate([baseRoute]);
            })
          )
        )
      );
    },
    { dispatch: false }
  );

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateUser),
      switchMap(({ user }) =>
        this.authService.update(user).pipe(
          map((user) => AuthActions.updateUserSuccess({ user })),
          catchError((error: unknown) => of(AuthActions.updateUserFailure({ errors: error })))
        )
      )
    );
  });

  changePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.changePassword),
      switchMap(({ password, currentPassword }) =>
        this.authService.changePassword(password, currentPassword).pipe(
          map(() => AuthActions.changePasswordSuccess()),
          catchError((error: { message: string; error: string; statusCode: number }) =>
            of(AuthActions.changePasswordFailure({ error: error?.message }))
          )
        )
      )
    );
  });

  registerSendToGa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.registerSuccess),
      map(() => GoogleAnalyticsActions.register())
    );
  });

  loginSendToGa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map(({ provider }) => GoogleAnalyticsActions.login({ method: provider }))
    );
  });

  storeLoginActions$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap((action) => {
          const storedActions = window.localStorage.getItem('storedAuthActions');
          const newActions = [action];
          window.localStorage.setItem('storedAuthActions', JSON.stringify(newActions));
        })
      );
    },
    { dispatch: false }
  );

  storageActionsLoginChange$ = isPlatformBrowser(this.platformId)
    ? createEffect(() => {
        return fromEvent<StorageEvent>(window, 'storage').pipe(
          filter((event) => event.key === 'storedAuthActions'),
          filter((event) => event.newValue !== null),
          filter((event) => {
            const [{ type }] = JSON.parse(event.newValue);
            return type === '[Auth/API] Login Success';
          }),
          map((event) => {
            const [{ type, auth }] = JSON.parse(event.newValue);
            return AuthActions.loginSuccessFromLocalStorage({ auth });
          })
        );
      })
    : () => {};

  // setAuthOnLoginSuccessFromLocalStorage$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(AuthActions.loginSuccessFromLocalStorage),
  //     map(({ auth }) => AuthActions.loginSuccess({ auth }))
  //   );
  // });

  storeLogoutActions$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        tap((action) => {
          const storedActions = window?.localStorage?.getItem('storedAuthActions');
          const newActions = [action];
          window?.localStorage?.setItem('storedAuthActions', JSON.stringify(newActions));
        })
      );
    },
    { dispatch: false }
  );

  storageActionsLogoutChange$ = isPlatformBrowser(this.platformId)
    ? createEffect(() => {
        return fromEvent<StorageEvent>(window, 'storage').pipe(
          filter((event) => event.key === 'storedAuthActions'),
          filter((event) => event.newValue !== null),
          filter((event) => {
            const [{ type }] = JSON.parse(event.newValue);
            return type === '[Auth/API] Logout';
          }),
          map((event) => {
            const [{ type, auth }] = JSON.parse(event.newValue);
            return AuthActions.logoutFromLocalStorage();
          })
        );
      })
    : () => {};

  logoutFromLocalStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logoutFromLocalStorage),
      map(() => AuthActions.logout())
    );
  });

  verifyJWTToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.verifyJwt),
      concatLatestFrom(() => this.store.select(selectAuthToken)),
      filter(([, token]) => !!token),
      switchMap(() => this.authService.verifyJWT()),
      map((verified) => (verified ? AuthActions.verifyJwtSuccess() : AuthActions.logout()))
    );
  });
}
