import { MaskitoOptions, MaskitoPreprocessor } from '@maskito/core';
import { maskitoPrefixPostprocessorGenerator, maskitoWithPlaceholder } from '@maskito/kit';

export default {
  ...maskitoWithPlaceholder('+48 ', true),
  mask: ['+', '4', '8', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
  // non-removable country prefix
  postprocessor: maskitoPrefixPostprocessorGenerator('+48 '),
  preprocessor: createTrickyPasteCasesPreprocessor()
} as MaskitoOptions;

// Paste "89123456789" => "+7 (912) 345-67-89"
function createTrickyPasteCasesPreprocessor(): MaskitoPreprocessor {
  const trimPrefix = (value: string): string => value.replace(/^(\+?48?\s?0?)\s?/, '');
  const countDigits = (value: string): number => value.replace(/\D/g, '').length;

  return ({ elementState, data }) => {
    const { value, selection } = elementState;

    return {
      elementState: {
        selection,
        value: countDigits(value) > 11 ? trimPrefix(value) : value
      },
      data: countDigits(data) >= 11 ? trimPrefix(data) : data
    };
  };
}
