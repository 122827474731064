import { Shipping } from '@manzuko/shared';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

import { ShippingActions } from './shipping.actions';

export type ShippingState = EntityState<Shipping>;

export const adapter: EntityAdapter<Shipping> = createEntityAdapter<Shipping>();

export const initialState: ShippingState = adapter.getInitialState({});

export const shippingFeature = createFeature({
  name: 'shipping',
  reducer: createReducer(
    initialState,
    on(ShippingActions.setAll, (state, { shippings }) => adapter.setMany(shippings, state))
  )
});
