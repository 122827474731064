import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';
import { combineLatest, map } from 'rxjs';

import { AuthActions } from 'app/data-access/auth/auth.actions';
import {
  selectAuthUser,
  selectAuthUserId,
  selectAuthUserIsAdmin
} from 'app/data-access/auth/auth.selectors';
import { selectLayoutLanguage } from 'app/data-access/layout';

import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { RouterLinkPipe } from '../pipes';

@Component({
  standalone: true,
  selector: 'mzk-customer-icon',
  templateUrl: './customer-icon.component.html',
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    FastSvgComponent,
    ClickOutsideDirective,
    RouterLinkPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerIconComponent {
  private readonly store = inject(Store);

  protected readonly userId$ = this.store.select(selectAuthUserId);
  protected readonly isAdmin$ = this.store.select(selectAuthUserIsAdmin);
  protected readonly user$ = this.store.select(selectAuthUser);
  protected readonly lang$ = this.store.select(selectLayoutLanguage);
  protected readonly loginLink$ = combineLatest([this.user$, this.lang$]).pipe(
    map(([user, lang]) => (user ? null : lang === 'en' ? '/en/logowanie' : '/logowanie'))
  );
  protected readonly icon$ = this.userId$.pipe(map((user) => (user ? 'user-solid' : 'user')));
  protected readonly nav = [
    { label: 'account', path: 'konto' },
    { label: 'orders', path: 'zamowienia' }
  ];

  menuOpen = false;

  logout(): void {
    this.menuOpen = false;
    this.store.dispatch(AuthActions.logout());
  }
}
