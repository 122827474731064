import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { selectAuthUser } from './auth.selectors';

@Injectable({ providedIn: 'root' })
export class NotAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  private readonly store = inject(Store);
  private readonly router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> | UrlTree {
    return this.check();
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> | UrlTree {
    return this.check();
  }

  canLoad(): boolean | Observable<boolean | UrlTree> | UrlTree {
    return this.check();
  }

  private check(): boolean | Observable<boolean | UrlTree> | UrlTree {
    return this.store.pipe(
      select(selectAuthUser),
      map((user) => {
        if (!user) {
          return true;
        }
        return this.router.createUrlTree(['']);
      })
    );
  }
}
