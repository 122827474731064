/* eslint-disable @typescript-eslint/member-ordering */
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { LayoutActions, selectLayoutLanguage } from '../layout';
import { CategorySeoService } from './category-seo.service';
import { CategoryActions } from './category.actions';
import { selectCategoryWithPage } from './category.selectors';
import { CategoryService } from './category.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly categoryService = inject(CategoryService);
  private readonly categorySeoService = inject(CategorySeoService);

  setTopCategories$ = createEffect(() => {
    return this.store.pipe(
      select(selectLayoutLanguage),
      switchMap((language = 'pl') =>
        this.categoryService.getNav({ language }).pipe(
          map((categories) =>
            categories.map((category) => ({
              ...category,
              active: true
              // parentId: 2
            }))
          ),
          map((categories) => CategoryActions.setCategories({ categories }))
        )
      )
    );
  });

  // setTree$ = createEffect(() => {
  //   return this.store.pipe(
  //     select(selectLayoutLanguage),
  //     switchMap((language = 'pl') =>
  //       this.categoryService
  //         .getTree({ language })
  //         .pipe(map((categories) => CategoryActions.setCategories({ categories })))
  //     )
  //   );
  // });

  setCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoryActions.loadCategory),
      switchMap(({ id, language }) =>
        this.categoryService
          .get({ id, language: language || 'pl' })
          .pipe(map((categories) => CategoryActions.setCategories({ categories })))
      )
    );
  });

  loadCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoryActions.loadCategories),
      switchMap((params) =>
        this.categoryService.list(params).pipe(
          map(({ results, pagination }) =>
            CategoryActions.loadCategoriesSuccess({ categories: results, pagination })
          ),
          catchError((error: unknown) => of(CategoryActions.loadCategoriesFailure({ error })))
        )
      )
    );
  });

  setCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoryActions.loadCategoriesSuccess),
      map(({ categories }) => CategoryActions.setCategories({ categories }))
    );
  });

  setCategoryMeta$ = createEffect(
    () => {
      return this.store.pipe(
        select(selectCategoryWithPage),
        filter(({ category, language }) => !!category?.id && !!language && !!category?.description),
        tap(({ category, page, language }) =>
          this.categorySeoService.setSeo(category, +page, language)
        )
      );
    },
    { dispatch: false }
  );

  loadNavCategoryOnSelect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LayoutActions.selectNav),
      map(({ selectedNav }) => selectedNav),
      filter(Boolean),
      concatLatestFrom(() => this.store.select(selectLayoutLanguage)),
      map(([id, language]) => CategoryActions.loadCategory({ id, language }))
    );
  });
}
