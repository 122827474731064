import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Shipping, ShippingResults } from '@manzuko/shared';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  private readonly http = inject(HttpClient);

  get(language: string) {
    const params = { language };
    return this.http.get<ShippingResults>(`${environment.api}/catalog/shippings`, {
      params: params as unknown as HttpParams
    });
  }

  create(shipping: Shipping) {
    return this.http.post<Shipping>(`${environment.api}/catalog/shippings`, shipping);
  }

  update(shipping: Shipping) {
    return this.http.put<Shipping>(`${environment.api}/catalog/shippings/${shipping.id}`, shipping);
  }

  delete(id: number) {
    return this.http.delete(`${environment.api}/catalog/shippings/${id}`);
  }
}
