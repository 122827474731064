import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiResults, Category, Product } from '@manzuko/shared';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private readonly http = inject(HttpClient);

  search(
    params: any
  ): Observable<{ categories: ApiResults<Category>; products: ApiResults<Product> }> {
    return this.http.get<{ categories: ApiResults<Category>; products: ApiResults<Product> }>(
      `${environment.api}/catalog/search`,
      {
        params: params as HttpParams
      }
    );
  }

  searchProducts(params: any): Observable<ApiResults<Product>> {
    return this.http.get<ApiResults<Product>>(`${environment.api}/catalog/search/products`, {
      params: params as HttpParams
    });
  }
}
