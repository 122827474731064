import { inject, Injectable } from '@angular/core';
import { Product } from '@manzuko/shared';

import { SEOService } from 'app/shared/seo.service';
import { shorten, stripHtmlTags } from 'app/utils';

import { productSeoUrl } from './product.helpers';

@Injectable({
  providedIn: 'root'
})
export class ProductSeoService {
  private readonly seoService = inject(SEOService);

  setSeo(product: Partial<Product> = {}, lang: string): void {
    const productWithoutReference = product?.name?.replace(product?.reference, '');
    const shortenedTitle = shorten(productWithoutReference, 50);
    const metaTitle = this.addReference(shortenedTitle, product?.reference);
    this.seoService.setPageTitle(metaTitle);
    const cleanDescription = stripHtmlTags(product?.metaDescription || product?.description);
    const description = this.addReference(
      shorten(cleanDescription, product?.reference?.length > 10 ? 140 : 150),
      product?.reference
    );

    const metaTags: Record<string, string> = {
      description,
      keywords: product?.metaKeywords
    };
    const ogTags: Record<string, string> = {
      'og:type': 'product',
      'og:title': metaTitle,
      'og:description': description,
      'og:url': productSeoUrl(product, lang),
      'og:image': `https://manzuko.com/assets/images/p/${product?.imageId}.jpg`,
      'og:locale': lang === 'en' ? 'en_US' : 'pl_PL',
      'og:price:amount': `${product?.price}`,
      'og:price:currency': lang === 'en' ? 'EUR' : 'PLN',
      'og:product:brand': 'Manzuko',
      'og:product:availability': product?.quantity > 0 ? 'in stock' : 'out of stock',
      'og:product:condition': 'new',
      'og:product:locale': lang === 'en' ? 'en_US' : 'pl_PL',
      'product:price:amount': `${product?.price}`,
      'product:price:currency': lang === 'en' ? 'EUR' : 'PLN',
      'product:retailer_item_id': product?.reference
    };
    this.seoService.updateMetaTags(metaTags);

    this.seoService.updateOgTags(ogTags);

    const alternatePl = productSeoUrl(product, 'pl');
    this.seoService.createLinkForAlternateDefault(alternatePl);
    this.seoService.createLinkForCanonicalURL(productSeoUrl(product, lang));
    this.seoService.createLinkForAlternatePl(alternatePl);
    this.seoService.createLinkForAlternateEn(productSeoUrl(product, 'en'));
  }

  addReference(value: string, reference: string): string {
    return `${value}${value?.includes(reference) ? '' : ` ${reference}`}`;
  }
}
