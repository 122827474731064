import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { getLangFromRouteParams } from '@manzuko/shared/utils/lang-from-params';
import { catchError, map } from 'rxjs';

import { ProductService, productWithUrls } from 'app/data-access/product';

export const categoryProductsResolver: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  const productsService = inject(ProductService);
  const router = inject(Router);

  const categoryId = route?.params?.categoryId;
  const lang = getLangFromRouteParams(route?.params);
  const queryParams = route?.queryParams;
  const products = productsService.get({ categoryId, language: lang, ...queryParams });

  return products.pipe(
    map((productsResults) => {
      const products = productsResults?.results.map((product) => ({
        ...productWithUrls(product, lang)
      }));

      const pagination = productsResults?.pagination;

      return {
        products,
        categoryId,
        pagination
      };
    }),
    catchError((error) => router.navigateByUrl('/not-found'))
  );
};
