import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, of, switchMap, takeUntil } from 'rxjs';

import { GoogleAnalyticsActions } from '../google-analytics';
import { ProductActions, selectProductById } from '../product';
import { FavoriteActions } from './favorite.actions';
import { selectFavoriteProductIds, selectFavoriteRouteParams } from './favorite.selectors';
import { FavoriteService } from './favorite.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly favoritesService = inject(FavoriteService);

  enter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.enter),
      concatMap(() =>
        this.store.select(selectFavoriteRouteParams).pipe(
          map((params) => FavoriteActions.load({ params })),
          takeUntil(this.actions$.pipe(ofType(FavoriteActions.leave)))
        )
      )
    );
  });

  load$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.load),
      switchMap(({ params }) =>
        this.favoritesService.get(params).pipe(
          map(({ results, pagination }) =>
            FavoriteActions.loadSuccess({ favorites: results, pagination })
          ),
          catchError((error: unknown) => of(FavoriteActions.loadFailure({ error })))
        )
      )
    );
  });

  set$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.loadSuccess),
      map(({ favorites, pagination }) => FavoriteActions.set({ favorites, pagination }))
    );
  });

  loadFavoriteProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.enter),
      concatMap(() =>
        this.store
          .select(selectFavoriteProductIds)
          .pipe(map((ids) => ProductActions.loadMany({ ids })))
      )
    );
  });

  addFavorite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.add),
      switchMap(({ productId }) =>
        this.favoritesService.create(productId).pipe(
          map((favorite) => FavoriteActions.addSuccess({ favorite })),
          catchError((error: any) => of(FavoriteActions.addFailure({ error })))
        )
      )
    );
  });

  sendAddToWishlistToGa$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.addSuccess),
      concatLatestFrom(({ favorite }) => this.store.select(selectProductById(favorite.productId))),
      map(([, product]) => GoogleAnalyticsActions.addToWishlist({ product }))
    );
  });

  removeFavorite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.remove),
      switchMap(({ productId }) =>
        this.favoritesService.delete(productId).pipe(
          map((favorite) => FavoriteActions.removeSuccess({ favorite })),
          catchError((error: any) => of(FavoriteActions.removeFailure({ error })))
        )
      )
    );
  });

  reloadList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.enter),
      concatMap(() =>
        this.actions$.pipe(
          ofType(FavoriteActions.addSuccess, FavoriteActions.removeSuccess),
          concatLatestFrom(() => this.store.select(selectFavoriteRouteParams)),
          map(([, params]) => FavoriteActions.load({ params })),
          takeUntil(this.actions$.pipe(ofType(FavoriteActions.leave)))
        )
      )
    );
  });

  reload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.addSuccess, FavoriteActions.removeSuccess),
      map(() => FavoriteActions.loadAll())
    );
  });

  loadAll$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoriteActions.loadAll),
      switchMap(() =>
        this.favoritesService.getAll().pipe(
          map((favorites) => FavoriteActions.loadAllSuccess({ favorites })),
          catchError((error: unknown) => of(FavoriteActions.loadAllFailure({ error })))
        )
      )
    );
  });
}
