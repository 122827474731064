import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Subscription } from '@manzuko/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';

import { SubscriptionActions } from './subscription.actions';
import { SubscriptionService } from './subscription.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionEffects {
  private readonly actions$ = inject(Actions);
  private readonly subscriptionService = inject(SubscriptionService);

  set$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.setBackInStockSubscription),
      switchMap(({ type, subscription }) =>
        this.subscriptionService.set(subscription).pipe(
          map((subscription) =>
            SubscriptionActions.setBackInStockSubscriptionSuccess({ subscription })
          ),
          catchError((error) =>
            of(SubscriptionActions.setBackInStockSubscriptionFailure({ error }))
          )
        )
      )
    );
  });

  setSubscriptionSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.setBackInStockSubscriptionSuccess),
        tap(() => console.log('set subscribe success'))
      );
    },
    { dispatch: false }
  );

  setSubscriptionFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.setBackInStockSubscriptionFailure),
      map(({ error }) => SubscriptionActions.setError({ error }))
    );
  });

  sendBackInStock$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.sendBackInStockSubscription),
        switchMap(({ productId }) =>
          this.subscriptionService.sendBackInStock(productId).pipe(
            map(() => SubscriptionActions.sendBackInStockSubscriptionSuccess()),
            catchError((error) =>
              of(SubscriptionActions.sendBackInStockSubscriptionFailure({ error }))
            )
          )
        )
      );
    },
    { dispatch: false }
  );

  sendBackInStockSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.sendBackInStockSubscriptionSuccess),
        tap(() => console.log('send back in stock success'))
      );
    },
    { dispatch: false }
  );

  sendBackInStockFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SubscriptionActions.sendBackInStockSubscriptionFailure),
        tap(({ error }) => console.log(error, 'send back in stock failure'))
      );
    },
    { dispatch: false }
  );

  loadSubscriptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.loadSubscription),
      switchMap(({ params }) =>
        this.subscriptionService.get({ ...params }).pipe(
          map(({ results, pagination }) =>
            SubscriptionActions.loadSubscriptionSuccess({
              subscriptions: results,
              pagination
            })
          ),
          catchError((error) => of(SubscriptionActions.loadSubscriptionFailure({ error })))
        )
      )
    );
  });

  setSubscriptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.loadSubscriptionSuccess),
      map(({ subscriptions }) => SubscriptionActions.set({ subscriptions: subscriptions }))
    );
  });

  loadSubscription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.loadOne),
      switchMap(({ id }) =>
        this.subscriptionService.getOne(id).pipe(
          map((subscription: Subscription) => SubscriptionActions.loadOneSuccess({ subscription })),
          catchError((error) => of(SubscriptionActions.loadOneFailure({ error })))
        )
      )
    );
  });

  setSubscription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.loadOneSuccess),
      map(({ subscription }) => SubscriptionActions.set({ subscriptions: [subscription] }))
    );
  });

  createSubscription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.create),
      switchMap(({ subscription }) =>
        this.subscriptionService.create(subscription).pipe(
          map((subscription: Subscription) => SubscriptionActions.createSuccess({ subscription })),
          catchError((error) => of(SubscriptionActions.createFailure({ error })))
        )
      )
    );
  });

  updateSubscription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.update),
      switchMap(({ subscription }) =>
        this.subscriptionService.update(subscription).pipe(
          map((subscription: Subscription) => SubscriptionActions.updateSuccess({ subscription })),
          catchError((error) => of(SubscriptionActions.updateFailure({ error })))
        )
      )
    );
  });
  removeSubscription$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SubscriptionActions.remove),
      switchMap(({ id }) =>
        this.subscriptionService.remove(id).pipe(
          map(() => SubscriptionActions.removeSuccess({ id })),
          catchError((error: HttpErrorResponse) => of(SubscriptionActions.removeFailure({ error })))
        )
      )
    );
  });
}
