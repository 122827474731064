import { ApiPagination, Category, CategoryParams } from '@manzuko/shared';
import { EntityMap, EntityMapOne, Predicate, Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CategoryActions = createActionGroup({
  source: 'Category/API',
  events: {
    'Load Categories': props<CategoryParams>(),
    'Load Categories Success': props<{ categories: Category[]; pagination: ApiPagination }>(),
    'Load Categories Failure': props<{ error: any }>(),
    'Set Categories': props<{ categories: Category[] }>(),
    'Add Category': props<{ category: Category }>(),
    'Set Category': props<{ category: Category }>(),
    'Upsert Category': props<{ category: Category }>(),
    'Add Categories': props<{ categories: Category[] }>(),
    'Upsert Categories': props<{ categories: Category[] }>(),
    'Update Category': props<{ update: Update<Category> }>(),
    'Update Categories': props<{ updates: Update<Category>[] }>(),
    'Map Category': props<{ entityMap: EntityMapOne<Category> }>(),
    'Map Categories': props<{ entityMap: EntityMap<Category> }>(),
    'Delete Category': props<{ id: string }>(),
    'Delete Categories': props<{ ids: string[] }>(),
    'Delete Categories By Predicate': props<{
      predicate: Predicate<Category>;
    }>(),
    'Clear Categories': emptyProps(),

    Create: props<{ category: Category }>(),
    'Create Success': emptyProps(),
    'Create Failure': props<{ error: any }>(),

    Update: props<{ category: Category }>(),
    'Update Success': emptyProps(),
    'Update Failure': props<{ error: any }>(),

    Delete: props<{ id: string }>(),
    'Delete Success': emptyProps(),
    'Delete Failure': props<{ error: any }>(),

    'Load Top Categories': emptyProps(),
    'Load Tree': emptyProps(),
    'Load Category': props<{ id: number; language?: string; page?: number }>()
  }
});
