import { createFeature, createReducer, on } from '@ngrx/store';

import { LayoutActions } from './layout.actions';

export interface Layout {
  showCart: boolean;
  showMenu: boolean;
  showSearch: boolean;
  selectedNav: number;
  language: string;
  currency: string;
}

const initialState: Layout = {
  showCart: false,
  showMenu: false,
  showSearch: false,
  selectedNav: 0,
  language: 'pl',
  currency: 'PLN'
};

export const layoutFeature = createFeature({
  name: 'layout',
  reducer: createReducer(
    initialState,

    on(
      LayoutActions.toggleCart,
      (state, { showCart }): Layout => ({
        ...state,
        showCart
      })
    ),

    on(
      LayoutActions.toggleMenu,
      (state, { showMenu }): Layout => ({
        ...state,
        showMenu: showMenu !== undefined ? showMenu : !state.showMenu
      })
    ),

    on(
      LayoutActions.toggleSearch,
      (state, { showSearch }): Layout => ({
        ...state,
        showSearch
      })
    ),

    on(
      LayoutActions.selectNav,
      (state, { selectedNav }): Layout => ({
        ...state,
        selectedNav
      })
    ),

    on(
      LayoutActions.setLanguage,
      (state, { language }): Layout => ({
        ...state,
        language
      })
    ),

    on(
      LayoutActions.setCurrency,
      (state, { currency }): Layout => ({
        ...state,
        currency
      })
    )
  )
});
