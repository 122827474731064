import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { catchError, Subject, switchMap, tap } from 'rxjs';

import { GoogleAnalyticsActions } from 'app/data-access/google-analytics';
import { NewsletterService } from 'app/data-access/newsletter/newsletter.service';
import { SalesmanagoService } from 'app/data-access/salesmanago/salesmanago.service';

@Component({
  standalone: true,
  selector: 'mzk-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
  imports: [AsyncPipe, ReactiveFormsModule, TranslocoDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsletterComponent {
  readonly #store = inject(Store);
  readonly #newsletterService = inject(NewsletterService);
  readonly #salesManagoService = inject(SalesmanagoService);

  protected readonly success$ = new Subject<boolean>();
  protected readonly error$ = new Subject<any>();
  protected readonly submit$ = new Subject<any>();

  protected readonly onSubmit$ = this.submit$
    .pipe(
      tap(() =>
        this.#store.dispatch(
          GoogleAnalyticsActions.subscribeNewsletter({ email: this.form?.value?.email })
        )
      ),
      switchMap(() => this.#salesManagoService.newsletter(this.form?.value?.email)),
      switchMap(() => this.#newsletterService.create({ email: this.form?.value?.email }))
    )
    .pipe(
      tap((response: { success: boolean; error: Error }) => {
        if (response.success) {
          return this.success$.next(true);
        }
        return this.error$.next(response.error);
      }),
      catchError((error: Error) => {
        this.error$.next(error);
        return [];
      })
    );

  protected readonly form: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    error: new FormControl(null)
  });

  protected readonly formValueChange$ = this.form.valueChanges.pipe(
    tap(() => {
      this.success$.next(false);
      this.error$.next(null);
    })
  );

  onSubmit(form: FormGroup): void {
    if (form.invalid) {
      return;
    }
    this.success$.next(false);
    this.error$.next(null);
    this.submit$.next('');
  }
}
