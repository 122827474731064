import { Category } from '@manzuko/shared';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

import { CategoryActions } from './category.actions';

export interface CategoryState extends EntityState<Category> {
  selectedId: number;
}

export const sortByName = (a: Category, b: Category): number => a?.name?.localeCompare(b?.name);

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>({
  sortComparer: sortByName
});

export const initialState: CategoryState = adapter.getInitialState({
  selectedId: null
});

export const categoryFeature = createFeature({
  name: 'categories',
  reducer: createReducer(
    initialState,
    on(CategoryActions.addCategory, (state, { category }) => {
      return adapter.addOne(category, state);
    }),
    on(CategoryActions.setCategory, (state, { category }) => {
      return adapter.setOne(category, state);
    }),
    on(CategoryActions.upsertCategory, (state, { category }) => {
      return adapter.upsertOne(category, state);
    }),
    on(CategoryActions.addCategories, (state, { categories }) => {
      return adapter.addMany(categories, state);
    }),
    on(CategoryActions.upsertCategories, (state, { categories }) => {
      return adapter.upsertMany(categories, state);
    }),
    on(CategoryActions.updateCategory, (state, { update }) => {
      return adapter.updateOne(update, state);
    }),
    on(CategoryActions.updateCategories, (state, { updates }) => {
      return adapter.updateMany(updates, state);
    }),
    on(CategoryActions.mapCategory, (state, { entityMap }) => {
      return adapter.mapOne(entityMap, state);
    }),
    on(CategoryActions.mapCategories, (state, { entityMap }) => {
      return adapter.map(entityMap, state);
    }),
    on(CategoryActions.deleteCategory, (state, { id }) => {
      return adapter.removeOne(id, state);
    }),
    on(CategoryActions.deleteCategories, (state, { ids }) => {
      return adapter.removeMany(ids, state);
    }),
    on(CategoryActions.deleteCategoriesByPredicate, (state, { predicate }) => {
      return adapter.removeMany(predicate, state);
    }),
    on(CategoryActions.setCategories, (state, { categories }) => {
      return adapter.setMany(categories, state);
    }),
    on(CategoryActions.clearCategories, (state) => {
      return adapter.removeAll({ ...state, selectedCategoryId: null });
    })
  )
});
