import { Discount, Product } from '@manzuko/shared';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

import { ProductActions } from './product.actions';

export interface ProductState extends EntityState<Product> {
  selectedId: number;
}

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>();

export const initialState: ProductState = adapter.getInitialState({
  selectedId: null
});

export const productFeature = createFeature({
  name: 'products',
  reducer: createReducer(
    initialState,
    on(ProductActions.setOne, (state, { product }) => adapter.setOne(product, state)),
    on(ProductActions.setProducts, (state, { products }) => ({
      ...adapter.setMany(products, state)
    }))
  )
});

const getActiveDiscounts = (product: Product): Discount[] => {
  if (!product.discounts || product.discounts?.length === 0) {
    return [];
  }

  return product.discounts;
};
