import { Category, Product, Tag } from '@manzuko/shared';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectAuthUserIsAdmin } from '../auth/auth.selectors';
import { categoryWithUrls } from '../category/category.helpers';
import {
  selectCategory,
  selectCategoryEntities,
  selectCategoryIdParam
} from '../category/category.selectors';
import { selectLayoutCurrency, selectLayoutLanguage } from '../layout/layout.selectors';
import { selectQueryParams, selectRouteParam } from '../router';
import { productJsonLd, productWithUrls } from './product.helpers';
import { adapter, ProductState } from './product.reducer';

export const selectProductState = createFeatureSelector<ProductState>('products');

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter?.getSelectors() || {};

export const selectProductIds = createSelector(selectProductState, selectIds);
export const selectProductEntities = createSelector(selectProductState, selectEntities);
export const selectProductAll = createSelector(selectProductState, selectAll);
export const selectProductTotal = createSelector(selectProductState, selectTotal);

export const selectProductAllWithUrls = createSelector(
  selectProductAll,
  selectLayoutLanguage,
  (products: Product[], language: string) =>
    products?.map((product) => productWithUrls(product, language))
);

export const selectProductCurrentId = createSelector(
  selectProductState,
  (state: ProductState) => state.selectedId
);

export const selectProductCurrent = createSelector(
  selectProductEntities,
  selectProductCurrentId,
  selectLayoutLanguage,
  (entities: Dictionary<Product>, selectedId: number, language: string) =>
    productWithUrls(entities?.[selectedId], language)
);

export const selectProductIdParam = selectRouteParam('productId');

export const selectProductIdParamAndLanguage = createSelector(
  selectProductIdParam,
  selectLayoutLanguage,
  (productId: string, language: string) => ({ productId, language })
);

export const selectProduct = createSelector(
  selectProductEntities,
  selectProductIdParam,
  selectLayoutLanguage,
  (entities: Dictionary<Product>, productId: string, language: string) => {
    const product = productWithUrls(entities?.[productId], language);
    return {
      ...product,
      tags: product?.tags as Tag[],
      descriptionShort: product?.descriptionShort?.replace(/<\/?h1[^>]*>/g, '')
    };
  }
);

export const selectProductAndLanguage = createSelector(
  selectProduct,
  selectLayoutLanguage,
  (product: Product, language: string) => ({ product, language })
);

export const selectProductsFromCategory = createSelector(
  selectProductAllWithUrls,
  selectCategoryIdParam,
  (products: Product[], categoryId: string) =>
    products?.filter((product: Product) => (product?.categories as number[]).includes(+categoryId))
);

export const selectProductCategoryId = createSelector(
  selectProduct,
  (product: Product) => product?.categoryId
);

export const selectProductBreadcrumbs = createSelector(
  selectProductCategoryId,
  selectCategoryEntities,
  selectLayoutLanguage,
  (categoryId: Product['categoryId'], categories: Dictionary<Category>, language: string) => {
    let current = categoryWithUrls(categories?.[categoryId], language);
    let breadcrumbs = [];

    while (current?.parentId > 1) {
      breadcrumbs = [...breadcrumbs, { ...current }];
      current = categoryWithUrls(categories?.[current?.parentId], language);
    }
    breadcrumbs.push({
      displayName: 'Manzuko',
      name: 'Manzuko',
      path: language === 'en' ? '/en' : '/'
    });
    return breadcrumbs.reverse();
  }
);

export const selectProductJsonLd = createSelector(
  selectProduct,
  selectCategoryEntities,
  (product: Product, categories: Dictionary<Category>) =>
    productJsonLd(product, categories?.[product?.categoryId])
);

export const selectProductView = createSelector(
  selectProduct,
  selectProductJsonLd,
  selectAuthUserIsAdmin,
  selectLayoutCurrency,
  (product, jsonLd, isAdmin, currency) => ({
    product,
    jsonLd,
    isAdmin,
    currency
    // images: product?.images?.map((imageId) =>
    //   productCdnUrl(environment.cdn, { src: `${imageId}`, width: 800 })
    // )
  })
);

export const selectProductById = (id: number) =>
  createSelector(selectProductEntities, selectLayoutLanguage, (entities, language: string) =>
    productWithUrls(entities?.[id], language)
  );

export const selectProductDiscounts = createSelector(
  selectProduct,
  (product: Product) => product?.discounts?.filter((discount) => discount?.active)
);

export const selectProductsTree = createSelector(
  selectProductAllWithUrls,
  (products: Product[]) => {
    return products?.sort((a: Product, b: Product) => a?.name?.localeCompare(b?.name));
  }
);

export const selectLayoutProductAndCategoryPath = createSelector(
  selectProduct,
  selectCategory,
  selectQueryParams,
  (product, category, queryParams) => {
    if (product?.id) {
      return product?.path;
    }
    if (category?.id) {
      const queryString = new URLSearchParams(queryParams);
      return `${category?.path}${Object.keys(queryString)?.length ? `?${queryString}` : ''}`;
    }

    return null;
  }
);
