import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, switchMap, tap } from 'rxjs';

import { ProductActions } from 'app/data-access/product';
import { ProductService } from 'app/data-access/product/product.service';
import { TagActions } from './tag.actions';
import { selectTagPageLanguageUrl } from './tag.selectors';

@Injectable({
  providedIn: 'root'
})
export class TagEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly productService = inject(ProductService);

  loadTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TagActions.enter),
      concatMap(() =>
        this.store
          .select(selectTagPageLanguageUrl)
          .pipe(
            map(({ page, language, tag }) =>
              TagActions.load({ page: +page || 1, language, tag: +tag?.split('-')[0] })
            )
          )
      )
    );
  });

  setTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TagActions.load),
      switchMap(({ page, language, tag }) =>
        this.productService.get({
          language,
          page: +page,
          ...(tag && { tag })
        })
      ),
      map(({ results, pagination }) =>
        TagActions.set({
          products: results,
          pagination
        })
      )
    );
  });

  setTagProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TagActions.set),
      map((results) => ProductActions.setProducts(results))
    );
  });
}
