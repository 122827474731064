import { inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { getLangUrl } from '@manzuko/shared/utils/get-lang-url';

import { SEOService } from 'app/shared/seo.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountSeoService {
  private readonly seoService = inject(SEOService);
  private readonly metaService = inject(Meta);

  descriptionEn =
    'Promotions on jewelry semi-finished products and beads at Manzuko ✂️Low prices 🚚Fast delivery ✔️Huge selection ✔️Competent and friendly service ✔️Wholesale and retail quantities';

  descriptionPl =
    'Promocje na półfabrykaty jubilerskie i koraliki w Manzuko ✂️Niskie ceny 🚚Szybka dostawa ✔️Ogromny wybór ✔️Kompetentna i miła obsługa ✔️Hurtowe i detaliczne ilości';

  setSeo(lang: string, title: string): void {
    const canonicalUrl = getLangUrl('promocje', lang);
    this.seoService.setPageTitle(title);
    this.metaService.updateTag({
      name: 'description',
      content: `${lang === 'en' ? this.descriptionEn : this.descriptionPl}`
    });
    this.seoService.createLinkForCanonicalURL(canonicalUrl);

    const ogTags: Record<string, string> = {
      // 'og:type': 'product',
      'og:title': title,
      'og:description': `${lang === 'en' ? this.descriptionEn : this.descriptionPl}`,
      'og:url': canonicalUrl,
      'og:image': `https://manzuko.b-cdn.net/assets/images/logo-text-white.jpg`,
      'og:locale': lang === 'en' ? 'en_US' : 'pl_PL'
    };

    this.seoService.updateOgTags(ogTags);

    this.seoService.createLinkForAlternatePl('https://manzuko.com/promocje');
    this.seoService.createLinkForAlternateEn('https://manzuko.com/en/promocje');
    this.seoService.createLinkForAlternateDefault('https://manzuko.com/promocje');
  }
}
