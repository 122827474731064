import { Cart } from '@manzuko/shared';
import { createFeature, createReducer, on } from '@ngrx/store';

import { CartActions } from './cart.actions';

export interface CartState {
  cart: Cart;
  voucherError: boolean;
  voucherSuccess: boolean;
}

export const initialState: CartState = {
  cart: {} as Cart,
  voucherError: false,
  voucherSuccess: false
};

export const cartFeature = createFeature({
  name: 'cart',
  reducer: createReducer(
    initialState,

    on(CartActions.set, (state, { cart }): CartState => ({ ...state, cart })),

    on(CartActions.setFromLocalStorage, (state, { cart }): CartState => ({ ...state, cart })),

    on(CartActions.clear, (): CartState => initialState),

    on(CartActions.loadVoucherFailure, (state): CartState => ({ ...state, voucherError: true })),

    on(CartActions.clearVoucherError, (state): CartState => ({ ...state, voucherError: false })),

    on(
      CartActions.clearVoucherSuccess,
      (state): CartState => ({ ...state, voucherSuccess: false })
    ),

    on(CartActions.loadVoucherSuccess, (state): CartState => ({ ...state, voucherSuccess: true }))
  )
});
