import { Directive, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[disableMouseScroll]'
})
export class DisableMouseScrollDirective {
  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    event.preventDefault();
  }
}
