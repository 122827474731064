<nav
  *transloco="let t; read: 'shop.nav'"
  aria-label="Main Navigation"
  class="fixed abs z-[105] top-0 left-0 w-10/12 h-full overflow-hidden max-h-[90vh] md:static md:block md:w-full">
  <ul
    class="flex flex-wrap bg-neutral-100 min-h-11 text-black uppercase items-start w-full h-full overflow-y-scroll md:overflow-hidden flex-col border-b-neutral-200 md:border-b-4 md:items-end md:justify-evenly md:flex-row md:w-auto md:text-base lg:text-lg">
    @for (category of categories$ | async; track category?.id) {
      <li
        attr.data-test="home-nav-category-{{ category.id }}"
        [ngClass]="{ parent: category.isExpandable }">
        <a [routerLink]="category?.path" routerLinkActive="active">
          <span
            (click)="selectNav(category?.id, $event)"
            (mouseenter)="selectNav(category?.id, $event)">
            @if ((showMenu$ | async) && category?.productImageId) {
              <img
                src="https://manzuko.b-cdn.net/assets/images/p/{{
                  category?.productImageId
                }}.jpg?width=100"
                alt="{{ category?.name }}"
                loading="lazy"
                class="rounded-full aspect-square border border-neutral-200 p-[1px] mr-5 md:hidden" />
            }
            <span class="flex flex-grow">
              {{ category?.displayName || category?.name }}
            </span>
          </span>
        </a>
      </li>
    }

    <li>
      <a
        data-test="new"
        [routerLink]="'/nowe-produkty' | mzkLink | async"
        routerLinkActive="active">
        <fast-svg name="nav-stars" size="22" />
        <span class="font-semibold">
          {{ t('news') }}
        </span>
      </a>
    </li>

    <li>
      <a
        data-test="nav-promotion"
        [routerLink]="'/promocje' | mzkLink | async"
        routerLinkActive="active"
        class="text-brand-700">
        <fast-svg name="nav-promotion" size="22" />
        <span class="font-semibold">
          {{ t('sale') }}
        </span>
      </a>
    </li>

    <li>
      <a
        data-test="nav-bestseller"
        [routerLink]="'/najczesciej-kupowane' | mzkLink | async"
        routerLinkActive="active">
        <fast-svg name="nav-bestseller" size="22" />
        <span class="font-semibold">
          {{ t('best') }}
        </span>
      </a>
    </li>
  </ul>
</nav>

<!-- @if (showMenu$ | async; as showMenu) { -->
<!-- <div
  class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[100] transition-all cursor-close backdrop-blur-[1px]"
  [ngClass]="{ 'opacity-100 pointer-events-auto': true }"></div> -->
<!-- } -->
