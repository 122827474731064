import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Subscription, SubscriptionParams } from '@manzuko/shared';
import { SubscriptionResults } from '@manzuko/shared/interfaces/subscription-results.dto';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private readonly http = inject(HttpClient);

  // get number of users waiting for a product
  getUsersSubs(productId: number): Observable<number> {
    return this.http.get<number>(`${environment.api}/subscription/users/${productId}`);
  }

  sendBackInStock(id: number) {
    return this.http.get(
      `${environment.api}/subscription/backinstock/${id}?nocache=${new Date()?.getTime()}`
    );
  }

  set(subscription: Partial<Subscription>) {
    return this.http.post<Subscription>(`${environment.api}/subscription`, subscription);
  }

  create(subscription: Subscription) {
    return this.http.post(`${environment.api}/subscription`, subscription);
  }

  get(params: SubscriptionParams): Observable<SubscriptionResults> {
    return this.http.get<SubscriptionResults>(`${environment.api}/subscription`, {
      params: params as HttpParams
    });
  }

  getOne(id: number) {
    return this.http.get(`${environment.api}/subscription/${id}?nocache=${new Date()?.getTime()}`);
  }

  update(subscription: Subscription) {
    return this.http.post(
      `${environment.api}/subscription/update/${subscription.id}`,
      subscription
    );
  }
  remove(id: number) {
    return this.http.delete(`${environment.api}/subscription/delete/${id}`);
  }
}
