import {
  FacebookLoginProvider,
  GoogleInitOptions,
  GoogleLoginProvider,
  SocialAuthServiceConfig
} from '@abacritt/angularx-social-login';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideFastSVG } from '@push-based/ngx-fast-svg';

import { CheckForUpdateService } from './sw/check-for-update.sw.service';
import { HandleUnrecoverableStateService } from './sw/handle-unrecoverable-state.sw.service';
import { LogUpdateService } from './sw/log-update.sw.service';
import { PromptUpdateService } from './sw/prompt-update.sw.service';

/**
 * These providers are only present in Browser (Client) version of the app.
 * Not provided to Server-side rendered app.
 */

const googleLoginOptions: GoogleInitOptions = {
  oneTapEnabled: false
};

const SocialAuthServiceConfigFactory = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const config: SocialAuthServiceConfig = {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '401812126015-tcomfnm1hqb295b0d9f0qacklb9eluki.apps.googleusercontent.com',
              googleLoginOptions //TODO add to env file
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1357594671765471') //TODO add to env file
          }
        ],
        onError: (err) => {
          console.error(err);
          reject(err);
        }
      };
      resolve(config);
    });
  });
};

export const APP_BROWSER_PROVIDER = [
  provideFastSVG({
    url: (name: string) => `assets/icons/${name}.svg`
  }),
  LogUpdateService,
  CheckForUpdateService,
  PromptUpdateService,
  HandleUnrecoverableStateService,
  {
    provide: 'SocialAuthServiceConfig',
    useFactory: SocialAuthServiceConfigFactory
  },
  provideAnimationsAsync()
];
