import { Cart, CartItem, Product, Shipping, ShippingId } from '@manzuko/shared';
import { createSelector, defaultMemoize } from '@ngrx/store';

import { selectAddressAll } from '../address/address.selectors';
import { selectAuthUserId } from '../auth/auth.selectors';
import { selectLayoutCurrency, selectLayoutLanguage } from '../layout/layout.selectors';
import { productWithUrls } from '../product/product.helpers';
import { selectProductEntities } from '../product/product.selectors';
import { selectShippingAll } from '../shipping';
import { cartFeature } from './cart.reducer';

const convertProductsObject = (cart: Partial<Cart>): Cart =>
  ({
    ...cart,
    products: cart?.products
      ? !Array.isArray(cart.products)
        ? Object.values(cart.products)
        : cart.products
      : []
  }) as Cart;

export const {
  selectCartState: selectCartState,
  selectVoucherError: selectCartVoucherError,
  selectVoucherSuccess: selectCartVoucherSuccess
} = cartFeature;

export const selectCartWithRefactoredProducts = createSelector(selectCartState, ({ cart }) =>
  convertProductsObject(cart)
);

export const selectCart = createSelector(selectCartWithRefactoredProducts, (cart) => cart);
export const selectCartVoucher = createSelector(
  selectCartWithRefactoredProducts,
  (cart) => cart.voucher
);
export const selectCartId = createSelector(selectCartWithRefactoredProducts, (cart) => cart.id);
export const selectCartProductsArray = createSelector(
  selectCartWithRefactoredProducts,
  (cart) => cart.products
);

export const selectCartProductsIds = createSelector(
  selectCartProductsArray,
  (products) => products?.map((product) => product?.id)
);

const selectProductById = (productId: number) =>
  createSelector(selectCartProductsArray, (products) => {
    return products?.find((p) => p.id === productId);
  });

const _selectCartProductQuantity = (productId: number) =>
  createSelector(selectProductById(productId), (product) => product?.quantity || 0);

export const selectCartProductQuantity = (productId: number) =>
  defaultMemoize(_selectCartProductQuantity).memoized(productId);

export const selectCartProducts = createSelector(
  selectCartProductsArray,
  selectProductEntities,
  selectLayoutLanguage,
  (cartProducts, productEntities, language: string) =>
    cartProducts?.map((cartProduct) => ({
      ...productWithUrls(cartProduct as Product, language)
      // product: { ...productEntities?.[cartProduct.id] }
    })) || []
);

export const selectCartProductsCount = createSelector(
  selectCartProductsArray,
  (products) => products?.length || 0
);

export const selectCartProductsQuantity = createSelector(
  selectCartProductsArray,
  (products) => products?.reduce((total, product) => total + product?.quantity, 0) || 0
);

export const selectCartProductsTotalPrice = createSelector(selectCart, (cart) => cart.totalPrice);

export const selectCartPriceWithoutDiscount = createSelector(
  selectCart,
  (cart) => cart?.productsRegularPrice || 0
);

export const selectCartVoucherDiscount = createSelector(
  selectCart,
  (cart) => cart?.voucherDiscount || 0
);

export const selectCartPriceWithDiscount = createSelector(
  selectCart,
  (cart) => cart?.productsPriceWithDiscounts || 0
);

export const selectSideCartView = createSelector(
  selectCart,
  selectCartProducts,
  selectCartProductsTotalPrice,
  selectCartVoucher,
  selectCartPriceWithoutDiscount,
  selectCartVoucherDiscount,
  selectAuthUserId,
  selectCartProductsQuantity,
  selectCartPriceWithDiscount,
  (
    cart,
    products,
    totalPrice,
    voucher,
    priceWithoutDiscount,
    discount,
    userId,
    count,
    priceWithDiscount
  ) => {
    const cartItems: CartItem[] = products?.map((product: any) => {
      const {
        isQuantityInvalid,
        quantityAfterOrder,
        quantityBeforeOrder,
        quantityDiscountPrice,
        productWithUrlsurlPl,
        urlEn,
        ...rest
      } = product;
      return {
        ...rest,
        productId: product?.id,
        product
      };
    });

    return {
      cart,
      products: cartItems,
      totalPrice,
      voucher,
      priceWithoutDiscount,
      discount,
      userId,
      count,
      priceWithDiscount
    };
  }
);

export const selectCartView = createSelector(
  selectSideCartView,
  selectShippingAll,
  selectAddressAll,
  (sideCartView, shippings, addresses) => {
    const hasVirtualProduct = sideCartView?.products.some((product) => product?.isVirtual);
    const hasNonVirtualProduct = sideCartView?.products.some((product) => !product?.isVirtual);

    const validShippings = shippings
      .map((shipping) => {
        const price = shipping?.prices.find(
          (price) =>
            (price?.min <= sideCartView?.cart?.totalPrice - sideCartView?.cart?.shippingPrice &&
              !price?.max) ||
            price?.max > sideCartView?.cart?.totalPrice - sideCartView?.cart?.shippingPrice
        )?.price;
        if (price === null) return;

        return {
          ...shipping,
          price
        };
      })
      .filter((shipping) => {
        if (hasVirtualProduct && !hasNonVirtualProduct) {
          return shipping?.id === ShippingId.wysylkaElektroniczna;
        } else {
          return shipping?.id !== ShippingId.wysylkaElektroniczna;
        }
      })
      .filter(Boolean);
    const updatedShippings = validShippings.map((shipping) => {
      const isFreeShipping = sideCartView?.voucher?.freeShippingIds?.includes(shipping.id);
      return {
        ...shipping,
        isFree: isFreeShipping,
        prices: shipping?.prices.map((price) => ({
          ...price,
          price: 0
        })),
        price: isFreeShipping ? 0 : shipping?.price
      };
    });

    const filteredByPayment = filterValidShippings(
      updatedShippings,
      sideCartView?.cart?.payment || 'transfer'
    )?.sort((a, b) => a?.price - b?.price);

    const bestPrice = filteredByPayment?.reduce((acc: number, shipping) => {
      if (shipping?.price === 0) return 0;
      if (shipping?.price <= acc) return shipping?.price;
      return acc;
    }, 10);

    let shippingBestPrice = bestPrice;
    if (hasVirtualProduct && !hasNonVirtualProduct) {
      shippingBestPrice = 0;
    } else {
      shippingBestPrice = bestPrice;
    }

    return {
      ...sideCartView,
      shippings: filteredByPayment?.filter(Boolean),
      shippingBestPrice,
      addresses
    };
  }
);

export const selectCartRequestProperties = createSelector(
  selectCart,
  selectLayoutLanguage,
  selectLayoutCurrency,
  selectAuthUserId,
  (cart, language, currency, userId) => ({
    cart,
    language,
    currency,
    userId
  })
);

export const filterValidShippings = (shippings: Shipping[], payment: string) => {
  if (payment === 'transfer' || payment === 'online') {
    return shippings.filter((shipping) => !(shipping.carrierType === 'cod'));
  }
  if (payment === 'cash') {
    return shippings.filter((shipping) => shipping.carrierType === 'cod');
  }
  return shippings;
};

export const selectCartCurrentPayment = createSelector(selectCart, (cart) => cart?.payment);

export const selectCartVoucherErrorAndSuccess = createSelector(
  selectCartVoucherError,
  selectCartVoucherSuccess,
  (error, success) => ({ error, success })
);
