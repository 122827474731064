import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Newsletter } from '@manzuko/shared';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {
  private readonly http = inject(HttpClient);

  create(newsletter: Newsletter) {
    return this.http.post(`${environment.api}/catalog/newsletter`, newsletter);
  }
}
