import { Subscription } from '@manzuko/shared';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';

import { SubscriptionActions } from './subscription.actions';

export interface SubscriptionState extends EntityState<Subscription> {
  email: string;
  errors: any;
  success: boolean;
}
export const adapter: EntityAdapter<Subscription> = createEntityAdapter<Subscription>();

export const initialState: SubscriptionState = adapter.getInitialState({
  email: '',
  errors: '',
  success: false
});

export const subscriptionFeature = createFeature({
  name: 'subscription',

  reducer: createReducer(
    initialState,
    on(
      SubscriptionActions.clearErrors,
      (state): SubscriptionState => ({
        ...state,
        errors: null,
        success: false
      })
    ),
    on(
      SubscriptionActions.setError,
      (state, { error }): SubscriptionState => ({
        ...state,
        errors: error,
        success: false
      })
    ),
    on(
      SubscriptionActions.setBackInStockSubscriptionSuccess,
      (state): SubscriptionState => ({
        ...state,
        success: true,
        errors: null
      })
    ),
    on(SubscriptionActions.set, (state, { subscriptions }) => {
      return adapter.setMany(subscriptions, state);
    })
  )
});
