import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { MessageActions } from './message.actions';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class MessageEffects {
  private readonly actions$ = inject(Actions);
  private readonly messageService = inject(MessageService);

  loadByUserId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.loadByUserId),
      switchMap(({ userId }) =>
        this.messageService.getbyuserId(userId).pipe(
          map(({ results, pagination }) =>
            MessageActions.loadByUserIdSuccess({ messages: results, pagination })
          ),
          catchError((error: any) => of(MessageActions.loadByUserIdFailure({ error })))
        )
      )
    );
  });

  loadByOrderId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.loadByOrderId),
      switchMap(({ orderId }) =>
        this.messageService.getByOrderId(orderId).pipe(
          map(({ results, pagination }) =>
            MessageActions.loadByOrderIdSuccess({ messages: results, pagination })
          ),
          catchError((error: any) => of(MessageActions.loadByOrderIdFailure({ error })))
        )
      )
    );
  });
}
