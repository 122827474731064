import { Route } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { environment } from 'environments/environment';

import { SearchEffects, searchFeature } from './catalog/feature/search/data-access';
import { SitemapEffects } from './catalog/feature/sitemap/data-access';
import { TagEffects, tagFeature } from './catalog/feature/tag/data-access';
import { AuthGuard } from './data-access/auth';
import { bestsellerResolver } from './data-access/bestseller/bestseller.resolver';
import { categoryUrlMatcher } from './data-access/category';
import { categoryProductsResolver } from './data-access/category/category-products.resolver';
import { categoryResolver } from './data-access/category/category.resolver';
import { contactResolver } from './data-access/contact/contact.resolver';
import { contentUrlMatcher } from './data-access/content/content.helpers';
import { contentResolver } from './data-access/content/content.resolver';
import { discountResolver } from './data-access/discount/discount.resolver';
import { groupProductsResolver } from './data-access/group/group-products.resolver';
import { groupResolver } from './data-access/group/group.resolver';
import { latestResolver } from './data-access/latest/latest.resolver';
import { langPrefixMatcher } from './data-access/layout';
import { productUrlMatcher } from './data-access/product/product.helpers';
import { productResolver } from './data-access/product/product.resolver';
import { LayoutComponent } from './layout/layout.component';
import { langResolver } from './shared/lang.resolver';

const mainRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('./home/home.component').then((m) => m.HomeComponent),
    data: { revalidate: environment.isrRevalidate }
  },

  /* --------------------------------- */
  /*  Content pages
  /* --------------------------------- */

  {
    path: 'kontakt',
    loadComponent: () => import('./contact/contact.component').then((m) => m.ContactComponent),
    data: { title: 'Kontakt z nami - Manzuko', revalidate: environment.isrRevalidate },
    resolve: {
      title: contactResolver
    }
  },
  {
    matcher: contentUrlMatcher,
    loadComponent: () => import('./content/content.component').then((m) => m.ContentComponent),
    data: { revalidate: environment.isrRevalidate },
    resolve: {
      content: contentResolver,
      lang: langResolver
    }
  },

  /* --------------------------------- */
  /*  Catalog pages
  /* --------------------------------- */

  {
    matcher: productUrlMatcher,
    loadComponent: () =>
      import('./catalog/feature/product/product.component').then((m) => m.ProductComponent),
    data: { revalidate: environment.isrRevalidate },
    resolve: {
      productData: productResolver
    }
  },
  {
    matcher: categoryUrlMatcher,
    loadComponent: () =>
      import('./catalog/feature/category/category.component').then((m) => m.CategoryComponent),
    data: { revalidate: environment.isrRevalidate },
    resolve: {
      categoryData: categoryResolver,
      productsData: categoryProductsResolver
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'promocje',
    loadComponent: () =>
      import('./catalog/feature/discounts/discounts.component').then((m) => m.DiscountsComponent),
    data: {
      title: 'Promocje na półfabrykaty i koraliki - Manzuko',
      revalidate: environment.isrRevalidate
    },
    resolve: {
      discountData: discountResolver
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'grupa/:id',
    loadComponent: () =>
      import('./catalog/feature/group/group.component').then((m) => m.GroupComponent),
    data: {
      revalidate: environment.isrRevalidate
    },
    resolve: {
      groupData: groupResolver,
      productsData: groupProductsResolver
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'nowe-produkty',
    loadComponent: () =>
      import('./catalog/feature/latest/latest.component').then((m) => m.LatestComponent),
    data: {
      title: 'Nowe półfabrykaty i koraliki - Manzuko',
      revalidate: environment.isrRevalidate
    },
    resolve: {
      latestData: latestResolver
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'szukaj',
    loadComponent: () =>
      import('./catalog/feature/search/search.component').then((m) => m.SearchComponent),
    data: {
      title: 'Szukaj',
      revalidate: environment.isrRevalidate
    },
    providers: [provideState(searchFeature), provideEffects([SearchEffects])]
  },
  {
    path: 'tag/:tag',
    loadComponent: () => import('./catalog/feature/tag/tag.component').then((m) => m.TagComponent),
    data: { title: 'Tag', revalidate: environment.isrRevalidate },
    providers: [provideState(tagFeature), provideEffects([TagEffects])]
  },
  {
    path: 'najczesciej-kupowane',
    loadComponent: () =>
      import('./catalog/feature/bestsellers/bestsellers.component').then(
        (m) => m.BestsellersComponent
      ),
    data: {
      title: 'Najczęściej kupowane półfabrykaty i koraliki - Manzuko',
      revalidate: environment.isrRevalidate
    },
    resolve: {
      bestsellerData: bestsellerResolver
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'mapa',
    loadComponent: () =>
      import('./catalog/feature/sitemap/sitemap.component').then((m) => m.SitemapComponent),
    data: {
      title: 'Mapa kategorii półfabrykatów i koralików - Manzuko',
      revalidate: environment.isrRevalidate
    },
    providers: [provideEffects([SitemapEffects])]
  },

  /* --------------------------------- */
  /*  Auth pages
  /* --------------------------------- */

  {
    path: 'logowanie',
    loadComponent: () => import('./auth/login/login.component').then((m) => m.LoginComponent),
    data: { title: 'Logowanie - Manzuko', revalidate: environment.isrRevalidate }
  },
  {
    path: 'rejestracja',
    loadComponent: () =>
      import('./auth/register/register.component').then((m) => m.RegisterComponent),
    data: { title: 'Rejestracja - Manzuko', revalidate: environment.isrRevalidate }
  },
  {
    path: 'odzyskiwanie-hasla',
    loadComponent: () =>
      import('./auth/lost-password/lost-password.component').then((m) => m.LostPasswordComponent),
    data: { title: 'Odzyskiwanie hasła - Manzuko', revalidate: environment.isrRevalidate }
  },
  {
    path: 'reset/:token',
    loadComponent: () => import('./auth/reset/reset.component').then((m) => m.ResetComponent)
  },
  {
    path: 'konto',
    data: { title: 'Twoje konto - Manzuko' },
    canActivate: [AuthGuard],
    loadChildren: () => import('./account/account.routes').then((m) => m.accountRoutes)
  },

  /* --------------------------------- */
  /*  Order pages
  /* --------------------------------- */

  {
    // TODO: ?
    path: 'koszyk',
    data: { title: 'Koszyk - Manzuko' },
    loadChildren: () => import('./order/order.routes').then((m) => m.orderRoutes)
  },
  {
    path: '**',
    data: { title: 'Nie znaleziono strony', revalidate: environment.isrRevalidate },
    loadComponent: () =>
      import('./core/error-404/error-404.component').then((m) => m.Error404Component)
  }
];

export const appRoutes: Route[] = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.routes').then((m) => m.adminRoutes),
    providers: []
  },
  {
    matcher: langPrefixMatcher,
    component: LayoutComponent,
    children: mainRoutes
  }
];
