import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

import { selectCart } from '../cart/cart.selectors';
import { GoogleTagService } from '../google-tag/google-tag.service';
import { GoogleAnalyticsActions } from './google-analytics.actions';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly googleTagService = inject(GoogleTagService);

  sendPurchaseToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.purchase),
        tap(({ order }) => {
          if (order?.info?.includes('[test]')) return;
          this.googleTagService.event(
            'purchase',
            'order',
            'purchase_label',
            order?.productsPriceWithDiscounts,
            true,
            {
              transaction_id: order?.id,
              value: order?.productsPriceWithDiscounts,
              currency: order?.currency,
              shipping: order?.shippingPrice,
              coupon: order?.voucher?.code,
              items: order?.products?.map((product) => ({
                item_id: product?.reference,
                item_name: product?.name,
                price: +product?.regularPrice,
                discount: +product?.regularPrice - +product?.price,
                item_category: product?.categoryName,
                quantity: product?.quantity
              }))
            }
          );
          // this.googleTagService.event(
          //   'transaction',
          //   'transaction',
          //   'transaction',
          //   order?.productsPriceWithDiscounts,
          //   true,
          //   {
          //     send_to: 'AW-669185184/26qJCJ_vvckDEKDpi78C',
          //     transaction_id: order?.id,
          //     value: order?.productsPriceWithDiscounts,
          //     currency: order?.currency
          //   }
          // );
          this.googleTagService.gtag('event', 'conversion', {
            send_to: 'AW-669185184/26qJCJ_vvckDEKDpi78C',
            transaction_id: order?.id,
            value: order?.productsPriceWithDiscounts,
            currency: order?.currency
          });
        })
      );
    },
    { dispatch: false }
  );

  sendViewItemToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.viewItem),
        tap(({ product }) => {
          this.googleTagService.event(
            'view_item',
            'cart',
            'view_item_label',
            product?.price,
            true,
            {
              currency: product?.currency,
              value: product?.price,
              items: [
                {
                  item_id: product?.reference,
                  item_name: product?.name,
                  price: product?.price,
                  item_category: product?.categoryName
                }
              ]
            }
          );
        })
      );
    },
    { dispatch: false }
  );

  sendViewItemListSendToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.viewItemList),
        tap(({ products }) => {
          const productsGa = products.map((product) => ({
            item_id: product?.reference,
            item_name: product?.name,
            price: product?.price,
            item_category: product?.categoryName || product?.categorySlug || product?.categoryId
          }));
          this.googleTagService.event('view_item_list', 'item', 'item_list_label', 1, true, {
            item_list_id:
              products?.[0]?.categoryName ||
              products?.[0]?.categorySlug ||
              products?.[0]?.categoryId,
            items: productsGa
          });
        })
      );
    },
    { dispatch: false }
  );

  registerSendToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.register),
        tap(() =>
          this.googleTagService.event('sign_up', 'register', 'register', 0, true, {
            method: 'manzuko'
          })
        )
      );
    },
    { dispatch: false }
  );

  loginSendToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.login),
        tap(({ method }) =>
          this.googleTagService.event('login', 'login', 'login', 0, true, {
            method: method || 'manzuko'
          })
        )
      );
    },
    { dispatch: false }
  );

  addToCartGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.addToCart),
        tap(({ product, quantity }) => {
          this.googleTagService.event(
            'add_to_cart',
            'cart',
            'add_to_cart_label',
            product?.price,
            true,
            {
              currency: product?.currency,
              value: product?.price,
              items: [
                {
                  item_id: product?.reference,
                  item_name: product?.name,
                  price: product?.price,
                  item_category: product?.categoryName,
                  quantity
                }
              ]
            }
          );
        })
      );
    },
    { dispatch: false }
  );

  removeToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.removeFromCart),
        tap(({ product, quantity }) => {
          this.googleTagService.event(
            'remove_from_cart',
            'cart',
            'remove_from_cart_label',
            product?.price,
            true,
            {
              currency: product?.currency,
              value: product?.price,
              items: [
                {
                  item_id: product?.reference,
                  item_name: product?.name,
                  price: product?.price,
                  item_category: product?.categoryName,
                  quantity: Math.abs(quantity)
                }
              ]
            }
          );
        })
      );
    },
    { dispatch: false }
  );

  addPaymentInfoToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.addPaymentInfo),
        tap(({ cart }) => {
          this.googleTagService.event(
            'add_payment_info',
            'cart',
            'add_payment_info_label',
            cart?.productsPriceWithDiscounts,
            true,
            {
              payment_type: cart?.payment,
              currency: cart?.currency,
              value: cart?.productsPriceWithDiscounts,
              items: cart?.products?.map((product) => ({
                item_id: product?.reference,
                item_name: product?.name,
                price: +product?.regularPrice,
                discount: +product?.regularPrice - +product?.price,
                item_category: product?.categoryName,
                quantity: product?.quantity
              }))
            }
          );
        })
      );
    },
    { dispatch: false }
  );

  addShippingInfoToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.addShippingInfo),
        tap(({ cart }) => {
          this.googleTagService.event(
            'add_shipping_info',
            'cart',
            'add_shipping_info_label',
            cart.productsPriceWithDiscounts,
            true,
            {
              shipping_tier: `${cart?.carrier} ${cart?.carrierType}`,
              currency: cart?.currency,
              value: cart?.productsPriceWithDiscounts,
              items: cart?.products?.map((product) => ({
                item_id: product?.reference,
                item_name: product?.name,
                price: +product?.regularPrice,
                discount: +product?.regularPrice - +product?.price,
                item_category: product?.categoryName,
                quantity: product?.quantity
              }))
            }
          );
        })
      );
    },
    { dispatch: false }
  );

  addToWishlistToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.addToWishlist),
        tap(({ product }) => {
          this.googleTagService.event(
            'add_to_wishlist',
            'wishlist',
            'add_to_wishlist_label',
            product?.price,
            true,
            {
              currency: product?.currency,
              value: product?.price,
              items: [
                {
                  item_id: product?.reference,
                  item_name: product?.name,
                  price: product?.price,
                  item_category: product?.categoryName
                }
              ]
            }
          );
        })
      );
    },
    { dispatch: false }
  );

  searchToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.search),
        tap(({ search_term }) =>
          this.googleTagService.event('search', 'search', 'search_label', 1, true, {
            search_term
          })
        )
      );
    },
    { dispatch: false }
  );

  sendBeginCheckoutToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.beginCheckout),
        concatLatestFrom(() => this.store.select(selectCart)),
        tap(([, cart]) => {
          this.googleTagService.event(
            'begin_checkout',
            'order',
            'begin_checkout_label',
            cart?.productsPriceWithDiscounts,
            true,
            {
              transaction_id: cart?.id,
              value: cart?.productsPriceWithDiscounts,
              currency: cart?.currency,
              shipping: cart?.shippingPrice,
              items: cart?.products?.map((product) => ({
                item_id: product?.reference,
                item_name: product?.name,
                price: +product?.regularPrice,
                discount: +product?.regularPrice - +product?.price,
                item_category: product?.categoryName,
                quantity: product?.quantity
              }))
            }
          );
        })
      );
    },
    { dispatch: false }
  );

  sendViewCartToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.viewCart),
        concatLatestFrom(() => this.store.select(selectCart)),
        tap(([, cart]) => {
          this.googleTagService.event(
            'view_cart',
            'cart',
            'cart_view_label',
            cart?.productsPriceWithDiscounts,
            true,
            {
              currency: cart?.currency,
              value: cart?.productsPriceWithDiscounts,
              items: cart?.products?.map((product) => ({
                item_id: product?.id,
                item_name: product?.name,
                discount: product?.regularPrice - product?.discountPrice,
                item_category: product?.categoryName,
                quantity: product?.quantity,
                price: product?.regularPrice
              }))
            }
          );
        })
      );
    },
    { dispatch: false }
  );

  sendSubscribeNewsletterToGa$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoogleAnalyticsActions.subscribeNewsletter),
        tap(({ email }) =>
          this.googleTagService.event(
            'subscribe_newsletter',
            'newsletter',
            'subscribe_newsletter_label',
            0,
            true,
            {
              email
            }
          )
        )
      );
    },
    { dispatch: false }
  );
}
