@if (showMenu(); as showMenu) {
  <nav
    *transloco="let t"
    aria-label="Main Navigation"
    class="fixed bg-white abs z-[1010] top-0 left-0 w-3/4 h-full flex flex-col"
    [ngClass]="showMenu ? 'show' : 'hidden'"
    (clickOutside)="showMenu ? toggleMenu() : null">
    @defer {
      @if (selectedCategory(); as category) {
        <mzk-nav-category [category]="category" [subcategories]="selectedSubcategories()" />
      } @else {
        @defer {
          <div class="flex flex-row py-2 pr-1 sm:px-4 bg-brand-100">
            <button
              type="button"
              title="Zamknij"
              class="inline-block mr-1 sm:mr-4 text-neutral-500"
              (click)="toggleMenu()">
              <fast-svg name="close-button" size="40" />
            </button>
            <a
              routerLink="/"
              title="Sklep z Koralikami i Akcesoriami do Wyrobu Biżuterii - Półfabrykatami - Manzuko">
              <fast-svg name="logo-text" class="text-brand" width="86" height="44" />
            </a>
            <h4 class="m-auto text-right uppercase">Menu</h4>
          </div>
          <div class="flex flex-col h-[calc(100vh-70px)] overflow-y-scroll">
            <div class="flow-root">
              <h5>Produkty</h5>
              <ul class="flex flex-col min-h-11 text-black uppercase w-full border-b-neutral-200">
                <li>
                  <a
                    class="px-3"
                    data-test="new"
                    [routerLink]="'/nowe-produkty' | mzkLink | async"
                    routerLinkActive="active">
                    <span class="flex flex-grow font-semibold">
                      {{ t('shop.nav.news') }}
                    </span>
                    <fast-svg name="nav-stars" size="22" />
                  </a>
                </li>
                @for (category of categories(); track category?.id) {
                  <li
                    attr.data-test="home-nav-category-{{ category?.id }}"
                    [ngClass]="{ parent: category?.isExpandable }">
                    <a class="px-3" [routerLink]="category?.path" routerLinkActive="active">
                      <span class="flex flex-grow" (click)="selectNav(category?.id, $event)">
                        {{ category?.displayName || category?.name }}
                      </span>
                      <fast-svg name="chevron-right" class="text-neutral-400" size="22" />
                    </a>
                  </li>
                }
                <li>
                  <a
                    data-test="nav-promotion"
                    [routerLink]="'/promocje' | mzkLink | async"
                    routerLinkActive="active"
                    class="px-3 text-brand-700">
                    <span class="flex flex-grow font-semibold">
                      {{ t('shop.nav.sale') }}
                    </span>
                    <fast-svg name="nav-promotion" size="22" />
                  </a>
                </li>
                <li>
                  <a
                    class="px-3"
                    data-test="nav-bestseller"
                    [routerLink]="'/najczesciej-kupowane' | mzkLink | async"
                    routerLinkActive="active">
                    <span class="flex flex-grow font-semibold">
                      {{ t('shop.nav.best') }}
                    </span>
                    <fast-svg name="nav-bestseller" size="22" />
                  </a>
                </li>
              </ul>
              <h5>Informacje</h5>
              <ul class="flex flex-col min-h-11 text-black uppercase w-full border-b-neutral-200">
                @for (link of infoLinks; track link.id) {
                  <li>
                    <a class="px-3" [routerLink]="link.link" routerLinkActive="active">
                      {{ link.title }}
                    </a>
                  </li>
                }
              </ul>
              <h5>Potrzebujesz pomocy?</h5>
              <mzk-footer-contact class="text-md" />
            </div>
          </div>
        }
      }
    }
  </nav>

  @defer {
    <div
      class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[1001] transition-all cursor-close backdrop-blur-[1px]"
      [ngClass]="
        showMenu ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      "></div>
  }
}
