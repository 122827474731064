import { HttpErrorResponse } from '@angular/common/http';
import { ApiSecureRoutes, LoginResponse, User } from '@manzuko/shared';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AuthActions = createActionGroup({
  source: 'Auth/API',
  events: {
    Login: props<{ email: string; password: string }>(),
    'Login With Social': props<{
      email: string;
      firstName: string;
      id: string;
      idToken: string;
      lastName: string;
      name: string;
      photoUrl: string;
      provider: string;
    }>(),
    'Login Success': props<{ auth: LoginResponse; provider?: string }>(),
    'Login Failure': props<{ error: HttpErrorResponse }>(),
    Set: props<{ auth: LoginResponse }>(),
    'Get User': emptyProps(),
    'Set User': props<{ user: User }>(),
    Logout: emptyProps(),
    'Get Secure Routes': emptyProps(),
    'Set Secure Routes': props<{ secureRoutes: ApiSecureRoutes }>(),
    Register: props<{ user: User }>(),
    'Register Success': props<{ user: User }>(),
    'Register Failure': props<{ errors: any }>(),
    'Clear Errors': emptyProps(),
    'Update User': props<{ user: Partial<User> }>(),
    'Update User Success': props<{ user: User }>(),
    'Update User Failure': props<{ errors: any }>(),
    'Change Password': props<{ password: string; currentPassword: string }>(),
    'Change Password Success': emptyProps(),
    'Change Password Failure': props<{ error: string }>(),
    'Login Success From Local Storage': props<{ auth: LoginResponse }>(),
    'Logout From Local Storage': emptyProps(),
    'Verify Jwt': emptyProps(),
    'Verify Jwt Success': emptyProps()
  }
});
