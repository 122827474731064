import { Params } from '@angular/router';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectLayoutLanguage } from 'app/data-access/layout';
import { productWithUrls } from 'app/data-access/product/product.helpers';
import { selectProductEntities } from 'app/data-access/product/product.selectors';
import { selectQueryParam, selectQueryParams, selectUrl } from 'app/data-access/router';

import { SearchState } from './search.reducer';

export const selectSearchState = createFeatureSelector<SearchState>('search');

export const selectSearchPagination = createSelector(
  selectSearchState,
  (state) => state.pagination
);
export const selectSearchProductsIds = createSelector(
  selectSearchState,
  (state) => state.productsIds
);
export const selectSearchPage = selectQueryParam('page');
export const selectSearchTerm = selectQueryParam('search');
export const selectLatestQueryParams = selectQueryParams;
export const selectTagTerm = selectQueryParam('tag');

export const selectSearchPageLanguageUrl = createSelector(
  selectSearchPage,
  selectLayoutLanguage,
  selectSearchTerm,
  selectUrl,
  selectTagTerm,
  selectLatestQueryParams,
  (page: string, language: string, search: string, url: string, tag: string, params: Params) => ({
    page,
    language,
    url,
    search,
    tag: tag?.split('-')[0],
    params
  })
);

export const selectSearchProducts = createSelector(
  selectSearchProductsIds,
  selectProductEntities,
  selectLayoutLanguage,
  (ids, entities, language) => ids?.map((id) => productWithUrls(entities?.[id], language))
);

export const selectSearchView = createSelector(
  selectSearchPagination,
  selectSearchProducts,
  selectSearchTerm,
  selectTagTerm,
  selectLayoutLanguage,
  (pagination, products, search: string, tag: string, language: string) => {
    const tagString = tag ? tag.split('-').slice(1).join(' ') : '';

    return {
      pagination,
      products,
      search: search || tagString,
      language
    };
  }
);
