import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { LazyInject } from 'app/admin/shared/lazy-injector.service';

import { ToastAdminActions } from './toast.actions';

@Injectable({
  providedIn: 'root'
})
export class ToastAdminEffects {
  // private readonly toast = inject(HotToastService);
  private readonly lazyInjector = inject(LazyInject);
  private readonly actions$ = inject(Actions);
  showSuccessToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ToastAdminActions.success),
        tap(async ({ message }) => {
          const service = await this.lazyInjector.get<any>(() =>
            import('@ngneat/hot-toast').then((m) => m.HotToastService)
          );
          service.success(message, {
            position: 'top-right',
            dismissible: true,
            className: 'w-100 h-20 bg-green-500 text-white opacity-80'
          });
        })
      );
    },
    { dispatch: false }
  );

  showErrorToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ToastAdminActions.error),
        tap(async ({ message }) => {
          const service = await this.lazyInjector.get<any>(() =>
            import('@ngneat/hot-toast').then((m) => m.HotToastService)
          );
          service.error(message, {
            position: 'top-right',
            dismissible: true,
            className: 'w-100 h-20 bg-green-500 text-white opacity-80'
          });
        })
      );
    },
    { dispatch: false }
  );
}
