<button
  type="button"
  *transloco="let t"
  (click)="showCart()"
  [attr.data-count]="cartCount()"
  class="flex flex-col min-w-16 items-center cursor-pointer relative">
  @if (cartCount() === 0) {
    <fast-svg name="shopping-cart" />
  } @else {
    <fast-svg name="shopping-cart-solid" [ngClass]="{ 'wobble-horizontal-bottom': adding() }" />
  }

  <span>
    {{ t('shop.header.cart') }}
  </span>

  @if (added$ | async) {}

  <span
    class="flex h-5 aspect-square absolute -top-2 right-1.5"
    [ngClass]="adding() ? 'bounce-top' : ''">
    <span
      class="absolute inline-flex h-full w-full rounded-full bg-brand-400 opacity-50"
      [ngClass]="adding() ? 'animate-ping' : ''"></span>
    <span
      class="relative inline-flex rounded-full h-full w-full bg-brand-500 text-white text-center justify-center items-center px-1">
      {{ cartCount() }}
    </span>
  </span>
</button>
