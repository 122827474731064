import { AsyncPipe, isPlatformServer, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  signal
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { LangDefinition, TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { FastSvgComponent } from '@push-based/ngx-fast-svg';

import { LayoutActions } from 'app/data-access/layout/layout.actions';
import { CartIconComponent } from 'app/shared/cart-icon/cart-icon.component';
import { CustomerIconComponent } from 'app/shared/customer-icon/customer-icon.component';
import { ClickOutsideDirective } from 'app/shared/directives/click-outside.directive';
import { RouterLinkPipe } from 'app/shared/pipes/router-link.pipe';

import { SearchComponent } from '../search/search.component';

@Component({
  standalone: true,
  selector: 'mzk-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    AsyncPipe,
    RouterLink,
    TranslocoDirective,
    ClickOutsideDirective,
    CartIconComponent,
    CustomerIconComponent,
    FastSvgComponent,
    SearchComponent,
    RouterLinkPipe,
    NgTemplateOutlet
  ],
  providers: [TranslocoService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  readonly #store = inject(Store);
  readonly #platformId = inject(PLATFORM_ID);
  readonly #translocoService = inject(TranslocoService);

  protected readonly availableLangs: LangDefinition[] =
    this.#translocoService.getAvailableLangs() as LangDefinition[];
  protected readonly flagCodes: any = {
    en: 'en',
    pl: 'pl',
    de: 'de'
  };

  protected readonly activeLang = toSignal(this.#translocoService.langChanges$);

  protected readonly langOpen = signal(false);

  setActiveLang(lang: string): void {
    this.#translocoService.setActiveLang(lang);
    this.#store.dispatch(LayoutActions.setLanguage({ language: lang }));
  }

  ngOnInit(): void {
    this.#store.dispatch(LayoutActions.enter());
  }

  ngOnDestroy(): void {
    if (isPlatformServer(this.#platformId)) {
      return;
    }
    this.#store.dispatch(LayoutActions.leave());
  }

  toggleMenu() {
    this.#store.dispatch(LayoutActions.toggleMenu({}));
  }
}
