@if (view(); as view) {
  <aside
    *transloco="let t; read: 'shop.cart'"
    data-test="cart-aside"
    class="fixed flex flex-col top-0 right-0 w-full max-w-100 z-9999 h-full bg-white transition-transform"
    [ngClass]="isOpen ? 'translate-x-0' : 'translate-x-full'">
    @if (isOpen) {
      <button
        data-test="cart-aside-close-button"
        title="{{ t('close') }}"
        (click)="closed.emit()"
        type="button"
        class="absolute top-4 right-4">
        <fast-svg name="close-button" size="40" />
      </button>
      <header class="pt-6 px-7 pb-4">
        <h4 class="text-3xl font-semibold">
          {{ t('title') }}
          @if (view?.count; as count) {
            <span data-test="cart-count" class="text-gray-500 text-xl">({{ count }})</span>
          }
        </h4>
      </header>

      <div class="mx-auto container overflow-y-auto h-[calc(100%-60px)]">
        @if (view?.products; as items) {
          <div class="flow-root">
            @if (items?.length) {
              <ul class="divide-y divide-gray-200">
                @for (item of items; track item.id) {
                  <li class="group px-1 sm:px-5 transition-colors ease-in-out hover:bg-neutral-50">
                    <mzk-cart-product
                      class="flex items-center justify-between w-4/5 sm:w-full py-4"
                      [item]="item" />
                  </li>
                }
              </ul>
            } @else {
              <div class="flex flex-col items-center">
                <fast-svg name="empty-cart" size="100" />
                <p class="mt-5">
                  {{ t('empty') }}
                </p>
              </div>
            }
          </div>
        }
      </div>

      <footer class="actions flex flex-col py-6 px-10 items-stretch">
        <dl class="font-bold text-2xl flex flex-row justify-between mb-4">
          <dt>{{ t('total') }}:</dt>
          <dd>{{ view?.priceWithDiscount | mzkCurrency | async }}</dd>
        </dl>
        <div class="flex flex-row justify-between">
          <button
            data-test="cart-clear"
            class="opacity-50 hover:text-red-500"
            title="{{ t('clear') }}"
            (click)="clear()"
            type="button">
            <fast-svg name="clear-cart" size="30" />
          </button>
          <button
            data-test="see-cart"
            [disabled]="view?.count === 0"
            title="Zobacz koszyk"
            class="btn-brand w-3/4 h-11"
            [routerLink]="'/koszyk' | mzkLink | async"
            type="button">
            {{ t('see') }}
          </button>
        </div>
      </footer>
    }
  </aside>
  <div
    data-test="cart-aside-close-container"
    class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[9998] transition-all cursor-close backdrop-blur-[1px]"
    [ngClass]="isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'"
    (click)="closed.emit()"></div>
}
