import { formatCurrency } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, of } from 'rxjs';

import { selectLayoutCurrency } from 'app/data-access/layout';

@Pipe({
  standalone: true,
  name: 'mzkCurrency'
})
export class CurrencyPipe implements PipeTransform {
  private readonly store = inject(Store);

  transform(value = 0, code?: string): Observable<string> {
    const formatValue = (currencyCode: string, amount: number): string => {
      if (currencyCode === 'EUR') {
        return formatCurrency(amount, 'en', '€ ', 'EUR');
      } else if (currencyCode === 'PLN') {
        return formatCurrency(amount, 'pl', 'zł', 'PLN');
      }
    };

    if (code) {
      return of(formatValue(code, value));
    }

    return this.store
      .select(selectLayoutCurrency)
      .pipe(map((currency) => formatValue(currency, value)));
  }
}
